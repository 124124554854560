import React from 'react';

import Table from '@components/Table';

import { psaTicketlogsColumns } from './constants';

import useStyles from './styles';

const defaultSort = { fieldName: 'creationDate', order: 'DESC' };

const PsaTicketsLogs = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Table
        resource="/crmTickets"
        columns={psaTicketlogsColumns}
        defaultSort={defaultSort}
      />
    </div>
  );
};

export default PsaTicketsLogs;
