import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ReusableButton from '@ui/components/common/Button/Button';

import {
  deleteWebHookResults,
  reParseWebHookResults,
} from '@ui/components/WebHooks/helpers';
import { listSelector } from '@store/selectors';
import { actions } from '@store/actions';

import { WEB_HOOKS_RESULT_UNRECOGNIZED } from '@constants/routes';

import { BuildOutlined as BuildOutlinedIcon } from '@material-ui/icons';
import iconDelete from '@assets/icons/trash-box.svg';
import iconRefresh from '@assets/icons/refresh.svg';
import useStyles from '../styles';
import { useTableContext } from '../../../../../../hooks/table';

const BulkActions = () => {
  const [loading, setLoading] = useState(false);
  const { selectedIds, deselectAllItems } = useTableContext();
  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const list = useSelector(listSelector);
  const { push } = useHistory();

  const handleDelete = () => {
    setLoading(true);
    const ids = selectedIds.map(id => `id=${id}`).join('&');
    deleteWebHookResults(ids)
      .then(() => {
        dispatch(actions.removeItemFromList({ selectedIds }));
        notify('Webhook results deleted', 'info');
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => {
        deselectAllItems();
        setLoading(false);
      });
  };

  const handleReParse = () => {
    setLoading(true);
    const ids = selectedIds.map(id => `resultId=${id}`).join('&');
    reParseWebHookResults(ids)
      .then(() => {
        dispatch(actions.removeItemFromList({ selectedIds }));
        notify('Web hook results send again', 'info');
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => {
        deselectAllItems();
        setLoading(false);
      });
  };

  const handleFix = () => {
    const ids = selectedIds.map(id => `id=${id}`).join('&');
    if (
      list.some(
        item => selectedIds.includes(String(item.id)) && item.status !== '2',
      )
    ) {
      push(WEB_HOOKS_RESULT_UNRECOGNIZED.replace(':id', ids));
    }
    notify('Web hook results already fixed', 'info');
  };

  return (
    <div className={classes.bulkActions}>
      <ReusableButton
        viewType="red"
        label="Delete"
        onClick={handleDelete}
        loading={loading}
      >
        <img src={iconDelete} alt="Delete" />
        Delete
      </ReusableButton>

      <ReusableButton
        label="Proceed again"
        onClick={handleReParse}
        loading={loading}
      >
        <img src={iconRefresh} alt="Send" />
        Send again
      </ReusableButton>

      <ReusableButton
        label="Proceed again"
        onClick={handleFix}
        size="md"
        loading={loading}
        classNameWrapper={classes.fixButton}
      >
        <BuildOutlinedIcon />
        Fix
      </ReusableButton>
    </div>
  );
};

export default BulkActions;
