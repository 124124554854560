import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  ThunkGetZendeskPriorities,
  ThunkGetZendeskStatuses,
  ThunkGetZendeskTypes,
} from '@store/slices/Zendesk/thunks';

import ZendeskTicketTableActions from '@components/PsaTickets/components/Zendesk/ZendeskTicketTableActions';
import Table from '@components/Table';

import { TableTypes } from '@components/Table/constants';
import { sortOrders } from '@constants/filters';

import useStyles from '../../styles';

const ZendeskTicketTable = ({ defaultId, crmId }) => {
  const [options, setOptions] = useState({
    typesOptions: [],
    statusesOptions: [],
    prioritiesOptions: [],
  });

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleTicketIdClick = useCallback(ticketLink => {
    window.open(ticketLink, '_blank');
  }, []);

  const columns = [
    {
      name: 'ID',
      key: 'id',
      isCustomAccessor: true,
      width: 120,
      minWidth: 120,
      sortable: true,
      searchable: true,
      accessor: item => (
        <div
          className={classes.link}
          onClick={() => {
            handleTicketIdClick(item.ticketLink);
          }}
          role="presentation"
        >
          {item.id}
        </div>
      ),
    },
    {
      name: 'Subject',
      key: 'subject',
      searchable: true,
      sortable: true,
      minWidth: 200,
    },
    {
      name: 'Description',
      key: 'description',
      searchable: true,
      minWidth: 200,
    },
    {
      name: 'Status',
      key: 'status',
      dropDownValues: options.statusesOptions,
      filterByAutocomplete: 'statusId',
      minWidth: 200,
      type: TableTypes.dropdown,
      sortable: true,
      searchable: true,
      isCustomAccessor: true,
      accessor: row => (
        <span style={{ textTransform: 'capitalize' }}>{row.status}</span>
      ),
    },
    {
      name: 'Type',
      key: 'type',
      dropDownValues: options.typesOptions,
      filterByAutocomplete: 'typeId',
      minWidth: 200,
      type: TableTypes.dropdown,
      sortable: true,
      searchable: true,
      isCustomAccessor: true,
      accessor: row => (
        <span style={{ textTransform: 'capitalize' }}>{row.type}</span>
      ),
    },
    {
      name: 'Priority',
      key: 'priority',
      dropDownValues: options.prioritiesOptions,
      filterByAutocomplete: 'priorityId',
      minWidth: 200,
      type: TableTypes.dropdown,
      sortable: true,
      searchable: true,
      isCustomAccessor: true,
      accessor: row => (
        <span style={{ textTransform: 'capitalize' }}>{row.priority}</span>
      ),
    },
    {
      name: 'Requester',
      key: 'requester',
      minWidth: 200,
    },
    {
      name: 'Assignee',
      key: 'assignee',
      minWidth: 200,
    },
    {
      name: 'Actions',
      minWidth: 150,
      width: 150,
      key: 'actions',
      isCustomAccessor: true,
      accessor: record => (
        <ZendeskTicketTableActions
          record={record}
          crmDefaultId={defaultId}
          crmId={crmId}
        />
      ),
    },
  ];

  const onMount = useCallback(() => {
    Promise.all([
      dispatch(ThunkGetZendeskTypes(defaultId)).unwrap(),
      dispatch(ThunkGetZendeskStatuses(defaultId)).unwrap(),
      dispatch(ThunkGetZendeskPriorities(defaultId)).unwrap(),
    ]).then(res => {
      const responseOptions = {
        typesOptions: res[0].map(i => ({ value: i.value, label: i.name })),
        statusesOptions: res[1].map(i => ({ value: i.value, label: i.name })),
        prioritiesOptions: res[2].map(i => ({ value: i.id, label: i.name })),
      };

      setOptions(responseOptions);
    });
  }, [defaultId, dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div>
      <Table
        actions={<></>}
        columns={columns}
        resource={`TicketUpdate/zendesk/${defaultId}/tickets`}
        defaultSort={{ fieldName: 'id', order: sortOrders.desc }}
      />
    </div>
  );
};

ZendeskTicketTable.propTypes = {
  defaultId: PropTypes.number.isRequired,
  crmId: PropTypes.number.isRequired,
};

export default ZendeskTicketTable;
