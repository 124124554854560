import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import Loading from '@common/Loading/Loading';
import ReusableButton from '@common/Button/Button';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { ThunkAddVerificationPhoneNumber } from '@store/slices/verificationSettings/thunks';
import Table from '@components/Table';
import { TableTypes } from '@ui/components/Table/constants';
import { ThunkGetPhoneNumbersCountries } from '@store/slices/bufferPhoneNumbers/thunks';
import { setVerificationPhone } from '@store/slices/verificationSettings';

const SelectPhoneDialog = ({ open, setOpen, refreshScreenHandler }) => {
  const classes = useSharedStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);

  const handlePurchase = bufferNumberId => {
    setProcessing(true);
    dispatch(ThunkAddVerificationPhoneNumber(bufferNumberId))
      .unwrap()
      .then(() => dispatch(setVerificationPhone(openConfirmDialog.number)))
      .finally(() => {
        setOpenConfirmDialog(null);
        setProcessing(false);
        refreshScreenHandler();
      });
  };

  const columns = useMemo(
    () => [
      {
        name: 'Phone Number',
        key: 'phoneNumber',
        sortable: true,
        searchable: true,
        type: TableTypes.phoneNumber,
      },
      {
        name: 'Country',
        key: 'country',
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: countries,
      },
      {
        name: 'Type',
        key: 'type',
        isUpperCase: true,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 300,
        accessor: rowData => (
          <div>
            <ReusableButton
              label="Select"
              onClick={() => {
                setOpenConfirmDialog({
                  id: rowData.id,
                  number: rowData.phoneNumber,
                });
              }}
              disabled={processing}
            />
          </div>
        ),
      },
    ],
    [countries, processing],
  );

  const confirmDialogText = useMemo(() => {
    return `Are you sure to select ${openConfirmDialog?.number} as your phone number? It will be impossible to edit in the future.`;
  }, [openConfirmDialog]);

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetPhoneNumbersCountries())
      .unwrap()
      .then(res => {
        setCountries(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Select Phone Number</DialogTitle>
        <DialogContent>
          <Table
            resource="bufferPhoneNumbers/verificationNumbers"
            columns={columns}
            bulkActions={null}
          />
          <div className={classes.dialogActionsContainer}>
            <ReusableButton
              label="Close"
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
      {openConfirmDialog && (
        <ConfirmDialog
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onCancel={() => {
            setOpenConfirmDialog(false);
          }}
          onAccept={() => {
            handlePurchase(openConfirmDialog.id);
          }}
          content={confirmDialogText}
          title="Confirm action"
          loading={processing}
        />
      )}
    </>
  );
};

SelectPhoneDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  refreshScreenHandler: PropTypes.func.isRequired,
};

export default SelectPhoneDialog;
