/* eslint-disable react/no-danger */
import React from 'react';
import DOMPurify from 'dompurify';
import linkifyHtml from 'linkify-html';

import StatusesAccessor from '@common/MuiVirtualizedTable/components/accessors/StatusesAccessor';

import { TableTypes } from '@components/Table/constants';
import crmTicketStatuses from '@constants/crmTicketStatuses';

import getFormattedDate from '@utils/getFormattedDate';
import EllipsisTooltip from '@common/EllipsisTooltip';

export const psaTicketlogsColumns = [
  {
    name: 'Date',
    key: 'creationDate',
    sortable: true,
    isCustomAccessor: true,
    accessor: row => <div>{getFormattedDate(row.creationDate)}</div>,
    minWidth: 200,
    width: 200,
  },
  {
    name: 'Ticket ID',
    key: 'ticketId',
    minWidth: 200,
    width: 200,
    sortable: true,
    searchable: true,
  },
  {
    name: 'Mailbox',
    key: 'mailBoxName',
    minWidth: 200,
    width: 200,
    sortable: true,
    searchable: true,
  },
  {
    name: 'Status',
    key: 'ticketStatus',
    type: TableTypes.dropdown,
    dropDownValues: [
      { label: 'Open', value: 1 },
      { label: 'Closed', value: 2 },
      { label: 'Error', value: 3 },
      { label: 'Acknowledged', value: 4 },
    ],
    filterByAutocomplete: 'ticketStatus',
    sortable: true,
    searchable: true,
    isCustomAccessor: true,
    accessor: row => (
      <StatusesAccessor item={row.ticketStatus} array={crmTicketStatuses} />
    ),
    width: 200,
    minWidth: 200,
  },
  {
    name: 'Subject',
    key: 'subject',
    sortable: true,
    searchable: true,
    minWidth: 200,
  },
  {
    name: 'Body',
    key: 'body',
    sortable: true,
    searchable: true,
    minWidth: 200,
    isCustomAccessor: true,
    accessor: row =>
      row.body ? (
        <EllipsisTooltip
          text={
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(linkifyHtml(row.body)),
              }}
            />
          }
        />
      ) : (
        <div />
      ),
  },
  {
    name: 'Error Message',
    key: 'errorMessage',
    sortable: true,
    searchable: true,
    minWidth: 200,
  },
];

export default {};
