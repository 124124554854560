import React, { useState } from 'react';
import { useNotify } from 'react-admin';
import useAsync from '@services/api/common/useAsync';
import { getCwOptions } from '@components/WebHooks/components/dialogs/helpers';
import DropDown from '@components/Auth/Common/DropDown';
import { deleteCwHooks } from '@components/DataSources/helpers';
import ActionField from '@common/ActionFields/ActionField';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import { useDispatch } from 'react-redux';
import { actions } from '@store/actions';
import useStyles from './styles';

const ConnectWiseHooks = () => {
  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [selectedAtIntegration, setSelectedAtIntegration] = useState(null);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [deleteProcess, setDeleteProcess] = useState(false);

  useAsync(getCwOptions, setOptions);

  const onDelete = item => {
    setIdForDelete(item.id);
    setDisplayDeleteDialog(true);
  };

  const handleDelete = () => {
    setDeleteProcess(true);
    deleteCwHooks(selectedAtIntegration, idForDelete)
      .then(() => {
        dispatch(actions.removeItemFromList({ id: idForDelete }));
        setIdForDelete(null);
        setDisplayDeleteDialog(false);
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => setDeleteProcess(false));
  };

  const columns = [
    {
      key: 'description',
      name: 'Name',
      sortable: true,
      searchable: true,
      minWidth: 100,
    },
    {
      key: 'level',
      name: 'Level',
      sortable: true,
      searchable: true,
      minWidth: 100,
    },
    {
      key: 'type',
      name: 'Type',
      sortable: true,
      searchable: true,
      minWidth: 100,
    },
    {
      key: 'url',
      name: 'URL',
      sortable: true,
      searchable: true,
    },
    {
      key: TableTypes.actions,
      type: TableTypes.actions,
      width: 75,
      minWidth: 75,
      accessor: rowData => (
        <ActionField
          label="Actions"
          record={rowData}
          onDelete={onDelete}
          fieldKey="link"
        />
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <DropDown
        options={options}
        classNameWrapper={classes.selectWrapper}
        input={{
          value: selectedAtIntegration,
          onChange: e => setSelectedAtIntegration(e.target.value),
        }}
      />
      {selectedAtIntegration && (
        <Table
          resource={`/Integrations/ConnectWise/${selectedAtIntegration}/getHooks`}
          columns={columns}
          defaultSort={{ fieldName: 'description', order: 'ASC' }}
        />
      )}
      <ConfirmDialog
        open={displayDeleteDialog}
        title="Delete from ConnectWise"
        content="This operation will remove ConnectWise web hook. It cannot be undone"
        onAccept={handleDelete}
        setOpen={() => setDisplayDeleteDialog(false)}
        loading={deleteProcess}
      />
    </div>
  );
};

export default ConnectWiseHooks;
