import { makeRequest } from '@services/requests/makeRequest';

export const helpdeskToggleState = (id, state) =>
  makeRequest(
    'PUT',
    `/HelpdeskAiAssistants/${id}/makeActive`,
    state,
    false,
    false,
  );

export const createNewHelpdeskConfiguration = data =>
  makeRequest('POST', '/HelpdeskAiAssistants', data);

export const deleteHelpdeskConfiguration = id =>
  makeRequest('DELETE', `/HelpdeskAiAssistants/${id}`, undefined, false, false);

export default {};
