import putData from '@services/api/common/putData';
import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import { genericPipelines, genericSources, logRecords } from '@constants/appRoutes';
import deleteData from '@services/api/common/deleteData';
import postWithResponse from '@services/api/common/postWithResponse';

export const toggleEnabled = (id, data) =>
  putData(`${appConfig.baseUrl}${genericSources}${id}/makeEnabled`, data);

export const getDetails = id =>
  getData(`${appConfig.baseUrl}${genericSources}${id}`);

export const getGenericPipelinesOptions = () =>
  getData(`${appConfig.baseUrl}${genericPipelines}?type=2`);

export const deleteRecord = id =>
  deleteData(`${appConfig.baseUrl.concat(genericSources)}${id}`);

export const getFieldTransformers = (srcId, pipelineId) =>
  getData(
    `${appConfig.baseUrl}${genericSources}getFieldTransformer?srcId=${srcId}&pipelineId=${pipelineId}`,
  );

export const createDataView = (id, data) =>
  putData(`${appConfig.baseUrl}${genericSources}${id}/createDataView`, data);

export const getDetailedLogPeriods = () =>
  getData(`${appConfig.baseUrl}${logRecords}getDetailedLogPeriods`);

export const setDetailedLogPeriod = (id, period) =>
  postWithResponse(`${appConfig.baseUrl}${logRecords}appBots/${id}/setDetailedLogsPeriod`, { period: period });
  
