import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { enqueueSnackbar } from 'notistack';
import { TEXT_UPDATE_SUCESS } from '@constants/texts/common';
import iconPlus from '@assets/icons/plus.svg';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import ReusableButton from '@common/Button/Button';
import Loading from '@common/Loading/Loading';
import { getItems, updateItems } from './helpers';
import CustomFieldItem from './components/CustomFieldItem';
import customNames from './cutomNames';

import useStyles from './styles';

const CustomFields = ({ current }) => {
  const classes = useStyles();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayLoading, setDisplayLoading] = useState(false);

  useEffect(() => {
    setDisplayLoading(true);
    getItems()
      .then(d => {
        const newItems = d.models.map(i => ({
          id: Math.floor(Math.random() * 10000),
          name: i.name,
          value: i.value,
        }));
        setItems(newItems);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setDisplayLoading(false));
  }, [current]);

  const handleAdd = () => {
    const newArr = [
      ...items,
      { id: Math.floor(Math.random() * 10000), name: '', value: '' },
    ];
    setItems(newArr);
  };

  const handleDelete = id => {
    const newArr = [...items];
    const idx = newArr.findIndex(i => i.id === id);
    if (idx > -1) {
      newArr.splice(idx, 1);
      setItems(newArr);
    }
  };

  const handleChange = item => {
    const newArr = [...items];
    const idx = newArr.findIndex(i => i.id === item.id);
    newArr[idx] = item;
    setItems(newArr);
  };

  const onSubmit = () => {
    setLoading(true);
    const payload = {
      models: items,
    };
    updateItems(payload)
      .then(() => enqueueSnackbar(TEXT_UPDATE_SUCESS, { variant: 'success' }))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setLoading(false));
  };

  const onAddDefaults = () => {
    const newArr = [...items];
    customNames.forEach(cn => {
      if (newArr.findIndex(i => i.value === cn.value) === -1) {
        newArr.push({
          id: Math.floor(Math.random() * 10000),
          name: cn.name,
          value: cn.value,
        });
      }
    });
    setItems(newArr);
  };

  if (displayLoading) {
    return <Loading />;
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        {items.map(i => (
          <CustomFieldItem
            key={i.id}
            value={i}
            onDelete={handleDelete}
            onChange={handleChange}
            loading={loading}
          />
        ))}
        <div className={classes.buttonsBlock}>
          <ActionButton
            handler={handleAdd}
            icon={<img src={iconPlus} alt="iconPlus" />}
            disabled={loading}
          />
          <ReusableButton
            label="resources.buttons.setDefault"
            onClick={onAddDefaults}
            disabled={loading}
          />
          <ReusableButton
            viewType="black"
            label="resources.buttons.submit"
            onClick={onSubmit}
            loading={loading}
          />
        </div>
      </Paper>
    </div>
  );
};

export default CustomFields;
