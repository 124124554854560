import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  statusBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  tableWrapper: {
    padding: '10px 30px',
    boxSizing: 'border-box',
    margin: '10px 0px',
    borderRadius: '5px',
  },
  tableActions: {
    padding: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
  },
  bagelContainer: {
    backgroundColor: '#373b3e',
    [theme.breakpoints.up('sm')]: {
      margin: '1rem 0.5rem',
    },
    borderRadius: '10px',
  },
  bagelGridContainer: {
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-around',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  bagelGrid: {
    backgroundColor: '#323538',
  },
  colorBlock: {
    fontWeight: 'bold',
    marginLeft: '0.5rem',
    color: props => props.statusColor,
  },
  tableRowActions: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  bulkActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  bulkActionsButtons: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    '& svg': {
      width: '20px',
      height: '20px',
      '& path': {
        fill: 'black',
      },
    },
  },
}));

export default useStyles;
