import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ReusableButton from '@ui/components/common/Button/Button';
import GroupOptInDialog from '../dialogs/GroupOptInDialog';
import BulkInviteDialog from '../dialogs/BulkInviteDialog';

import useStyles from '../styles';

const PsaContactsTableBulkActions = ({
  psaId,
  psaName,
  isClientPortalButton = true,
}) => {
  const [openGroupOptInDialog, setOpenGroupOptInDialog] = useState(false);
  const [openBulkInviteDialog, setOpenBulkInviteDialog] = useState(false);

  const classes = useStyles();

  return (
    <div className={classes.tableBulk}>
      <ReusableButton
        label="Send Opt-in Form"
        onClick={() => {
          setOpenGroupOptInDialog(true);
        }}
      />
      {isClientPortalButton && (
        <ReusableButton
          label="Invite to Client Portal"
          onClick={() => {
            setOpenBulkInviteDialog(true);
          }}
        />
      )}
      {openGroupOptInDialog && (
        <GroupOptInDialog
          onClose={setOpenGroupOptInDialog}
          psaId={psaId}
          psaName={psaName}
        />
      )}
      {openBulkInviteDialog && (
        <BulkInviteDialog
          psaId={psaId}
          open={openBulkInviteDialog}
          onClose={() => {
            setOpenBulkInviteDialog(false);
          }}
        />
      )}
    </div>
  );
};

PsaContactsTableBulkActions.propTypes = {
  psaId: PropTypes.string.isRequired,
  psaName: PropTypes.string.isRequired,
  isClientPortalButton: PropTypes.bool,
};

export default PsaContactsTableBulkActions;
