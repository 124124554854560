import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useNotify } from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { CREATE_SCHEDULE, EDIT_SCHEDULE } from '@constants/routes';

import Loading from '@ui/components/common/Loading/Loading';
import DropDown from '@ui/components/Auth/Common/DropDown';
import ReusableButton from '@ui/components/common/Button/Button';
import SchedulerItem from './components/SchedulerItem';

import {
  generateEventArray,
  getNotificationGroupAutocomplete,
  getNotificationGroupUsers,
  getSchedulerRulesForGroup,
  getSchedulerRulesForUser,
  prepareDate,
} from '../helper';

import useStyles from '../styles';

const Schedule = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedUser, setSelectedUser] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [userView, setUserView] = useState(false);

  const classes = useStyles();
  const notify = useNotify();
  const { search } = useLocation();
  const { push } = useHistory();

  // user select effect
  useEffect(() => {
    if (selectedUser !== 0) {
      getSchedulerRulesForUser(
        selectedUser,
        prepareDate(startDate),
        prepareDate(endDate),
      )
        .then(res => {
          setEvents(generateEventArray(res, match.params.groupId));
        })
        .catch(err => {
          notify(err.message, 'warning');
        });
    }
  }, [selectedUser, endDate, startDate, notify]);

  // group select effect
  useEffect(() => {
    if (selectedGroup !== 0 && selectedUser === 0) {
      setSelectedUser(0);

      getSchedulerRulesForGroup(
        selectedGroup,
        prepareDate(startDate),
        prepareDate(endDate),
      )
        .then(res => {
          setEvents(generateEventArray(res, match.params.groupId));
        })
        .catch(err => {
          notify(err.message, 'warning');
        });
      getNotificationGroupUsers(selectedGroup)
        .then(res => {
          setUsers([{ value: 0, label: 'All' }, ...res]);
        })
        .catch(err => {
          notify(err.message, 'warning');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup, startDate, endDate, notify]);

  // mount effect
  useEffect(() => {
    setLoading(true);
    if (!Number.isNaN(Number(match.params.userId))) {
      setSelectedUser(Number(match.params.userId));
      setUserView(true);
    } else {
      setUserView(false);
    }

    getNotificationGroupAutocomplete()
      .then(res => {
        setGroups(res);
        if (res.length > 0 && !search.includes('groupId')) {
          setSelectedGroup(res[0].value);
        } else {
          const urlSearchParams = new URLSearchParams(search);
          if (urlSearchParams.has('groupId')) {
            setSelectedGroup(Number(urlSearchParams.get('groupId')));
          }
        }
      })
      .catch(err => {
        notify(err.message, 'warning');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [notify, match]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {!userView && (
          <>
            <div className={classes.headerDropDown}>
              <DropDown
                options={users}
                input={{
                  onChange: e => {
                    setSelectedUser(e.target.value);
                  },
                  value: selectedUser,
                }}
              />
            </div>
            <div className={classes.headerDropDown}>
              <DropDown
                options={groups}
                input={{
                  onChange: e => {
                    setSelectedUser(0);
                    setSelectedGroup(e.target.value);
                  },
                  value: selectedGroup,
                }}
              />
            </div>
          </>
        )}
        <div className={classes.headerButton}>
          <ReusableButton
            label="New Schedule"
            size="md"
            onClick={() => {
              push(CREATE_SCHEDULE.replace(':groupId', selectedGroup));
            }}
          />
        </div>
      </div>

      <FullCalendar
        height="80%"
        nowIndicator
        plugins={[timeGridPlugin, dayGridPlugin]}
        events={events}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'title',
          right: 'today prev timeGridDay timeGridWeek dayGridMonth next',
        }}
        datesSet={({ startStr, endStr }) => {
          setStartDate(startStr);
          setEndDate(endStr);
        }}
        eventContent={({ event }) => <SchedulerItem event={event} />}
        eventClick={({ event }) => {
          push(
            EDIT_SCHEDULE.replace(':groupId', String(selectedGroup)).replace(
              ':ruleId',
              event.extendedProps.ruleId,
            ),
          );
        }}
      />
    </div>
  );
};

Schedule.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
      groupId: PropTypes.string,
    }),
  }),
};
export default Schedule;
