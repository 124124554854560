import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ThunkDeleteSmsCampaignGroupItems } from '@store/slices/smsCampaigns/thunks';
import { actions } from '@store/actions';

import ReusableButton from '@common/Button/Button';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { useTableContext } from '../../../../../hooks/table';

const BulkActions = () => {
  const [
    openDeleteConfirmationDialog,
    setOpenDeleteConfirmationDialog,
  ] = useState(false);
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const { selectedIds, deselectAllItems } = useTableContext();

  const handleBulkDelete = useCallback(() => {
    setProcessing(true);
    dispatch(ThunkDeleteSmsCampaignGroupItems(selectedIds))
      .unwrap()
      .then(() => {
        dispatch(actions.removeItemFromList({ selectedIds }));
        setProcessing(false);
        setOpenDeleteConfirmationDialog(false);
        deselectAllItems();
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [deselectAllItems, dispatch, selectedIds]);

  return (
    <div>
      <ReusableButton
        label="Delete"
        onClick={() => {
          setOpenDeleteConfirmationDialog(true);
        }}
      />

      {openDeleteConfirmationDialog && (
        <ConfirmDialog
          onAccept={handleBulkDelete}
          onCancel={() => {
            setProcessing(false);
            setOpenDeleteConfirmationDialog(false);
          }}
          title="Confirm Action"
          content="Are you sure you want to delete this items?"
          open={openDeleteConfirmationDialog}
          loading={processing}
        />
      )}
    </div>
  );
};

export default BulkActions;
