import React, { useCallback, useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import { enqueueSnackbar } from 'notistack';

import Loading from '@ui/components/common/Loading/Loading';
import TypesList from './components/TypesList';
import TypeValues from './components/TypeValues';

import { getTypes } from './helpers';
import useStyles from './styles';

const Types = () => {
  const [loading, setLoading] = useState(true);
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(0);

  const classes = useStyles();

  const onMount = useCallback(() => {
    setLoading(true);
    getTypes()
      .then(res => {
        setTypes(res);
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <Paper className={classes.container}>
      <TypesList
        types={types}
        setSelectedType={setSelectedType}
        setTypes={setTypes}
      />

      <TypeValues
        types={types}
        setTypes={setTypes}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
      />
    </Paper>
  );
};

export default Types;
