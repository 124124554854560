import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { EditButton } from 'react-admin';
import Box from '@material-ui/core/Box';

import iconEdit from '@assets/icons/edit.svg';
import BulkActionButtons from '@common/BulkActionButtons';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import EllipsisTooltip from '@common/EllipsisTooltip';
import DeleteActionField from './components/DeleteActionField';
import ListActions from './components/ListActions';

import useStyles from './styles';

const List = props => {
  const { history } = props;

  const classes = useStyles();

  const createPage = () => history.push(`${window.location.pathname}/create`);

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        key: 'name',
        sortable: true,
        searchable: true,
        minWidth: 200,
      },
      {
        name: 'Displayed Fields',
        key: 'data',
        isCustomAccessor: true,
        accessor: rowData => (
          <EllipsisTooltip
            text={JSON.parse(rowData.data)
              .map(i => i.name)
              .join(', ')}
          />
        ),
        minWidth: 640,
        sortable: true,
        searchable: true,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 100,
        minWidth: 100,
        accessor: rowData => (
          <Box>
            <EditButton
              label=""
              icon={<img src={iconEdit} alt="iconEdit" />}
              record={rowData}
              basePath="dataViews"
              className={classes.editButton}
            />

            <DeleteActionField record={rowData} />
          </Box>
        ),
      },
    ],
    [classes.editButton],
  );

  return (
    <div className={classes.container}>
      <Table
        bulkActions={<BulkActionButtons resource="dataViews" />}
        actions={<ListActions createPage={createPage} />}
        columns={columns}
        defaultSort={{ fieldName: 'name', order: 'ASC' }}
        resource="dataViews"
      />
    </div>
  );
};

List.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default List;
