import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDataProvider, useRedirect, useTranslate } from 'react-admin';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Field, Form as RaForm } from 'react-final-form';
import { Grid } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { enqueueSnackbar } from 'notistack';

import { TEXT_CREATE_SUCESS } from '@constants/texts/common';

import useAsync from '@services/api/common/useAsync';

import ReusableButton from '@common/Button/Button';
import Input from '@common/Input/Input';
import { DATA_VIEWS } from '@constants/routes';
import displayTypes from '@constants/displayTypes';
import iconPlus from '@assets/icons/plusGrey.svg';
import { getAuditView } from '@components/Audit/helpers';
import Loading from '@common/Loading/Loading';
import AuditViewItem from '../DataViewItem';
import DataViewFormFilters from './components/Filters';
import DataViewFormSelectors from './components/Selectors';

import {
  getFieldOptions,
  getGenericSourcesOptions,
  getMailBoxOptions,
  getParserOptions,
  getWebHookOptions,
} from './helpers';
import { reorder } from '../../helpers/reorder';

import useStyles from './styles';

const emptyFilterBox = '_';

const prepareData = data => {
  if (!data) {
    return [];
  }
  const a = JSON.parse(data);
  return a.map(i => ({
    id: Math.floor(Math.random() * 10000),
    ...i,
  }));
};

const Form = ({ match, redirect }) => {
  const [record, setRecord] = useState(undefined);

  const isEditMode = redirect === 'list';

  const initValues = useMemo(
    () =>
      isEditMode
        ? {
            name: record?.name,
            sourceMailBox: record?.sourceMailBox || emptyFilterBox,
            sourceParserId: record?.sourceParserId || 0,
            sourceWebHook: record?.sourceWebHook || emptyFilterBox,
            sourceGeneric: record?.sourceGeneric || 'All',
            records: [],
          }
        : {
            name: '',
            sourceMailBox: emptyFilterBox,
            sourceParserId: 0,
            sourceWebHook: emptyFilterBox,
            sourceGeneric: 0,
            records: [],
          },
    [record, isEditMode],
  );

  const translate = useTranslate();
  const classes = useStyles();
  const redirectTo = useRedirect();
  const dataProvider = useDataProvider();
  const [records, setRecords] = useState(initValues.records);
  const [loading, setLoading] = useState(false);
  const [fieldsOptions, setFieldOptions] = useState([]);
  const [filteredFieldsOptions, setFilteredFieldsOptions] = useState([]);
  const [mailBoxOptions, setMailBoxOptions] = useState([]);
  const [parserOptions, setParserOptions] = useState([]);
  const [webHookOptions, setWebHookOptions] = useState([]);
  const [filterHooksOnly, setFilterHooksOnly] = useState(false);

  useAsync(getFieldOptions, setFieldOptions);
  useAsync(getFieldOptions, setFilteredFieldsOptions);
  useAsync(getMailBoxOptions, setMailBoxOptions);
  useAsync(getParserOptions, setParserOptions);
  useAsync(getWebHookOptions, setWebHookOptions);

  const [filterMailBoxesOptions, setFilterMailBoxesOptions] = useState([]);

  const [filterParsersOptions, setFilterParsersOptions] = useState([]);
  useEffect(() => {
    const newOptions = parserOptions.filter(o => o.value !== 0);
    newOptions.unshift({ value: 0, label: 'All Parsers' });
    setFilterParsersOptions(newOptions);
  }, [parserOptions]);

  const [filterWebHookOptions, setFilterWebHookOptions] = useState([]);
  useEffect(() => {
    const newOptions = webHookOptions
      .filter(o => o.value !== 0)
      .map(o => {
        return { value: o.info, label: o.label };
      });
    newOptions.unshift({ value: emptyFilterBox, label: 'All WebHooks' });
    setFilterWebHookOptions(newOptions);
  }, [webHookOptions]);

  const [selectedMailBox, setSelectedMailBox] = useState(0);
  const [selectedParser, setSelectedParser] = useState(0);
  const [selectedWebHook, setSelectedWebHook] = useState(0);
  const [allowedParserOptions, setAllowedParserOptions] = useState([]);

  const [genericSourcesOptions, setGenericSourcesOptions] = useState([]);
  const [selectedSourceOption, setSelectedSourceOption] = useState(0);
  const [selectAllSourceOptions, setSelectAllSourceOptions] = useState(false);

  useAsync(getMailBoxOptions, setMailBoxOptions);
  useAsync(getGenericSourcesOptions, setGenericSourcesOptions);

  useEffect(() => {
    if (selectedSourceOption !== 0) {
      const newFilters = filteredFieldsOptions.filter(
        p => p.genericSourceId === selectedSourceOption,
      );
      setFilteredFieldsOptions(newFilters);
      setSelectedWebHook(0);
      setFilterHooksOnly(false);
    } else {
      setFilteredFieldsOptions(fieldsOptions);
    }
  }, [selectedSourceOption]);

  useEffect(() => {
    if (selectAllSourceOptions) {
      const newFilters = filteredFieldsOptions.filter(p => !!p.genericSourceId);
      setFilteredFieldsOptions(newFilters);
      setSelectedWebHook(0);
      setFilterHooksOnly(false);
    } else {
      setFilteredFieldsOptions(fieldsOptions);
    }
  }, [selectAllSourceOptions]);

  useEffect(() => {
    const newOptions = genericSourcesOptions;
    newOptions.unshift({ value: 0, label: 'All', info: 0 });
    setGenericSourcesOptions(newOptions);
  }, [genericSourcesOptions]);

  useEffect(() => {
    if (selectedMailBox !== 0) {
      setFilteredFieldsOptions(
        fieldsOptions.filter(p => p.mailBoxId === selectedMailBox),
      );
      setSelectedParser(0);
      setAllowedParserOptions(
        parserOptions.filter(
          p => p.info.includes(selectedMailBox) || p.value === 0,
        ),
      );
    } else {
      setFilteredFieldsOptions(fieldsOptions);
      setAllowedParserOptions([]);
    }
  }, [parserOptions, fieldsOptions, selectedMailBox]);

  useEffect(() => {
    if (selectedParser !== 0) {
      setFilteredFieldsOptions(
        fieldsOptions.filter(
          p => p.parserId === selectedParser && p.mailBoxId === selectedMailBox,
        ),
      );
    } else {
      setFilteredFieldsOptions(
        fieldsOptions.filter(p => p.mailBoxId === selectedMailBox),
      );
    }
  }, [selectedMailBox, fieldsOptions, selectedParser]);

  useEffect(() => {
    if (selectedWebHook !== 0) {
      setFilteredFieldsOptions(
        fieldsOptions.filter(p => p.webHookId === selectedWebHook),
      );
    } else {
      setFilteredFieldsOptions(fieldsOptions);
    }
  }, [selectedWebHook, fieldsOptions]);

  useEffect(() => {
    if (filterHooksOnly) {
      setFilteredFieldsOptions(fieldsOptions.filter(p => !!p.webHookId));
    } else {
      setFilteredFieldsOptions(fieldsOptions);
    }
  }, [fieldsOptions, filterHooksOnly]);

  const handleAdd = event => {
    event.preventDefault();
    const newRecords = [...records];
    const newItem = {
      id: Math.floor(Math.random() * 10000),
      field: '',
      name: '',
      type: 0,
      displayType: displayTypes.Text,
      identifier: false,
    };
    newRecords.push(newItem);
    setRecords(newRecords);
  };

  const handleDelete = item => {
    const newRecords = [...records].filter(i => i.id !== item.id);
    setRecords(newRecords);
  };

  const handleItemChange = item => {
    const newRecords = [...records];
    const index = newRecords.findIndex(i => i.id === item.id);
    if (index > -1) {
      newRecords[index] = item;
      for (let j = 0; j < newRecords.length; j += 1) {
        if (
          !newRecords[j].isCombine ||
          newRecords[j].displayType !== displayTypes.Circle
        ) {
          delete newRecords[j].isCombine;
          delete newRecords[j].combCount;
        }
      }
      setRecords(newRecords);
    }
  };

  const handleBack = () => redirectTo(`${DATA_VIEWS}`);

  const create = data => {
    dataProvider
      .create(DATA_VIEWS.replace('/', ''), { data })
      .then(() => {
        enqueueSnackbar(TEXT_CREATE_SUCESS, { variant: 'success' });
        setLoading(false);
        handleBack();
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
        setLoading(false);
      });
  };

  const update = payload => {
    const data = {
      id: record.id,
      ...payload,
    };
    dataProvider
      .update(DATA_VIEWS.replace('/', ''), { id: record.id, data })
      .then(() => {
        enqueueSnackbar(TEXT_CREATE_SUCESS, { variant: 'success' });
        setLoading(false);
        handleBack();
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
        setLoading(false);
      });
  };

  const submit = values => {
    setLoading(true);
    const data = {
      name: values.name,
      sourceMailBox:
        values.sourceMailBox === emptyFilterBox ? null : values.sourceMailBox,
      sourceParserId:
        values.sourceParserId === 0 ? null : values.sourceParserId,
      sourceWebHook:
        values.sourceWebHook === emptyFilterBox ? null : values.sourceWebHook,
      sourceGeneric:
        values.sourceGeneric === 'All' || values.sourceGeneric === '0'
          ? null
          : values.sourceGeneric,
      data: JSON.stringify(
        records.map(r => ({
          field: r.field,
          name: r.name,
          type: r.type,
          displayType: r.displayType,
          identifier: r.identifier,
          isCombine: r.isCombine,
          combCount: r.combCount,
        })),
      ),
    };
    if (isEditMode) {
      update(data);
    } else {
      create(data);
    }
  };

  const validate = values => {
    const errors = { name: undefined };

    if (!values.name) {
      errors.name = translate('ra.validation.required');
    }

    return errors;
  };

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newRecord = reorder(records, source.index, destination.index);

    setRecords(newRecord);
  };

  const onMount = useCallback(() => {
    if (match.params.dataViewId) {
      setLoading(true);
      getAuditView(match.params.dataViewId)
        .then(res => {
          setRecord(res);
          setRecords(prepareData(res?.data));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [match]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <RaForm
      onSubmit={submit}
      validate={validate}
      initialValues={{
        name: initValues.name,
        sourceMailBox: initValues.sourceMailBox,
        sourceParserId: initValues.sourceParserId,
        sourceWebHook: initValues.sourceWebHook,
        sourceGeneric: initValues.sourceGeneric,
      }}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} noValidate className={classes.form}>
          <Grid container>
            <Grid container className={classes.nameRow}>
              <Grid item xs={12} sm={1}>
                Date View Name:
              </Grid>
              <Grid item xs={12} sm={11} md={3}>
                <Field
                  id="name"
                  name="name"
                  placeholder={translate('resources.dataViews.form.nameLabel')}
                  styleType="main"
                  inputView="text"
                  component={Input}
                  disabled={loading}
                  classNameWrapper={classes.fullWidthInput}
                />
              </Grid>
            </Grid>
            <DataViewFormFilters
              setFilterMailBoxesOptions={setFilterMailBoxesOptions}
              selectedMailBox={selectedMailBox}
              setSelectedMailBox={setSelectedMailBox}
              allowedParserOptions={allowedParserOptions}
              selectedParser={selectedParser}
              setSelectedParser={setSelectedParser}
              webHookOptions={webHookOptions}
              selectedWebHook={selectedWebHook}
              setSelectedWebHook={setSelectedWebHook}
              setFilterHooksOnly={setFilterHooksOnly}
              filterHooksOnly={filterHooksOnly}
              genericSourcesOptions={genericSourcesOptions}
              selectedSourceOption={selectedSourceOption}
              setSelectedSourceOption={setSelectedSourceOption}
              selectAllSourceOptions={selectAllSourceOptions}
              setSelectAllSourceOptions={setSelectAllSourceOptions}
              mailBoxOptions={mailBoxOptions}
            />
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="dateView-list">
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={classes.div}
                  >
                    {records.map((r, index) => (
                      <AuditViewItem
                        key={r.id}
                        item={r}
                        fieldsOptions={filteredFieldsOptions}
                        onChange={handleItemChange}
                        onDelete={handleDelete}
                        index={index}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div>
              {records.length && !records.find(o => o.identifier) ? (
                <span style={{ color: 'red' }}>
                  There are no ID fields selected. Data view will not work
                  properly
                </span>
              ) : null}
            </div>

            <DataViewFormSelectors
              loading={loading}
              form={form}
              filterMailBoxesOptions={filterMailBoxesOptions}
              filterParsersOptions={filterParsersOptions}
              filterWebHookOptions={filterWebHookOptions}
              genericSourcesOptions={genericSourcesOptions}
            />

            <Grid item xs={12} className={classes.buttonContainer}>
              <ReusableButton
                size="md"
                icon={null}
                classNameWrapper={classes.buttonAddWrapper}
                onClick={handleAdd}
              >
                <>
                  <img src={iconPlus} alt="iconPlus" />
                  {translate('common.create')}
                </>
              </ReusableButton>
              <div>
                <ReusableButton
                  label="resources.buttons.cancel"
                  classNameWrapper={cx(
                    classes.buttonAddWrapper,
                    classes.buttonCancel,
                  )}
                  size="md"
                  onClick={handleBack}
                />

                <ReusableButton
                  viewType="black"
                  type="submit"
                  size="md"
                  classNameWrapper={cx(
                    classes.buttonAddWrapper,
                    classes.buttonSubmit,
                  )}
                  disabled={
                    loading ||
                    !records.length ||
                    !records.find(o => o.identifier)
                  }
                  loading={loading}
                  label="resources.buttons.submit"
                />
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

Form.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  redirect: PropTypes.string,
};

export default Form;
