import React, { useEffect, useMemo, useState } from 'react';
import { useRedirect } from 'react-admin';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  getIncident,
  getIncidentsTagsAutocomplete,
  updateIncident,
} from '@components/Triggers/helpers';
import Loading from '@common/Loading/Loading';
import FieldWrapper from '@common/form/FieldWrapper';
import { Field, Form } from 'react-final-form';
import {
  getIncidentStatusName,
  triggerPriorities,
} from '@components/Triggers/components/helpers';
import DropDown from '@components/Auth/Common/DropDown';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import InputWithAutocomplete from '@common/Input/InputWithAutocomplete';
import useAsync from '@services/api/common/useAsync';
import incidentSources from '@constants/incidentSources';
import useStyles from './styles';

const IncidentDetails = ({ match }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [record, setRecord] = useState({});
  const [tagsOptions, setTagsOptions] = useState([]);
  useAsync(getIncidentsTagsAutocomplete, setTagsOptions);

  const getBack = () => redirect('/incidents');

  useEffect(() => {
    setLoading(true);
    getIncident(match.params.id)
      .then(d => setRecord(d))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setLoading(false));
  }, [match]);

  const submit = values => {
    setProcessing(true);
    const payload = { ...record, ...values };
    updateIncident(record.id, payload)
      .then(() => {
        enqueueSnackbar('Assignment completed', { variant: 'success' });
        getBack();
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setProcessing(false));
  };

  const parentEntity = useMemo(() => {
    switch (record.incidentSource) {
      case incidentSources.trigger:
        return { name: record.triggerName, source: record.incidentSource };
      case incidentSources.parser:
        return { name: record.parserName, source: record.incidentSource };
      case incidentSources.webHook:
        return { name: record.webHookName, source: record.incidentSource };
      default:
        return 'Undefined source';
    }
  }, [record]);

  if (loading) return <Loading />;

  return (
    <div className={classes.container}>
      <Form
        onSubmit={submit}
        initialValues={record}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <FieldWrapper
              label="ID"
              labelSize={2}
              contentSize={10}
              content={<div className={classes.label}>{record.id}</div>}
            />
            <FieldWrapper
              label={parentEntity.source}
              labelSize={2}
              contentSize={10}
              content={<div className={classes.label}>{parentEntity.name}</div>}
            />
            <FieldWrapper
              label="Assignee"
              labelSize={2}
              contentSize={10}
              content={
                <div className={classes.label}>{record.assigneeName}</div>
              }
            />
            <FieldWrapper
              label="Priority"
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="priority"
                  name="priority"
                  options={triggerPriorities}
                  component={DropDown}
                />
              }
            />
            <FieldWrapper
              label="Status"
              labelSize={2}
              contentSize={10}
              content={
                <div className={classes.label}>
                  {getIncidentStatusName(record.incidentStatus)}
                </div>
              }
            />
            <FieldWrapper
              label="Tags"
              labelSize={2}
              contentSize={10}
              content={
                <InputWithAutocomplete
                  name="tags"
                  options={tagsOptions}
                  disableCloseOnSelect
                  freeSolo
                  limitTags={5}
                />
              }
            />

            <FieldWrapper
              label="Email Subject"
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="emailSubject"
                  name="emailSubject"
                  component={Input}
                />
              }
            />
            <div className={classes.buttons}>
              <ReusableButton
                viewType="red"
                size="md"
                label="Cancel"
                onClick={getBack}
                disabled={processing}
              />
              <ReusableButton
                type="submit"
                size="md"
                label="Update"
                disabled={processing}
                loading={processing}
              />
            </div>
          </form>
        )}
      />
    </div>
  );
};

IncidentDetails.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
};

export default IncidentDetails;
