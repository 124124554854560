const crmTicketStatuses = {
  1: { name: 'Open', color: '#67AD5B' },
  2: { name: 'Closed', color: '#ece837' },
  3: { name: 'Error', color: '#f00016' },
  4: { name: 'Acknowledged', color: '#1904d1' },
};

export const crmTicketStatusesArray = Object.keys(crmTicketStatuses).map(i => ({
  label: i.name,
  value: i.value,
  color: i.color,
}));

export default crmTicketStatuses;
