import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { enqueueSnackbar } from 'notistack';

import {
  TEXT_CREATE_SUCESS,
  TEXT_UPDATE_SUCESS,
} from '@constants/texts/common';
import Paper from '@common/Paper/Paper';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@common/Input/Input';
import DropDown from '@components/Auth/Common/DropDown';
import SettingsTile from '@components/Ticketing/Notifications/components/SettingsTile';
import ReusableButton from '@common/Button/Button';
import iconArrow from '@assets/icons/arrowGrey.svg';
import { useTranslate } from 'react-admin';
import { deviceThresholdTypes } from '@components/DeviceMonitors/components/helpers';
import SwitchComponent from '@common/FilterInputs/Switch';
import useAsync from '@services/api/common/useAsync';
import { useDispatch, useSelector } from 'react-redux';
import { notificationSettings } from '@store/selectors';
import {
  emailSettings,
  slackSettings,
  smsSettings,
  teamsSettings,
  ticketSettings,
  webHookSettings,
} from '@store/selectors/notifications';
import { actions } from '@store/actions';
import { NOTIFICATION_SETTING } from '@constants/routes';
import appConfig from '@configs/appConfig';
import useStyles from './stylesDmSettingsForm';
import loadData, { handleDisabled, handleErrors } from './utils';
import useAllowUpdate from '../hooks/useAllowUpdate';
import {
  createDeviceMonitorDmSettings,
  getAlertDescription,
  getDeviceMonitorsNames,
  updateDeviceMonitorDmSettings,
} from './helpers';
import tileData from '../utils';
import {
  postEmailSettings,
  postSlackSettings,
  postSmsSettings,
  postTeamsSettings,
  postTicketSettings,
  postWebHookSettings,
} from './components/helpers';

const flexibleDataLoader = (loadedData, storedData) => {
  const data = Object.keys(storedData).length ? storedData : loadedData;
  return loadData(data);
};

const DmSettingsForm = ({ data }) => {
  const translate = useTranslate();
  const isAllowedUpdate = useAllowUpdate();
  const [deviceMonitorsNames, setDeviceMonitorsNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const inMemorySetting = useSelector(notificationSettings);
  const inMemoryTicketSetting = useSelector(ticketSettings);
  const inMemoryEmailSetting = useSelector(emailSettings);
  const inMemorySmsSetting = useSelector(smsSettings);
  const inMemoryWebHookSetting = useSelector(webHookSettings);
  const inMemorySlackSetting = useSelector(slackSettings);
  const inMemoryTeamsSetting = useSelector(teamsSettings);

  useAsync(getDeviceMonitorsNames, setDeviceMonitorsNames);

  const initData = useMemo(() => flexibleDataLoader(data, inMemorySetting), [
    inMemorySetting,
    data,
  ]);

  const handleBack = () => history.goBack();

  const update = async values => {
    try {
      await updateDeviceMonitorDmSettings(values.id, values);
      enqueueSnackbar(TEXT_UPDATE_SUCESS, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Unable to update ticket default', { variant: 'error' });
    }
  };
  const isAllow = setting => setting && Object.keys(setting).length;

  const create = async values => {
    try {
      const model = await createDeviceMonitorDmSettings(values);

      const promises = [
        isAllow(inMemoryTicketSetting) && {
          tickets: postTicketSettings(model.id, {
            ...inMemoryTicketSetting,
            id: model.id,
          }),
        },
        isAllow(inMemoryEmailSetting) && {
          tickets: postEmailSettings(model.id, {
            ...inMemoryEmailSetting,
            id: model.id,
          }),
        },
        isAllow(inMemorySmsSetting) && {
          tickets: postSmsSettings(model.id, {
            ...inMemorySmsSetting,
            id: model.id,
          }),
        },
        isAllow(inMemoryWebHookSetting) && {
          tickets: postWebHookSettings(model.id, {
            ...inMemoryWebHookSetting,
            id: model.id,
          }),
        },
        isAllow(inMemorySlackSetting) && {
          tickets: postSlackSettings(model.id, {
            ...inMemorySlackSetting,
            id: model.id,
          }),
        },
        isAllow(inMemoryTeamsSetting) && {
          tickets: postTeamsSettings(model.id, {
            ...inMemoryTeamsSetting,
            id: model.id,
          }),
        },
      ]
        .filter(i => i)
        .map(i => i.tickets);

      return Promise.all(promises).then(() =>
        enqueueSnackbar(TEXT_CREATE_SUCESS, { variant: 'success' }),
      );
    } catch (e) {
      return enqueueSnackbar('Unable to create ticket default', {
        variant: 'error',
      });
    }
  };

  const submit = values => {
    let promise;
    setLoading(true);
    if (!values.id) {
      promise = create(values);
    } else {
      promise = update(values);
    }
    promise.then(() => {
      setLoading(false);
      handleBack();
    });
  };

  const storeDataToRedux = formData => dispatch(actions.saveSetting(formData));

  return (
    <Paper>
      <Form
        onSubmit={submit}
        validate={handleErrors}
        initialValues={initData}
        render={({ handleSubmit, values, form }) => (
          <form
            onSubmit={handleSubmit}
            noValidate
            className={classes.formWrapper}
          >
            <div className={classes.contentFormWrapper}>
              <FieldWrapper
                label="Device Name"
                labelSize={0}
                classNameContainer={classes.gridContainer}
                classNameLabelOuter={classes.gridLabelOuter}
                contentSize={12}
                content={
                  <Field
                    id="deviceMonitorId"
                    name="deviceMonitorId"
                    placeholder="Device Name"
                    options={deviceMonitorsNames}
                    component={DropDown}
                    disabled={loading || !isAllowedUpdate}
                  />
                }
              />

              <FieldWrapper
                label="Number of Uptime Checks"
                labelSize={0}
                classNameContainer={classes.gridContainer}
                classNameLabelOuter={classes.gridLabelOuter}
                contentSize={12}
                infoText={`With ${appConfig.projectName} Uptime monitoring you can delay notifications until a specific number of failures has been reached. For example maybe you only want a ticket if the last 5 ping tests failed.`}
                content={
                  <Field
                    id="lastN"
                    name="lastN"
                    placeholder="Last number"
                    styleType="main"
                    inputView="number"
                    min={1}
                    type="number"
                    fullWidth
                    classNameWrapper={classes.inputWrapper}
                    component={Input}
                    disabled={loading}
                  />
                }
              />

              <FieldWrapper
                label="Type"
                labelSize={0}
                classNameContainer={classes.gridContainer}
                classNameLabelOuter={classes.gridLabelOuter}
                contentSize={12}
                infoText={
                  form.getFieldState('type')?.value === '1'
                    ? 'Milliseconds Threshold (ms) monitor allows a user to set a threshold of how many times a monitor is above a certain value. For example if pinging google you may want to alert if the last 3 pings were above 500ms as it may indicate a connectivity issue that should be checked into.'
                    : 'With Alert Manage Uptime monitoring you can delay notifications until a specific number of failures has been reached. For example maybe you only want a ticket if the last 5 ping tests failed. '
                }
                content={
                  <Field
                    id="type"
                    name="type"
                    placeholder="Type"
                    options={deviceThresholdTypes}
                    component={DropDown}
                    disabled={loading}
                  />
                }
              />
              <FieldWrapper
                label="Value"
                labelSize={0}
                classNameContainer={classes.gridContainer}
                classNameLabelOuter={classes.gridLabelOuter}
                contentSize={12}
                content={
                  <Field
                    name="value"
                    id="value"
                    placeholder="Value"
                    styleType="main"
                    inputView="number"
                    min={1}
                    type="number"
                    fullWidth
                    classNameWrapper={cx(
                      classes.inputWrapper,
                      classes.disabledInput,
                    )}
                    component={Input}
                    disabled={handleDisabled(form)}
                  />
                }
              />
              <FieldWrapper
                label="Notify about recovering"
                classNameLabelInner={classes.classNameLabelInner}
                content={
                  <Field
                    id="notifyAboutRecovering"
                    name="notifyAboutRecovering"
                    placeholder="Notify about recovering"
                    classNameWrapper={classes.inputWrapper}
                    input={{
                      checked: values.notifyAboutRecovering,
                      onChange: (_, value) =>
                        form.change('notifyAboutRecovering', value),
                    }}
                    component={SwitchComponent}
                    disabled={loading}
                  />
                }
              />
              <div className={classes.textDecorator}>
                <span>
                  {getAlertDescription(
                    values.lastN,
                    values.type,
                    values.value,
                    values.notifyAboutRecovering,
                  )}
                </span>
              </div>
              <div className={classes.tilesSpace}>
                {tileData
                  .filter(i => i.id !== 'isFtp')
                  .map(i => (
                    <Field
                      key={i.id}
                      type="checkbox"
                      id={i.id}
                      name={i.id}
                      component={SettingsTile}
                      disabled={loading}
                      label={i.label}
                      ticketSettingId={initData.id}
                      notificationType={i.notifyType}
                      redirectUrl={`${NOTIFICATION_SETTING}/${initData.id}/${i.notifyType}`}
                      dataViewSelected
                      additionalHandler={() => storeDataToRedux(values)}
                      readOnly={!isAllowedUpdate}
                      icon={i.icon}
                    />
                  ))}
              </div>
            </div>
            <div className={classes.buttonWrapper}>
              <ReusableButton
                size="md"
                type="button"
                classNameWrapper={classes.button}
                disabled={loading}
                onClick={handleBack}
              >
                <>
                  <img src={iconArrow} alt="iconArrow" />
                  {translate('resources.buttons.back')}
                </>
              </ReusableButton>
              {isAllowedUpdate && (
                <ReusableButton
                  size="md"
                  viewType="black"
                  type="submit"
                  classNameWrapper={classes.button}
                  disabled={loading}
                  loading={loading}
                >
                  {translate(
                    data
                      ? 'resources.billing.updateButton'
                      : 'pages.role.permissions.create',
                  )}
                </ReusableButton>
              )}
            </div>
          </form>
        )}
      />
    </Paper>
  );
};

export default DmSettingsForm;
