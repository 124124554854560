import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  tableContainer: {
    margin: '1rem 16px 0',
    '& tbody': {
      maxHeight: '75vh',
    },
  },
  button: {
    float: 'right',
    marginBottom: '8px',
  },
  logDetailHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
