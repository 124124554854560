import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px',
    padding: '20px',
    maxHeight: '80vh',
    overflowY: 'auto',
    background: 'white',
    borderRadius: '15px',
    '& > div': {
      margin: '20px 0px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: '10px',
    },
  },
  table: {
    '& table': {
      maxHeight: '74vh',
    },
  },
}));

export default useStyles;
