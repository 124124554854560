import React, { useEffect, useMemo, useState } from 'react';
import {
  useNotify,
  usePermissions,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import Authorize from '@common/Authorize/Authorize';
import { TICKETING, TICKETING_CREATE } from '@constants/routes';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import resources from '@constants/resources';
import iconEdit from '@assets/icons/edit.svg';
import iconView from '@assets/icons/visibility_blue.svg';
import iconDelete from '@assets/icons/delete.svg';
import { basePermissions } from '@constants/permissions';
import NotificationTypes from '@components/Ticketing/Notifications/TicketSettings/NotificationTypes';
import StatusCircle from '@common/StatusCircle/StatusCircle';
import CopyIcon from '@assets/icons/copy_blue.svg';
import StatusesAccessor from '@common/MuiVirtualizedTable/components/accessors/StatusesAccessor';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import { deleteSetting, sanitizeProps, toggleEnabled } from './helpers';
import ListActions from './components/ListActions';
import MakeCopyDialog from './components/dialogs/MakeCopyDialog';
import notificationSources, {
  notificationSourcesOptions,
} from './notificationSources';
import useStyles from './Form/stylesNotificationSettingsForm';
import BulkActionButtons from './components/BulkActionButtons';

const NotificationSettings = props => {
  const classes = useStyles();
  const redirect = useRedirect();
  const translate = useTranslate();
  const notify = useNotify();
  const dispatch = useDispatch();
  const { permissions } = usePermissions();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [makeCopyDialogOpen, setMakeCopyDialogOpen] = useState(false);
  const [clonedItem, setClonedItem] = useState({});
  const list = useSelector(listSelector);

  const resource = 'notificationSettings';

  const editIcon = useMemo(
    () =>
      permissions && permissions[resource][basePermissions.update]
        ? { src: iconEdit, alt: 'iconEdit', readOnly: false }
        : { src: iconView, alt: 'iconView', readOnly: true },
    [permissions, resource],
  );

  useEffect(() => {
    dispatch(actions.resetSetting());
  }, [dispatch]);

  const handleMakeDefault = async (id, isActive) => {
    if (editIcon.readOnly) {
      return;
    }
    await toggleEnabled(id, !isActive);

    const newArr = list.map(item =>
      item.id !== id ? item : { ...item, enabled: !isActive },
    );
    dispatch(actions.saveList(newArr));
  };

  const handleAddRecord = () => redirect(TICKETING_CREATE);

  const handleEditRecord = id => redirect(`${TICKETING}/${id}`);

  const handleDelete = id => {
    setIdForDelete(id);
    setDeleteDialogOpen(true);
  };

  const onCancelDeleteHandler = () => {
    setIdForDelete(null);
    setDeleteDialogOpen(false);
  };

  const onDeleteHandler = async () => {
    const result = await deleteSetting(idForDelete);
    if (!result) {
      notify('Unable to remove', 'warning');
    }
    const newArr = list.filter(({ id }) => id !== idForDelete);
    dispatch(actions.saveList(newArr));
    onCancelDeleteHandler();
  };

  const handleMakeCopy = item => {
    setClonedItem(item);
    setMakeCopyDialogOpen(true);
  };

  return (
    <div className={classes.notificationContainer}>
      <div className={classes.listActions}>
        <ListActions
          customResource={resources.notificationSettings}
          handleAddRecord={handleAddRecord}
        />
      </div>

      <Table
        rowHeight={50}
        bulkActions={<BulkActionButtons />}
        defaultSort={{ fieldName: 'Name', order: 'ASC' }}
        columns={[
          {
            name: 'Name',
            key: 'name',
            sortable: true,
            searchable: true,
            minWidth: 200,
          },
          {
            type: TableTypes.dropdown,
            dropDownValues: notificationSourcesOptions,
            filterByAutocomplete: 'notificationSettingSource',
            minWidth: 200,
            width: 200,
            sortable: true,
            searchable: true,
            name: 'Source',
            key: 'notificationSettingSource',
            isCustomAccessor: true,
            accessor: row => (
              <StatusesAccessor
                item={row.notificationSettingSource}
                array={notificationSources}
              />
            ),
          },
          {
            name: 'Notification Types',
            key: 'type',
            isCustomAccessor: true,
            accessor: row => <NotificationTypes record={row} />,
            minWidth: 350,
          },
          {
            name: 'Enabled',
            key: 'enabled',
            isCustomAccessor: true,
            width: 100,
            minWidth: 100,
            accessor: row => (
              <StatusCircle handleClick={handleMakeDefault} row={row} />
            ),
          },
          {
            name: 'Actions',
            key: TableTypes.actions,
            type: TableTypes.actions,
            width: 150,
            minWidth: 150,
            accessor: row => (
              <div className={classes.buttonGroup}>
                <ActionButton
                  handler={() => handleEditRecord(row.id)}
                  icon={<img src={editIcon.src} alt={editIcon.alt} />}
                  toolTip="Edit"
                />
                <ActionButton
                  handler={() => handleMakeCopy(row)}
                  icon={<img src={CopyIcon} alt="Make a copy" />}
                  toolTip="Clone"
                />
                <Authorize
                  {...sanitizeProps(props)}
                  customResource={resources.notificationSettings}
                  requiredPermissions={[basePermissions.delete]}
                >
                  <ActionButton
                    handler={() => handleDelete(row.id)}
                    icon={<img src={iconDelete} alt="iconDelete" />}
                    toolTip="Delete"
                  />
                </Authorize>
              </div>
            ),
          },
        ]}
        resource={resources.notificationSettings}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onDeleteHandler}
        onCancel={onCancelDeleteHandler}
        title={translate('resources.notificationSettings.deleteTitle')}
        content={translate('resources.notificationSettings.deleteContent')}
      />
      <MakeCopyDialog
        open={makeCopyDialogOpen}
        onClose={() => setMakeCopyDialogOpen(false)}
        item={clonedItem}
      />
    </div>
  );
};

export default NotificationSettings;
