import React, { useState } from 'react';
import { TableBody } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Add } from '@material-ui/icons';

import importTypes from '@constants/importTypes';
import exportIcon from '@assets/icons/export.svg';

import UploadDialog from '@components/UploadFileDialog/Dialog';
import TableHeader from '@components/UploadFileDialog/DetailsDialog/TableHeader';
import DetailsDialogItem from '@components/DeviceMonitors/detailsDialog/DetailsDialogItem';
import DetailsDialog from '@components/UploadFileDialog/DetailsDialog/DetailsDialog';
import ReusableButton from '@common/Button/Button';

import {
  DEVICE_MONITORS_EXPORT,
  DEVICE_MONITORS_CREATE,
} from '@constants/routes';

import useStyles from '../styles';
import { deviceMonitorsHeaders } from '@ui/pages/uptimeMonitors/DeviceMonitors/constants';

const TableActions = () => {
  const [openUploadModal, setOpenUploadModal] = React.useState(false);
  const [modalDetails, setModalDetails] = useState({
    open: false,
    data: {},
  });

  const classes = useStyles();
  const { push } = useHistory();

  const redirectToCreate = () => {
    push(DEVICE_MONITORS_CREATE);
  };

  return (
    <div className={classes.tableActions}>
      <ReusableButton
        onClick={() => {
          setOpenUploadModal(true);
        }}
        label="Upload"
        viewType="orange"
        size="md"
      >
        <>
          <img src={exportIcon} alt="Upload" />
          Upload
        </>
      </ReusableButton>

      <ReusableButton
        size="md"
        viewType="black"
        onClick={() => push(DEVICE_MONITORS_EXPORT)}
        icon={null}
        label="Export"
      >
        <>
          <img src={exportIcon} alt="export" />
          Export
        </>
      </ReusableButton>

      <ReusableButton onClick={redirectToCreate} size="md">
        <>
          <Add />
          Create
        </>
      </ReusableButton>

      {openUploadModal && (
        <UploadDialog
          open
          type={importTypes.DeviceMonitor}
          setOpen={setOpenUploadModal}
          setModalDetails={setModalDetails}
        />
      )}

      {modalDetails.open && (
        <DetailsDialog
          statistic={modalDetails.data.statistic}
          open={modalDetails.open}
          setModalDetails={setModalDetails}
        >
          <>
            <TableHeader headers={deviceMonitorsHeaders} />
            <TableBody>
              {modalDetails.data?.importedRows?.map(row => (
                <DetailsDialogItem key={row} row={row} />
              ))}
            </TableBody>
          </>
        </DetailsDialog>
      )}
    </div>
  );
};

export default TableActions;
