import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';

import {
  ThunkAddTeamsConnections,
  ThunkEditTeamsConnections,
} from '@store/slices/teamsConnections/thunks';
import { requiredValidator } from '@utils/validators';
import { actions } from '@store/actions';
import useTest from '@ui/components/Hooks/useTest';
import { testTeams } from '@ui/components/Ticketing/Notifications/TicketSettings/helpers';

import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import Input from '@ui/components/Auth/Common/Input';
import ReusableButton from '@ui/components/common/Button/Button';
import TestArea from '@ui/components/common/TestArea/TestArea';

import useStyles from '../styles';

const AddNewTeamsConnection = ({ open, setOpen, item = undefined }) => {
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState();

  const classes = useStyles();
  const dispatch = useDispatch();

  const { handleTestClick, testOutput } = useTest({
    setLoading,
    apiRequest: testTeams,
  });

  const submit = async values => {
    setProcessing(true);
    if (!item) {
      const connection = await dispatch(
        ThunkAddTeamsConnections(values),
      ).unwrap();

      dispatch(actions.updateItemInList(connection));
    } else {
      await dispatch(
        ThunkEditTeamsConnections({ id: item.id, payload: values }),
      ).unwrap();

      dispatch(actions.updateItemInList(values));
    }

    setProcessing(false);
    setOpen(false);
  };

  const validate = values => {
    return {
      name: requiredValidator(values.name),
      url: requiredValidator(values.url),
    };
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>
        {item ? 'Edit ' : 'Add '}
        New Connection
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={item || {}}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <FieldWrapper
                label="Name"
                labelSize={12}
                contentSize={12}
                showLabel
                isRequired
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="name"
                    id="name"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <FieldWrapper
                label="Url"
                labelSize={12}
                contentSize={12}
                showLabel
                isRequired
                classNameLabelInner={classes.alignLeft}
                content={
                  <Field
                    name="url"
                    id="url"
                    render={Input}
                    disabled={processing}
                  />
                }
              />

              <TestArea
                onClick={() =>
                  handleTestClick({ url: form.getFieldState('url').value })
                }
                loading={loading}
                testOutput={testOutput}
              />

              <div className={classes.dialogActionsContainer}>
                {/* <ReusableButton
                   
                   
                  label="Test"
                  onClick={() => []}
                /> */}

                <ReusableButton
                  label="Cancel"
                  onClick={() => {
                    setOpen(false);
                  }}
                />

                <ReusableButton label="Submit" type="submit" />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

AddNewTeamsConnection.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default AddNewTeamsConnection;
