import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import BagelChart from '@common/Bagels/BagelChart';
import { getMonitorCountStatuses } from '@components/DeviceMonitors/helpers';

import useStyles from '../styles';

const BagelContainer = () => {
  const classes = useStyles();

  const [counts, setCounts] = useState({});
  const { successful, pause, down, total } = counts;

  useEffect(() => {
    getMonitorCountStatuses()
      .then(data => setCounts(data))
      .catch(e => console.error(e.message));
  }, []);

  return (
    <div className={classes.bagelContainer}>
      <Grid container className={classes.bagelGridContainer}>
        <Grid className={classes.bagelGrid} item>
          <BagelChart
            allCount={total}
            value={5}
            count={successful}
            title="UP MONITORS"
            color="#67AD5B"
          />
        </Grid>
        <Grid className={classes.bagelGrid} item>
          <BagelChart
            allCount={total}
            count={down}
            value={4}
            title="DOWN MONITORS"
            color="#EC6A4E"
          />
        </Grid>
        <Grid className={classes.bagelGrid} item>
          <BagelChart
            allCount={total}
            count={pause}
            value={2}
            title="PAUSED MONITORS"
            color="#3A3E45"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BagelContainer;
