import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';

import ReusableButton from '@ui/components/common/Button/Button';

import { actions } from '@store/actions';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import { tableDefaultFilters } from '@components/AvailablePhoneNumbers/constants';
import PurchaseFilters from '@components/AvailablePhoneNumbers/components/PurchaseFilters';
import sharedStyles from '@components/CrmTicket/sharedStyles';
import { ThunkBuyPhoneNumber } from '@store/slices/phoneNumbers/thunks';
import InternationalFormatPhoneNumber from '@utils/InternationalFormatPhoneNumber';

const AvailablePhoneNumbers = () => {
  const classes = sharedStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const handleBuy = (phoneNumber, country, type, id) => {
    setLoading(true);
    dispatch(ThunkBuyPhoneNumber({ phoneNumber, country, type })).then(
      ({ error }) => {
        if (!error) {
          dispatch(actions.removeItemFromList({ id }));
          notify('The number is purchased', 'success');
        } else {
          notify(error.message, 'error');
        }
        setLoading(false);
      },
    );
  };

  const columns = [
    {
      name: 'Number',
      key: 'dedicatedNumber',
      isCustomAccessor: true,
      accessor: rowData =>
        InternationalFormatPhoneNumber(rowData.dedicatedNumber),
    },
    {
      name: 'Country',
      key: 'country',
    },
    {
      name: 'Monthly Fee',
      key: 'priceMonthly',
    },
    {
      name: 'Setup Fee',
      key: 'priceSetup',
      accessor: rowData => (
        <>{rowData.priceSetup === '0.0000' ? 'Free' : rowData.priceSetup}</>
      ),
    },
    {
      name: 'Actions',
      key: 'actions',
      accessor: rowData => (
        <ReusableButton
          label="Buy"
          onClick={() => {
            handleBuy(
              rowData.dedicatedNumber,
              rowData.country,
              rowData.type,
              rowData.id,
            );
          }}
          disabled={loading}
        />
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <MuiVirtualizedTable
        actions={<></>}
        columns={columns}
        resource="BufferPhoneNumbers/buy/availableNumbers"
        defaultFilters={tableDefaultFilters}
      >
        <PurchaseFilters />
      </MuiVirtualizedTable>
    </div>
  );
};

export default AvailablePhoneNumbers;
