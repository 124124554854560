import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@assets/icons/delete.svg';
import EditIcon from '@assets/icons/edit.svg';
import ViewLogsIcon from '@assets/icons/documents.svg';
import LogDetailsIcon from '@assets/icons/calendar_green.svg'

import { default as useGlobalStyles } from '@common/Styles/actionFieldStyles';
import useStyles from './styles';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

const convertStringToView = value => {
  var mins_num = parseFloat(value, 10); 
  var hours = Math.floor(mins_num / 60);
  var minutes = Math.floor((mins_num - ((hours * 3600)) / 60));

  // Appends 0 when unit is less than 10
  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  return hours + ' hrs ' + minutes + ' min left';
}

const ActionField = ({
  onEdit,
  record,
  onViewLogs,
  onDelete,
  onAddDetailedLogs
}) => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const calendarClass = useMemo(() => record.isDetailedLogEnabled ? null : classes.grayScalse);
  const calendarToolTip = useMemo(() =>
    record.isDetailedLogEnabled
      ? convertStringToView(record.detailedLogMinutesLeft)
      : "Turn on detailed logging");

  return (
    <div className={globalClasses.container}>
      <ActionFieldItem handler={() => onEdit(record)} toolTip="Edit">
        <img src={EditIcon} alt="edit" />
      </ActionFieldItem>

      <ActionFieldItem handler={() => onViewLogs(record)} toolTip="View logs">
        <img src={ViewLogsIcon} alt="logs" />
      </ActionFieldItem>

      <ActionFieldItem handler={() => onAddDetailedLogs(record)} toolTip={calendarToolTip} classNameWrapper={calendarClass}>
        <img src={LogDetailsIcon} alt="calendar" />
      </ActionFieldItem>

      <ActionFieldItem handler={() => onDelete(record)} toolTip="Delete">
        <img src={DeleteIcon} alt="delete" />
      </ActionFieldItem>
    </div>
  );
};

ActionField.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isDetailedLogEnabled: PropTypes.bool.isRequired,
    detailedLogMinutesLeft: PropTypes.string,
  }),
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onViewLogs: PropTypes.func.isRequired,
  onAddDetailedLogs: PropTypes.func.isRequired
};

export default ActionField;
