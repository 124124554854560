import React from 'react';
import PropTypes from 'prop-types';
import { useRedirect, useTranslate } from 'react-admin';
import { WEB_HOOKS, WEB_HOOKS_CREATE } from '@constants/routes';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import useDeleteRecord from '@components/DeviceMonitors/hook/useDeleteRecord';
import { deleteRecord } from '@components/WebHooks/helpers';
import { TableTypes } from '@components/Table/constants';
import Table from '@components/Table';
import ActionField from '../components/ActionField';
import ListActions from '../components/ListActions';

import useStyles from '../styles';

const List = ({ resource }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const redirect = useRedirect();

  const {
    onCancel,
    open,
    loading,
    handleDeleteIcon,
    onDelete,
  } = useDeleteRecord(deleteRecord);

  const createPage = () => redirect(WEB_HOOKS_CREATE);
  const editPage = id => redirect(`${WEB_HOOKS}/${id}`);

  return (
    <div className={classes.tableWrapper}>
      <ListActions createPage={createPage} />
      <Table
        columns={[
          {
            name: 'Integration Name',
            key: 'integrationName',
            sortable: true,
            searchable: true,
          },
          {
            name: 'Integration Guid',
            key: 'integrationGuid',
            sort: 'integrationGuid',
          },
          {
            name: 'Actions',
            key: TableTypes.actions,
            type: TableTypes.actions,
            accessor: rowData => (
              <ActionField
                label="Actions"
                record={rowData}
                onEdit={row => editPage(row.id)}
                onDelete={row => handleDeleteIcon(row.id)}
              />
            ),
            width: 150,
          },
        ]}
        resource={resource}
        defaultSort={{ fieldName: 'integrationName', order: 'ASC' }}
      />
      <ConfirmDialog
        open={open}
        onCancel={onCancel}
        onAccept={onDelete}
        loading={loading}
        title={translate('resources.webHooks.modal.deleteTitle')}
        content={translate('resources.webHooks.modal.deleteContent')}
      />
    </div>
  );
};

List.propTypes = {
  resource: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default List;
