import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';
import AddGroupIcon from '@assets/icons/network.svg';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import { Menu, MenuItem } from '@material-ui/core';
import { addToGroup } from '../helpers';

const ActionField = ({ record, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAddToGroup = item => {
    const data = {
      name: record.name,
      email: record.email,
      notificationGroupId: item.value,
    };
    addToGroup(data)
      .then(() =>
        enqueueSnackbar(`User successfully added to ${item.label} group`, {
          variant: 'success',
        }),
      )
      .catch(e => enqueueSnackbar(e.message, 'error'));
  };

  return (
    <>
      <ActionFieldItem
        handler={e => {
          if (options.length) {
            setAnchorEl(e.target);
          }
        }}
        toolTip="Add to group"
        disabled={!options.length}
      >
        <img src={AddGroupIcon} alt="add to group" />
      </ActionFieldItem>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {options.map(i => (
          <MenuItem
            key={i.value}
            onClick={() => {
              setAnchorEl(null);
              handleAddToGroup(i);
            }}
          >
            {i.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

ActionField.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.arrayOf(PropTypes.any),
};

export default ActionField;
