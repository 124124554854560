import { createAsyncThunk } from '@reduxjs/toolkit';
import { OUTGOING_MAIL } from '@store/slices/resources';
import { setupOutboundMailbox } from '@services/requests/outGoingMail';

export const ThunkSetupOutgoingMailbox = createAsyncThunk(
  `${OUTGOING_MAIL}/setupOutGoingMailbox`,
  payload => setupOutboundMailbox(payload),
);

export default {};
