import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';

import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import CustomCheckbox from '@common/Checkbox/Checkbox';
import DropDown from '@components/Auth/Common/DropDown';
import useStyles from './ignoreModelStyles';
import { addIgnoreRules } from '../../../helpers';
import IgnoreModalFormElement from './IgnoreModalFormElement';

const IgnoreModal = ({ open, close, mailBoxId, from = '', subject = '' }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const initData = {
    mailBoxId: +mailBoxId,
    from,
    subject,
    isFromEnable: true,
    isSubjectEnable: true,
    fromOperator: 'Equals',
    subjectOperator: 'Equals',
    operator: 'OR',
  };

  const submit = values => {
    setLoading(true);
    addIgnoreRules(values)
      .then(() =>
        enqueueSnackbar('Rules successfully updated', { variant: 'success' }),
      )
      .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => {
        setLoading(false);
        close();
      });
  };

  const options = [
    { label: 'Equals', value: 'Equals' },
    { label: 'Contains', value: 'Contains' },
  ];

  const operatorOptions = [
    { label: 'OR', value: 'OR' },
    { label: 'AND', value: 'AND' },
  ];

  return (
    <>
      <Dialog open={open} onClose={close} aria-labelledby="ignore-modal-title">
        <DialogTitle id="simple-dialog-title">Add ignore rules</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={submit}
            initialValues={initData}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <IgnoreModalFormElement
                  label="From"
                  infoText="Ignore from sender"
                >
                  <>
                    <Field
                      id="fromOperator"
                      name="fromOperator"
                      component={DropDown}
                      classNameWrapper={classes.operatorWrapper}
                      options={options}
                      disabled={loading}
                    />
                    <div className={classes.spaceDiv} />
                    <Field
                      name="from"
                      id="outlined-basic"
                      placeholder="From"
                      styleType="main"
                      inputView="text"
                      fullWidth
                      component={Input}
                      classNameWrapper={classes.inputWrapper}
                      disabled={loading}
                    />
                    <Field
                      type="checkbox"
                      id="isFromEnable"
                      name="isFromEnable"
                      component={CustomCheckbox}
                      disabled={loading}
                    />
                  </>
                </IgnoreModalFormElement>
                <Field
                  id="operator"
                  name="operator"
                  component={DropDown}
                  classNameWrapper={classes.operator}
                  options={operatorOptions}
                  disabled={loading}
                />
                <IgnoreModalFormElement
                  label="Subject"
                  infoText="Subject for ignoring"
                >
                  <>
                    <Field
                      id="subjectOperator"
                      name="subjectOperator"
                      component={DropDown}
                      classNameWrapper={classes.operatorWrapper}
                      options={options}
                      disabled={loading}
                    />
                    <div style={{ width: '10px' }} />
                    <Field
                      name="subject"
                      id="outlined-basic"
                      placeholder="Subject"
                      styleType="main"
                      inputView="text"
                      fullWidth
                      component={Input}
                      classNameWrapper={classes.inputWrapper}
                      disabled={loading}
                    />
                    <Field
                      type="checkbox"
                      id="isSubjectEnable"
                      name="isSubjectEnable"
                      component={CustomCheckbox}
                      disabled={loading}
                    />
                  </>
                </IgnoreModalFormElement>

                <div className={classes.buttonWrapper}>
                  <ReusableButton
                    onClick={close}
                    label="resources.buttons.cancel"
                    classNameWrapper={classes.button}
                  />
                  <ReusableButton
                    viewType="black"
                    type="submit"
                    label="resources.buttons.submit"
                    classNameWrapper={classes.button}
                  />
                </div>
              </form>
            )}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

IgnoreModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  mailBoxId: PropTypes.string.isRequired,
  from: PropTypes.string,
  subject: PropTypes.string,
};

export default IgnoreModal;
