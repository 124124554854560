import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { enqueueSnackbar } from 'notistack';

import Loading from '@common/Loading/Loading';
import useAsync from '@services/api/common/useAsync';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import crmSources from '@constants/crmSources';
import SyncroDefaultForm from '@components/Ticketing/Integrations/pages/Syncro/SyncroDefaultForm';
import {
  createTicketDefault,
  deleteDefault,
  getDefaults,
  makeDefaultEnabled,
  updateTicketDefault,
} from '../../../helpers';
import DefaultsTable from '../Table/DefaultsTable';
import CrmDefaultForm from './CrmDefaultForm';
import HaloDefaults from '../../HaloIntegration/HaloDefaults';

import useStyles from '../styles';
import AutotaskDefaultForm from '../../Autotask/AutotaskDefaultForm';
import ConnectWiseDefaultForm from '../../ConnectWise/ConnectWiseDefaultForm';
import KaseyaDefaultForm from '../../Kaseya/KaseyaDefaultForm';
import ZendeskDefaultForm from '../../Zendesk/ZendeskDefaultForm';
import SuperOpsDefaultForm from '../../SuperOps/SuperOpsDefaultForm';

const CrmDefaults = ({ id, crmType, handleShowCrmInfoFormChange }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [data, setData] = useState({});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);
  const [editingItem, setEditingItem] = useState({});
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [loading, setLoading] = useState(false);
  const getData = useCallback(() => getDefaults(id), [id]);
  useAsync(getData, setData);

  const invokeRefresh = () => {
    getDefaults(id).then(d => setData(d));
  };

  const isDataLoaded = Array.isArray(data);

  const create = async value => {
    if (await createTicketDefault(value)) {
      enqueueSnackbar('Created', { variant: 'success' });
      return;
    }
    enqueueSnackbar('Unable to create ticket default', { variant: 'error' });
  };

  const update = async value => {
    if (await updateTicketDefault(value.id, value)) {
      enqueueSnackbar('Record updated', { variant: 'success' });
      return;
    }
    enqueueSnackbar('Unable to update ticket default', { variant: 'error' });
  };

  const handleSubmitForm = async value => {
    const model = {
      crmSourceId: id,
      default: false,
      ...value,
    };
    if (!model.id) {
      await create(model);
    } else {
      await update(model);
    }
    invokeRefresh();
    setEditingItem({});
    setDisplayForm(false);
  };

  const handleAddClick = () => {
    setEditingItem(undefined);
    setDisplayForm(true);
    handleShowCrmInfoFormChange(false);
  };

  const handleEditItem = item => {
    setEditingItem(item);
    setDisplayForm(true);
    handleShowCrmInfoFormChange(false);
  };

  const handleHideForm = useCallback(() => {
    setEditingItem({});
    setDisplayForm(false);
    handleShowCrmInfoFormChange(true);
  }, [handleShowCrmInfoFormChange]);

  const handleMakeDefault = async itemId => {
    await makeDefaultEnabled(itemId);
    invokeRefresh();
  };

  const onDeleteHandler = deletingId => {
    setDeletingItemId(deletingId);
    setDeleteDialogOpen(true);
  };

  const deleteTicketDefault = async () => {
    setLoading(true);
    if (await deleteDefault(deletingItemId)) {
      enqueueSnackbar('Record removed', { variant: 'success' });
      invokeRefresh();
    } else {
      enqueueSnackbar('Unable to delete ticket default', { variant: 'error' });
    }
    setDeletingItemId(null);
    setDeleteDialogOpen(false);
    setLoading(false);
  };

  const onCancelDeleteHandler = () => {
    setDeletingItemId(null);
    setDeleteDialogOpen(false);
  };

  const getCrmForm = useMemo(() => {
    switch (crmType) {
      case crmSources.Halo.name:
        return (
          <HaloDefaults
            data={editingItem}
            onBack={handleHideForm}
            onSubmit={handleSubmitForm}
            crmId={id}
          />
        );
      case crmSources.Autotask.name:
        return (
          <AutotaskDefaultForm
            data={editingItem}
            onBack={handleHideForm}
            onSubmit={handleSubmitForm}
            crmId={id}
          />
        );
      case crmSources.ConnectWise.name:
        return (
          <ConnectWiseDefaultForm
            data={editingItem}
            onBack={handleHideForm}
            onSubmit={handleSubmitForm}
            crmId={id}
          />
        );
      case crmSources.Kaseya.name:
        return (
          <KaseyaDefaultForm
            data={editingItem}
            onBack={handleHideForm}
            onSubmit={handleSubmitForm}
            crmId={id}
          />
        );
      case crmSources.Zendesk.name:
        return (
          <ZendeskDefaultForm
            data={editingItem}
            onBack={handleHideForm}
            onSubmit={handleSubmitForm}
            crmId={id}
          />
        );
      case crmSources.SuperOps.name:
        return (
          <SuperOpsDefaultForm
            data={editingItem}
            onBack={handleHideForm}
            onSubmit={handleSubmitForm}
            crmId={id}
          />
        );
      case crmSources.SyncroMsp.name:
        return (
          <SyncroDefaultForm
            data={editingItem}
            onBack={handleHideForm}
            onSubmit={handleSubmitForm}
            crmId={id}
          />
        );
      default:
        return (
          <CrmDefaultForm
            data={editingItem}
            onBack={handleHideForm}
            onSubmit={handleSubmitForm}
            crmId={id}
          />
        );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmType, id, editingItem]);

  const content = () => {
    if (isDataLoaded) {
      return (
        <div>
          <div className={classes.header}>
            <div>Integration Ticket Defaults</div>
          </div>
          {displayForm ? (
            getCrmForm
          ) : (
            <DefaultsTable
              data={data}
              setData={setData}
              handleAddRecord={handleAddClick}
              handleEditRecord={handleEditItem}
              handleMakeDefaultRecord={handleMakeDefault}
              handleDeleteRecord={onDeleteHandler}
            />
          )}
          <ConfirmDialog
            open={!!deleteDialogOpen}
            setOpen={setDeleteDialogOpen}
            onAccept={deleteTicketDefault}
            onCancel={onCancelDeleteHandler}
            title={translate(
              'resources.integrations.update.ticketDefault.deleteTitle',
            )}
            content={translate(
              'resources.integrations.update.ticketDefault.deleteContent',
            )}
            loading={loading}
          />
        </div>
      );
    }
    return <Loading />;
  };
  return content();
};

CrmDefaults.propTypes = {
  id: PropTypes.string.isRequired,
  crmType: PropTypes.string,
  handleShowCrmInfoFormChange: PropTypes.func,
};

export default CrmDefaults;
