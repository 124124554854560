import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ViewIcon from '@assets/icons/visibility.svg';
import AlertIcon from '@assets/icons/dangerTriangle.svg';
import GenericSourcesLogsDetails from '@components/GenericSources/pages/GenericSourcesLogs/components/GenericSourcesLogsDetails';
import GenericSourcesStackTraceDetails from '@components/GenericSources/pages/GenericSourcesLogs/components/GenericSourcesStackTraceDetails';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

const GenericSourcesLogsTableActions = ({ record }) => {
  const [logDetailsOpen, setLogsDetailsOpen] = useState(false);
  const [stackTraceOpen, setStackTraceOpen] = useState(false);

  const disabledToolTip = record.stackTrace
    ? ''
    : '. This option is disabled because this log doesn’t have a Stack Trace.';

  return (
    <>
      <ActionFieldItem
        alt="Logs"
        handler={() => setLogsDetailsOpen(true)}
        toolTip="Logs"
        icon={ViewIcon}
      />
      <ActionFieldItem
        alt="Stack Trace"
        handler={() => setStackTraceOpen(true)}
        toolTip={`StackTrace${disabledToolTip}`}
        icon={AlertIcon}
        disabled={!record.stackTrace}
      />
      {logDetailsOpen && (
        <GenericSourcesLogsDetails
          open={logDetailsOpen}
          setOpen={setLogsDetailsOpen}
          logDetails={record.executionDetails}
        />
      )}
      {stackTraceOpen && (
        <GenericSourcesStackTraceDetails
          open={stackTraceOpen}
          setOpen={setStackTraceOpen}
          stackTrace={record.stackTrace}
        />
      )}
    </>
  );
};

GenericSourcesLogsTableActions.propTypes = {
  record: PropTypes.objectOf({
    id: PropTypes.string.isRequired,
    dateTime: PropTypes.string.isRequired,
    tenantId: PropTypes.number.isRequired,
    entityId: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    stackTrace: PropTypes.string,
    executionDetails: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default GenericSourcesLogsTableActions;
