import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { OnChange } from 'react-final-form-listeners';
import { useHistory } from 'react-router-dom';

import {
  ThunkCheckDealerCode,
  ThunkRegisterUser,
} from '@store/slices/auth/thunks';
import {
  getCountriesOptions,
  getStatesOptions,
} from '@constants/countries/countries';
import { requiredValidator } from '@utils/validators';
import useValidate from '@components/Hooks/useValidate';
import { LOGIN_ROUTE } from '@constants/routes';

import AppLogo from '@ui/common/AppLogo';
import Input from '@components/Auth/Common/Input';
import TextMask from '@components/Auth/Common/creditCardInput';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import ReusableButton from '@common/Button/Button';
import CardSummary from './CardSummary';

import { signUpPages } from '../constants';
import { SignInContext, SingInDispatchContext } from '../context';

import useLoginStyles from '../../Login/styles';
import useStyles from '../styles';
import { enqueueSnackbar } from 'notistack';

const CardData = ({ handleBack, pricePlans }) => {
  const [loading, setLoading] = useState(false);
  const [discountData, setDiscountData] = useState(undefined);

  const dispatch = useDispatch();
  const sharedClasses = useLoginStyles();
  const classes = useStyles();
  const contextDispatch = useContext(SingInDispatchContext);
  const { push } = useHistory();
  const { cardData, userData, technicianCount, selectedPlanId } = useContext(
    SignInContext,
  );
  const {
    numberInRange,
    numberLengthRange,
    validateCreditCardNumber,
  } = useValidate();

  const handleCouponEnter = useCallback(
    dealerCode => {
      dispatch(ThunkCheckDealerCode({ code: dealerCode }))
        .unwrap()
        .then(res => {
          if (res?.description) {
            setDiscountData(res);
          } else {
            setDiscountData(undefined);
          }
        });
    },
    [dispatch],
  );

  const validate = values => {
    return {
      cardHolder: requiredValidator(values.cardHolder),
      cardNumber: validateCreditCardNumber(values.cardNumber),
      billingEmail: requiredValidator(values.billingEmail),
      country: requiredValidator(values.country),
      state: requiredValidator(values.state),
      cardExpMonth: numberInRange(values.cardExpMonth, 1, 12),
      cardExpYear: numberInRange(values.cardExpYear, 22, 35),
      cardCvc: numberLengthRange(values.cardCvc, 3, 4),
    };
  };

  const submit = values => {
    setLoading(true);
    const payload = {
      ...values,
      ...userData,
      techCount: technicianCount,
      pricingPlan: selectedPlanId,
    };
    dispatch(ThunkRegisterUser({ payload }))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Successfully created.', { variant: 'success' });
        push(LOGIN_ROUTE);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      initialValues={cardData}
      render={({ handleSubmit, values }) => (
        <>
          <CardSummary
            pricePlans={pricePlans}
            name={values.fullName}
            cardNumber={values.cardNumber}
            expirationMonth={values.cardExpMonth}
            expirationYear={values.cardExpYear}
            discountData={discountData}
          />
          <div className={sharedClasses.activeContainer}>
            <AppLogo />
            <div>
              <form onSubmit={handleSubmit}>
                <div className={sharedClasses.fieldItem}>
                  <p>Card Holder Full Name</p>
                  <Field
                    id="cardHolder"
                    name="cardHolder"
                    component={Input}
                    placeholder="Enter your full name"
                    disabled={loading}
                    autoComplete="name"
                  />
                </div>

                <div className={sharedClasses.fieldItem}>
                  <p>Card Number</p>
                  <Field
                    id="cardNumber"
                    name="cardNumber"
                    inputView="text"
                    styleType="main"
                    placeholder="**** **** **** ****"
                    fullWidth
                    component={Input}
                    InputProps={{ inputComponent: TextMask }}
                    disabled={loading}
                  />
                </div>

                <div className={sharedClasses.fieldItem}>
                  <p>Card Data</p>
                  <div className={classes.inputGroup}>
                    <Field
                      id="cardExpMonth"
                      name="cardExpMonth"
                      styleType="main"
                      inputView="text"
                      placeholder="Month"
                      fullWidth
                      component={Input}
                      classNameWrapper={classes.inputWrapper}
                      disabled={loading}
                    />
                    <Field
                      id="cardExpYear"
                      name="cardExpYear"
                      styleType="main"
                      inputView="text"
                      placeholder="Year"
                      fullWidth
                      component={Input}
                      classNameWrapper={classes.inputWrapper}
                      disabled={loading}
                    />
                    <Field
                      id="cardCvc"
                      name="cardCvc"
                      styleType="main"
                      inputView="text"
                      placeholder="CVV"
                      fullWidth
                      component={Input}
                      classNameWrapper={classes.inputWrapper}
                      disabled={loading}
                    />
                  </div>
                </div>

                <div className={sharedClasses.fieldItem}>
                  <p>Location</p>

                  <div className={classes.inputGroup}>
                    <Field
                      id="country"
                      name="country"
                      component={AutocompleteFormInput}
                      label="Country"
                      items={getCountriesOptions()}
                      classNameWrapper={classes.inputWrapper}
                      disabled={loading}
                    />
                    <Field
                      id="state"
                      name="state"
                      component={AutocompleteFormInput}
                      label="State"
                      items={getStatesOptions(values.country)}
                      classNameWrapper={classes.inputWrapper}
                      disabled={loading}
                    />
                    <Field
                      id="zipCode"
                      name="zipCode"
                      component={Input}
                      label="Zip Code"
                      disabled={loading}
                    />
                  </div>
                </div>

                <div className={sharedClasses.fieldItem}>
                  <p>Billing Email</p>
                  <Field
                    id="billingEmail"
                    name="billingEmail"
                    styleType="main"
                    inputView="text"
                    fullWidth
                    component={Input}
                    placeholder="Enter your email address"
                    disabled={loading}
                  />
                </div>

                <div className={sharedClasses.fieldItem}>
                  <p>Dealer Code</p>

                  <div className={classes.inputGroup}>
                    <Field
                      id="coupon"
                      name="coupon"
                      styleType="main"
                      inputView="text"
                      fullWidth
                      component={Input}
                      placeholder="Enter your dealer code"
                      disabled={loading}
                    />

                    <OnChange name="coupon">{handleCouponEnter}</OnChange>
                  </div>
                </div>

                <div className={classes.billingButtons}>
                  <ReusableButton
                    label="Back"
                    onClick={() => {
                      contextDispatch({
                        type: 'setCardData',
                        cardData: values,
                      });
                      handleBack(signUpPages.cardData);
                    }}
                    disabled={loading}
                  />
                  <ReusableButton
                    label="Register"
                    type="submit"
                    viewType="blue"
                    loading={loading}
                    disabled={loading}
                  />
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    />
  );
};

CardData.propTypes = {
  handleBack: PropTypes.func.isRequired,
  pricePlans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

export default CardData;
