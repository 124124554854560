import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { sanitizeListRestProps, TopToolbar, useTranslate } from 'react-admin';
import { Add } from '@material-ui/icons';
import ReusableButton from '@common/Button/Button';
import { basePermissions } from '@constants/permissions';
import Authorize from '@common/Authorize/Authorize';
import useStyles from '../styles';

const ListActions = props => {
  const classes = useStyles();
  const translate = useTranslate();

  const { className, maxResults, onRefresh, createPage, ...rest } = props;

  return (
    <TopToolbar className={cx(className)} {...sanitizeListRestProps(rest)}>
      <Authorize
        customResource="webHooks"
        requiredPermissions={[basePermissions.create]}
      >
        <ReusableButton
          size="md"
          onClick={createPage}
          classNameWrapper={classes.buttonWrapper}
        >
          <>
            <Add className={classes.plusIcon} />
            {translate('common.create')}
          </>
        </ReusableButton>
      </Authorize>
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  exporter: PropTypes.func,
  filters: PropTypes.objectOf(PropTypes.any),
  maxResults: PropTypes.number,
  onRefresh: PropTypes.func,
  createPage: PropTypes.func,
};

export default ListActions;
