import React from 'react';
import Table from '@components/Table';
import useStyles from './styles';

const ParsingStatus = () => {
  const classes = useStyles();
  return (
    <div className={classes.table}>
      <Table
        columns={[
          {
            name: 'Tenant Name',
            key: 'tenantName',
            sortable: true,
            searchable: true,
            minWidth: 200,
          },
          {
            name: 'Box Name',
            key: 'name',
            width: 'auto',
            sortable: true,
            searchable: true,
            minWidth: 150,
          },
          {
            name: 'Parsers',
            key: 'parsersCount',
            width: 110,
            minWidth: 110,
            sortable: true,
          },
          {
            name: 'Total',
            key: 'total',
            width: 100,
            minWidth: 110,
            sortable: true,
          },
          {
            name: 'Processed',
            key: 'processed',
            width: 130,
            minWidth: 130,
            sortable: true,
          },
          {
            name: 'Attention',
            key: 'attentionRequired',
            sortable: true,
            width: 130,
            minWidth: 130,
          },
          {
            name: 'Failed',
            key: 'failed',
            width: 100,
            minWidth: 100,
            sortable: true,
          },
          {
            name: 'Quota',
            key: 'quotaExceeded',
            width: 110,
            minWidth: 110,
            sortable: true,
          },
          {
            name: 'Ignored',
            key: 'ignored',
            width: 120,
            minWidth: 120,
            sortable: true,
          },
        ]}
        defaultSort={{ fieldName: 'tenantName', order: 'ASC' }}
        resource="mailBoxes/statistic"
      />
    </div>
  );
};

export default ParsingStatus;
