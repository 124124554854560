import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import PropTypes from 'prop-types';

import DateTimeField from '@common/TableComponents/DateTimeField';
import { actions } from '@store/actions';
import UsageField from '@components/Admin/Subscriptions/UsageField';
import BooleanAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanAccessor';
import useTranslation from '@common/hooks/useTranslation';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import booleanOptions from '@utils/constants/options';
import ActionField from './ActionField';
import { pauseSubscription, syncWithStripe } from './helpers';

import useStyles from '../styles';

const List = props => {
  const { resource } = props;
  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const { getLabel } = useTranslation(resource);
  const [loading, setLoading] = useState(false);

  const handleToggleStateClick = useCallback(
    item => {
      setLoading(true);
      pauseSubscription(item.stripeId)
        .then(resp => {
          dispatch(actions.updateItemInList(resp));
        })
        .catch(e => notify(e.message, 'error'))
        .finally(() => setLoading(false));
    },
    [dispatch, notify],
  );

  const handleSyncClick = useCallback(
    item => {
      setLoading(true);
      syncWithStripe(item.stripeId)
        .then(resp => {
          dispatch(actions.updateItemInList(resp));
        })
        .catch(e => notify(e.message, 'error'))
        .finally(() => setLoading(false));
    },
    [dispatch, notify],
  );

  const columns = useMemo(
    () => [
      {
        name: 'Tenant Name',
        key: 'tenantName',
        sortable: true,
        searchable: true,
        minWidth: 200,
      },
      {
        name: 'Tenant Admin',
        searchable: true,
        key: 'tenantAdmin',
        minWidth: 200,
      },
      {
        name: 'is Active',
        key: 'active',
        isCustomAccessor: true,
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: booleanOptions,
        filterByAutocomplete: 'active',
        minWidth: 130,
        accessor: rowData => (
          <BooleanAccessor value={rowData.active} useMultiColor />
        ),
      },
      {
        name: 'Is Demo',
        key: 'isDemoSubscription',
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: booleanOptions,
        filterByAutocomplete: 'isDemoSubscription',
        isCustomAccessor: true,
        minWidth: 130,
        accessor: rowData => (
          <BooleanAccessor value={rowData.isDemoSubscription} useMultiColor />
        ),
      },
      {
        name: 'Status',
        key: 'status',
        sort: 'status',
        minWidth: 100,
        width: 100,
      },
      {
        name: 'Price plan',
        key: 'planId',
        sort: 'planId',
        minWidth: 100,
        width: 100,
      },
      {
        name: 'Alerts',
        key: 'emailUsage',
        minWidth: 100,
        width: 100,
        isCustomAccessor: true,
        accessor: rowData => (
          <UsageField
            record={rowData}
            quota="monthlyQuota"
            usage="emailUsage"
          />
        ),
      },
      {
        name: 'SMS',
        key: 'smsUsage',
        minWidth: 100,
        width: 100,
        isCustomAccessor: true,
        accessor: rowData => (
          <UsageField
            record={rowData}
            quota="monthlySmsQuota"
            usage="smsUsage"
          />
        ),
      },
      {
        name: 'Monitors',
        key: 'devicesUsage',
        isCustomAccessor: true,
        minWidth: 100,
        width: 100,
        accessor: rowData => (
          <UsageField
            record={rowData}
            quota="devicesQuota"
            usage="devicesUsage"
          />
        ),
      },

      {
        name: 'Stripe Id',
        key: 'stripeId',
        minWidth: 250,
      },
      {
        name: 'Next Payment',
        key: 'nextPayment',
        minWidth: 200,
        isCustomAccessor: true,
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="nextPayment"
            source="nextPayment"
            label={getLabel('nextPayment')}
            sortable
          />
        ),
      },
      {
        name: 'Last Stripe Sync',
        key: 'lastSyncDate',
        sort: 'lastSyncDate',
        isCustomAccessor: true,
        minWidth: 200,
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="lastSyncDate"
            source="lastSyncDate"
            label={getLabel('lastSyncDate')}
            sortable
          />
        ),
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 100,
        minWidth: 100,
        accessor: rowData => (
          <ActionField
            label="Actions"
            record={rowData}
            handleToggleStateClick={handleToggleStateClick}
            handleSyncClick={handleSyncClick}
            loading={loading}
          />
        ),
      },
    ],
    [getLabel, handleSyncClick, handleToggleStateClick, loading],
  );

  return (
    <div className={classes.subscriptionsTable}>
      <Table
        columns={columns}
        resource={resource}
        defaultSort={{ fieldName: 'tenantName', order: 'ASC' }}
      />
    </div>
  );
};

List.propTypes = {
  resource: PropTypes.string,
};

export default List;
