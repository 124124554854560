import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts';
import cx from 'classnames';
import Paper from '@common/Paper/Paper';
import { deviceMonitorsEvents } from '@ui/pages/uptimeMonitors/DeviceMonitors/constants';
import { ReactComponent as ChartIcon } from '@assets/icons/chart.svg';
import useStyles from './styles';
import StatisticContainer from './StatisticContainer';
import { calculateDurationEventTime } from '../utils';

const timeFormatter = (value, format) =>
  moment
    .parseZone(value)
    .local()
    .format(format);

const tickFormatterX = value => moment(value).format('HH:mm');

const CustomTooltip = ({ active, payload, label }) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    const [hoveredObject] = payload;
    const text = `${hoveredObject.value} milliseconds`;
    const date = `Date: ${timeFormatter(hoveredObject.payload.dateTime, 'L')}`;
    const server = `Server: ${hoveredObject.payload.server}`;
    return (
      <div className={classes.tooltipContainer}>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          className={cx(classes.labelTooltipWeight, classes.labelTooltipColor)}
        >
          {timeFormatter(label, 'LTS')}
        </Typography>
        <Typography variant="span" className={classes.labelTooltipWeight}>
          {date}
        </Typography>
        <Typography className={classes.labelTooltipWeight}>{text}</Typography>
        <Typography className={classes.labelTooltipWeight}>{server}</Typography>
      </div>
    );
  }
  return null;
};
const DetailsChart = memo(({ metrics, statistic, lastEvent }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const event = deviceMonitorsEvents[statistic.currentStatus];
  const iconColor = event?.color;

  const eventName = `${event?.name}time`;

  const getMargin = () => {
    const defaultSpace = {
      left: -25,
    };
    return matches
      ? { right: 30, top: 20, bottom: 20, ...defaultSpace }
      : defaultSpace;
  };

  return (
    <Paper classNameContainer={classes.paperContainer}>
      <Box className={classes.innerPaperContainer}>
        <Grid item xs={12} sm={9} className={classes.chartContainer}>
          <Box display="flex" alignItems="center">
            <Box
              fontSize="1.5rem"
              display="flex"
              color="#3A3E45"
              fontWeight="bold"
            >
              <ChartIcon fill={iconColor} />
              <span className={classes.leftSpacing}>{eventName}</span>
            </Box>
            <Box
              color={iconColor}
              marginLeft="5px"
              fontWeight="bold"
              fontSize="1rem"
            >
              last
            </Box>
            <Box
              color={iconColor}
              marginLeft="5px"
              fontWeight="bold"
              fontSize="1rem"
            >
              {calculateDurationEventTime(lastEvent).duration}
            </Box>
          </Box>
          <ResponsiveContainer>
            <AreaChart data={metrics} margin={getMargin()}>
              <XAxis dataKey="dateTime" tickFormatter={tickFormatterX} />
              <YAxis />
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <Area
                dataKey="durationMs"
                stroke="#459636"
                strokeWidth={1}
                fill="#67AD5B"
              />
              <Tooltip content={props => <CustomTooltip {...props} />} />
            </AreaChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={12} sm={3} className={classes.statisticContainer}>
          <StatisticContainer statistic={statistic} />
        </Grid>
      </Box>
    </Paper>
  );
});

DetailsChart.propTypes = {
  metrics: PropTypes.arrayOf(PropTypes.object),
  statistic: PropTypes.objectOf(PropTypes.any),
  lastEvent: PropTypes.objectOf(PropTypes.any),
};
CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
};

export default DetailsChart;
