import { createAsyncThunk } from '@reduxjs/toolkit';
import { OUTGOING_MAIL } from '@store/slices/resources';
import { getOutboundMailbox } from '@services/requests/outGoingMail';

export const ThunkGetOutgoingMailbox = createAsyncThunk(
  `${OUTGOING_MAIL}/OutGoingMailbox`,
  () => getOutboundMailbox(),
);

export default {};
