import {
  ArrowDownward,
  ArrowUpward,
  Pause,
  PlayArrow,
} from '@material-ui/icons';

import iconOk from '@assets/icons/accept.svg';
import iconPause from '@assets/icons/notify.svg';
import iconDown from '@assets/icons/remove.svg';

export const deviceMonitorsStatuses = [
  { label: 'All', value: 'All' },
  { label: 'Started', value: 'Start' },
  { label: 'Paused', value: 'Pause' },
  { label: 'Up', value: 'Up' },
  { label: 'Down', value: 'Down' },
];

export const deviceMonitorsEvents = {
  Start: {
    icon: PlayArrow,
    name: 'Started',
    enumKey: 'Start',
    color: '#0E96E2',
    statusIcon: iconOk,
    statusColor: '#67AD5B',
  },
  Pause: {
    icon: Pause,
    name: 'Pause',
    enumKey: 'Pause',
    color: '#F0BD3A',
    statusIcon: iconPause,
    statusColor: '#F0BD3A',
  },
  Up: {
    icon: ArrowUpward,
    name: 'Up',
    enumKey: 'Up',
    color: '#67AD5B',
    statusIcon: iconOk,
    statusColor: '#67AD5B',
  },
  Down: {
    icon: ArrowDownward,
    name: 'Down',
    enumKey: 'Down',
    color: '#EC6A4E',
    statusIcon: iconDown,
    statusColor: '#EC6A4E',
  },
};

export const deviceMonitorsHeaders = [
  'Status',
  'Company',
  'Device Name',
  'Device Url',
  'Device Type',
  'Site',
  'Region',
  'Period (min)',
  'Errors',
];

export default {};
