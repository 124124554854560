import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '20px',
    boxSizing: 'border-box',
    '& tbody': {
      maxHeight: '75vh',
    },
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignContent: 'center',
  },
  actions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    padding: '10px 0px',
    boxSizing: 'border-box',
  },
  dialog: {
    width: '650px',
    minHeight: '650px',
    display: props => (props.loading ? 'flex' : 'unset'),
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
