import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  table: {
    margin: '2rem 16px 0',
    '& tbody': {
      maxHeight: '78vh',
    },
  },
}));

export default useStyles;
