import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import {
    Dialog as MuiDialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    RadioGroup,
    Radio,
    FormControlLabel,
} from '@material-ui/core';
import { Form } from 'react-final-form';

import ReusableButton from '@common/Button/Button';
import { getDetailedLogPeriods, setDetailedLogPeriod } from '../helpers';

import useStyles from './styles';

const initialValues = {
    period: 15,
}

const AddDetailedLogsDialog = ({
    appBotId,
    onCloseDialog,
    onAccept,
}) => {
    const classes = useStyles();
    const notify = useNotify();

    const [detailedLogDialogOptions, setDetailedLogDialogOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDetailedLogPeriods()
            .then(data => setDetailedLogDialogOptions(data))
            .catch(() => notify("Unable to fetch options, please, reload the page", 'error'));
    }, []);

    const submit = values => {
        setLoading(true);
        setDetailedLogPeriod(appBotId, values.period)
            .then(() => {
                notify(`Succesfully applied for next ${values.period} minutes`);
                onAccept(appBotId, values.period);
            })
            .catch(() => notify('Unable to set detailed log period', 'error'))
            .finally(() => setLoading(false));
    };

    return (
        <MuiDialog open={!!appBotId} maxWidth="xs">
            <DialogTitle>Set detaild log observability period</DialogTitle>
            <DialogContent>
                <Form
                    onSubmit={submit}
                    initialValues={initialValues}
                    render={({ handleSubmit, form, values }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <div className={classes.displayFlex}>
                                <RadioGroup
                                    row
                                    id="period"
                                    name="period"
                                    fullWidth
                                    onChange={e => form.change('period', +e.target.value)}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        minWidth: '9rem',
                                    }}
                                >
                                    {detailedLogDialogOptions.map(choice => (
                                        <FormControlLabel
                                            key={choice.value}
                                            value={choice.value}
                                            checked={values.period === choice.value}
                                            label={choice.label}
                                            control={<Radio />}
                                        />
                                    ))}
                                </RadioGroup>
                            </div>

                            <DialogActions
                                classes={{
                                    root: classes.dialogActionsRoot,
                                    spacing: classes.spacingWrapper,
                                }}
                            >
                                <ReusableButton
                                    label="resources.buttons.cancel"
                                    onClick={onCloseDialog}
                                    classNameWrapper={classes.confirmBtnContainer}
                                    size="md"
                                    disabled={loading}
                                />

                                <ReusableButton
                                    viewType="black"
                                    autoFocus
                                    type="submit"
                                    classNameWrapper={classes.confirmBtnContainer}
                                    size="md"
                                    disabled={loading}
                                    loading={loading}
                                    label="resources.buttons.confirm"
                                />
                            </DialogActions>
                        </form>
                    )}
                />
            </DialogContent>
        </MuiDialog>
    );
};

AddDetailedLogsDialog.propTypes = {
    appBotId: PropTypes.number,
    onCloseDialog: PropTypes.func,
    onAccept: PropTypes.func.isRequired,
};

export default AddDetailedLogsDialog;