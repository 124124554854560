import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import {
  ThunkGetConnectWiseTicketBoards,
  ThunkGetConnectWiseTicketCompanies,
  ThunkGetConnectWiseTicketDepartments,
  ThunkGetConnectWiseTicketImpacts,
  ThunkGetConnectWiseTicketMembers,
  ThunkGetConnectWiseTicketPriorities,
  ThunkGetConnectWiseTicketSeverities,
  ThunkGetConnectWiseTicketStatuses,
  ThunkGetConnectWiseTicketSystemLocations,
} from '@store/slices/connectWiseTicket/thunks';
import ConnectWiseTicketTableFilters from './ConnectWiseTicketTableFilters';
import ConnectWiseTicketTableActions from './ConnectWiseTicketTableActions';

import useStyles from '../../styles';

const ConnectWiseTicketTable = ({ defaultId, crmId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [options, setOptions] = useState({
    boardOptions: [],
    companyOptions: [],
    locationOptions: [],
    priorityOptions: [],
    departmentOptions: [],
    memberOptions: [],
    severitiesOptions: [],
    impactOptions: [],
    statuses: [],
  });
  const onMount = useCallback(async () => {
    await Promise.all([
      dispatch(ThunkGetConnectWiseTicketBoards(defaultId)).unwrap(),
      dispatch(ThunkGetConnectWiseTicketCompanies(defaultId)).unwrap(),
      dispatch(ThunkGetConnectWiseTicketSystemLocations(defaultId)).unwrap(),
      dispatch(ThunkGetConnectWiseTicketPriorities(defaultId)).unwrap(),
      dispatch(ThunkGetConnectWiseTicketDepartments(defaultId)).unwrap(),
      dispatch(ThunkGetConnectWiseTicketMembers(defaultId)).unwrap(),
      dispatch(ThunkGetConnectWiseTicketSeverities(defaultId)).unwrap(),
      dispatch(ThunkGetConnectWiseTicketImpacts(defaultId)).unwrap(),
    ]).then(res => {
      const responseOptions = {
        boardOptions: res[0],
        companyOptions: res[1],
        locationOptions: res[2],
        priorityOptions: res[3],
        departmentOptions: res[4],
        memberOptions: res[5],
        severitiesOptions: res[6],
        impactOptions: res[7],
      };

      setOptions({ ...responseOptions, statuses: [] });
    });
  }, [dispatch, defaultId]);

  useEffect(() => {
    onMount();
  }, [onMount]);
  const handleTicketIdClick = async ticketLink => {
    window.open(ticketLink, '_blank');
  };

  const columns = useMemo(
    () => [
      {
        name: 'ID',
        key: 'id',
        isCustomAccessor: true,
        sortable: true,
        searchable: true,
        minWidth: 150,
        accessor: item => (
          <div
            className={classes.link}
            onClick={() => {
              handleTicketIdClick(item.ticketLink);
            }}
            role="presentation"
          >
            {item.id}
          </div>
        ),
      },
      {
        name: 'Service Boards',
        key: 'board',
        searchable: true,
        minWidth: 200,
        type: TableTypes.dropdown,
        dropDownValues: options.boardOptions,
        filterByAutocomplete: 'boardId',
        filterCallBack: (value, filtering, setFilteringObject) => {
          if (value) {
            dispatch(
              ThunkGetConnectWiseTicketStatuses({
                crmId: defaultId,
                boardId: value,
              }),
            )
              .unwrap()
              .then(res => {
                setOptions(prevOptions => ({ ...prevOptions, statuses: res }));
              });
          } else {
            setOptions(prevOptions => ({ ...prevOptions, statuses: [] }));
          }
          const { statusId, ...filters } = filtering;
          setFilteringObject(filters, 'boardId', value);
        },
      },
      {
        name: 'Status',
        key: 'status',
        searchable: true,
        minWidth: 200,
        type: TableTypes.dropdown,
        dropDownValues: options.statuses,
        filterByAutocomplete: 'statusId',
      },
      {
        name: 'Company',
        key: 'company',
        searchable: true,
        minWidth: 200,
        type: TableTypes.dropdown,
        dropDownValues: options.companyOptions,
        filterByAutocomplete: 'companyId',
      },
      {
        name: 'Contact',
        key: 'contact',
        sortable: true,
        searchable: true,
        minWidth: 150,
      },
      {
        name: 'Priority',
        key: 'priority',
        searchable: true,
        minWidth: 200,
        type: TableTypes.dropdown,
        dropDownValues: options.priorityOptions,
        filterByAutocomplete: 'priorityId',
      },
      {
        name: 'Location',
        key: 'location',
        searchable: true,
        minWidth: 200,
        type: TableTypes.dropdown,
        dropDownValues: options.locationOptions,
        filterByAutocomplete: 'locationId',
      },
      {
        name: 'Department',
        key: 'department',
        searchable: true,
        minWidth: 200,
        type: TableTypes.dropdown,
        dropDownValues: options.departmentOptions,
        filterByAutocomplete: 'departmentId',
      },
      {
        name: 'Owner',
        key: 'owner',
        searchable: true,
        minWidth: 200,
        type: TableTypes.dropdown,
        dropDownValues: options.memberOptions,
        filterByAutocomplete: 'ownerId',
      },
      {
        name: 'Resources',
        key: 'resources',
        sortable: true,
        searchable: true,
        minWidth: 150,
      },
      {
        name: 'Severity',
        key: 'severity',
        sortable: true,
        searchable: true,
        minWidth: 200,
        type: TableTypes.dropdown,
        dropDownValues: options.severitiesOptions,
        filterByAutocomplete: 'severity',
      },
      {
        name: 'Impact',
        key: 'impact',
        sortable: true,
        searchable: true,
        minWidth: 200,
        type: TableTypes.dropdown,
        dropDownValues: options.impactOptions,
        filterByAutocomplete: 'impact',
      },
      {
        name: 'Summary',
        key: 'summary',
        sortable: true,
        searchable: true,
        minWidth: 150,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        minWidth: 165,
        width: 165,
        accessor: item => (
          <ConnectWiseTicketTableActions
            record={item}
            crmDefaultId={defaultId}
            crmId={crmId}
          />
        ),
      },
    ],
    [classes, crmId, defaultId, dispatch, options],
  );

  return (
    <div>
      <Table
        columns={columns}
        actionsComponent={<ConnectWiseTicketTableFilters />}
        defaultSort={{ fieldName: 'id', order: 'DESC' }}
        resource={`TicketUpdate/connectWise/${defaultId}/tickets`}
      />
    </div>
  );
};

ConnectWiseTicketTable.propTypes = {
  defaultId: PropTypes.number.isRequired,
  crmId: PropTypes.number,
};

export default ConnectWiseTicketTable;
