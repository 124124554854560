import { createAsyncThunk } from '@reduxjs/toolkit';
import { OUTGOING_MAIL } from '@store/slices/resources';
import { deleteOutboundMailbox } from '@services/requests/outGoingMail';

export const ThunkDeleteOutgoingMailbox = createAsyncThunk(
  `${OUTGOING_MAIL}/deleteOutGoingMailbox`,
  async () => deleteOutboundMailbox(),
);

export default {};
