import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tableWrapper: {
    margin: '4rem 16px 0 11px',
    '& tbody': {
      maxHeight: '70vh',
    },
  },
  buttonWrapper: {
    marginBottom: '8px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  monoSpace: {
    fontFamily: 'monospace',
  },
  textCenter: {
    textAlign: 'center',
  },
  title: {
    fontSize: '1.5rem',
    marginBottom: '0.25rem',
  },
  marginTop: {
    marginTop: '2rem',
  },
}));

export default useStyles;
