import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { ReactComponent as EventsIcon } from '@assets/icons/events.svg';
import { useSelector } from 'react-redux';
import { listSelector } from '@store/selectors';

import useStyles from './styles';

const BagelChart = memo(
  ({
    count = 0,
    allCount,
    title,
    color,
    textColor,
    circleColor,
    useLineSeparator = true,
  }) => {
    const canvasRef = useRef();
    const classes = useStyles({ textColor, useLineSeparator });
    const list = useSelector(listSelector);

    useEffect(() => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      const posX = canvas.width / 2;
      const posY = canvas.height / 2;
      const fps = 1000 / 200;
      const oneProcent = ((360 / allCount) * count) / 100;
      const result = oneProcent * 100;

      ctx.lineCap = 'round';

      let acrInterval;

      (() => {
        let degrees = 0;
        acrInterval = setInterval(() => {
          degrees += 1;
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.arc(
            posX,
            posY,
            50,
            (Math.PI / 180) * 270,
            (Math.PI / 180) * (270 + 360),
          );
          ctx.strokeStyle = circleColor || '#848788';
          ctx.lineWidth = '10';
          ctx.stroke();
          if (count) {
            ctx.beginPath();
            ctx.strokeStyle = color;
            ctx.lineWidth = '10';
            ctx.arc(
              posX,
              posY,
              50,
              (Math.PI / 180) * 270,
              (Math.PI / 180) * (270 + degrees),
            );
            ctx.stroke();
          }
          if (degrees >= result) clearInterval(acrInterval);
        }, fps);
      })();
      return () => clearInterval(acrInterval);
    }, [list, count, allCount, color, circleColor]);

    return (
      <div>
        <Typography variant="subtitle1" className={classes.bagelChartTitle}>
          <>
            <EventsIcon width={20} height={20} fill={color} />
            <span>{title}</span>
          </>
        </Typography>
        <div role="presentation" className={classes.canvasContainer}>
          <canvas ref={canvasRef} />
          <div className={classes.bagelChartCount}>{count}</div>
        </div>
      </div>
    );
  },
);

BagelChart.propTypes = {
  count: PropTypes.number,
  allCount: PropTypes.number,
  title: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  circleColor: PropTypes.string,
  useLineSeparator: PropTypes.bool,
};

export default BagelChart;
