import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { enqueueSnackbar } from 'notistack';

import CustomCheckbox from '@common/Checkbox/Checkbox';
import FormElement from '@common/form/FormElement';
import ReusableButton from '@common/Button/Button';
import { listSelector } from '@store/selectors/index';
import useStyles from '../Actions/ignoreModelStyles';
import ChipForBulkIgnore from './ChipForBulkIgnore';
import { addBulkIgnoreRules } from '../../../helpers';
import EditContext from './Context';

const BulkIgnoreModal = ({ open, close, onSubmit, selectedIds, mailBoxId }) => {
  const classes = useStyles();
  const [emails, setEmails] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const list = useSelector(listSelector);

  useEffect(() => {
    const sendersArray = [];
    const subjectsArray = [];

    list.forEach(item => {
      if (selectedIds.indexOf(item.id) !== -1) {
        sendersArray.push({ value: item.mailFrom });
        subjectsArray.push({ value: item.subject });
      }
    });
    setEmails(_.uniqBy(sendersArray, 'value'));
    setSubjects(_.uniqBy(subjectsArray, 'value'));
  }, [open, selectedIds, list]);

  const handleSubmitForm = ({ ignoreSenders, ignoreSubjects }) => {
    const payload = {
      mailBoxId,
      ignoreSenders,
      ignoreSubjects,
      emails: emails.map(v => v.value),
      subjects: subjects.map(v => v.value),
    };
    setLoading(true);

    addBulkIgnoreRules(payload)
      .then(() =>
        enqueueSnackbar('Rules successfully updated', { variant: 'success' }),
      )
      .catch(error => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => {
        setLoading(false);
        close();
        onSubmit();
      });
  };

  const initData = {
    ignoreSenders: true,
    ignoreSubjects: true,
  };

  return (
    <>
      <Dialog open={open} onClose={close} aria-labelledby="ignore-modal-title">
        <DialogTitle id="simple-dialog-title">Add ignore rules</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={handleSubmitForm}
            initialValues={initData}
            render={({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                className={classes.formWrapper}
              >
                <FormElement label="Ignore selected senders">
                  <Field
                    type="checkbox"
                    id="ignoreSenders"
                    name="ignoreSenders"
                    component={CustomCheckbox}
                    disabled={loading}
                  />
                </FormElement>
                <EditContext.Provider value={false}>
                  <ChipForBulkIgnore
                    chipData={emails}
                    setChipData={setEmails}
                    disabled={loading}
                  />
                </EditContext.Provider>
                <FormElement label="Ignore selected subjects">
                  <Field
                    type="checkbox"
                    id="ignoreSubjects"
                    name="ignoreSubjects"
                    component={CustomCheckbox}
                    disabled={loading}
                  />
                </FormElement>
                <EditContext.Provider value>
                  <ChipForBulkIgnore
                    chipData={subjects}
                    setChipData={setSubjects}
                    disabled={loading}
                  />
                </EditContext.Provider>
                <div className={classes.buttonWrapper}>
                  <ReusableButton
                    onClick={close}
                    label="resources.buttons.cancel"
                    classNameWrapper={classes.button}
                    disabled={loading}
                  />
                  <ReusableButton
                    viewType="black"
                    type="submit"
                    classNameWrapper={classes.button}
                    disabled={loading}
                    loading={loading}
                    label="resources.buttons.submit"
                  />
                </div>
              </form>
            )}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

BulkIgnoreModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  mailBoxId: PropTypes.number.isRequired,
};

export default BulkIgnoreModal;
