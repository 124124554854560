import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  buttons: {
    gap: '10px',
    display: 'grid',
    justifyContent: 'space-between',
    gridTemplateColumns: '1fr 1fr',
    margin: '10px 0px',
  },
  activeContainer: {
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > div': {
      width: '70%',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },

    '@media (max-width: 1100px)': {
      padding: '0 40px',
      '& > div': {
        width: '100%',
        margin: '0 auto',
        padding: '10px',
        boxSizing: 'border-box',
      },
    },
    '@media (max-width: 600px)': {
      padding: '0 10px',
    },
  },
}));

export default useStyles;
