import React from 'react';

import getFormattedDate from '@utils/getFormattedDate';

import BooleanAccessor from '@common/MuiVirtualizedTable/components/accessors/BooleanAccessor';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';

import RefreshIcon from '@assets/icons/refresh_circle.svg';
import DeleteIcon from '@assets/icons/delete.svg';
import { TableTypes } from '@components/Table/constants';
import booleanOptions from '@utils/constants/options';

const columns = [
  {
    name: 'Email',
    key: 'email',
  },
  {
    name: 'Invite Date',
    key: 'inviteDateTime',
    isCustomAccessor: true,
    accessor: rowData => <>{getFormattedDate(rowData.inviteDateTime)}</>,
  },
  {
    name: 'Is Registered',
    key: 'isRegistered',
    searchable: true,
    sortable: true,
    filterByAutocomplete: 'isRegistered',
    type: TableTypes.dropdown,
    dropDownValues: booleanOptions,
    isCustomAccessor: true,
    accessor: rowData => (
      <BooleanAccessor value={rowData.isRegistered} useMultiColor />
    ),
  },
  {
    name: 'Registration Date',
    key: 'registrationDateTime',
    isCustomAccessor: true,
    accessor: rowData => <>{getFormattedDate(rowData.registrationDateTime)}</>,
  },
];

export const getColumns = (
  handleReInviteDialogOpen,
  handleDeleteDialogOpen,
  allowEdit,
) =>
  allowEdit
    ? columns.concat([
        {
          name: 'Actions',
          key: TableTypes.actions,
          type: TableTypes.actions,
          isCustomAccessor: true,
          width: 100,
          accessor: rowData => (
            <>
              <ActionFieldItem
                icon={RefreshIcon}
                toolTip={`Re-invite User${
                  rowData.isRegistered
                    ? '. This feature is not available because the user has already accepted the invitation.'
                    : ''
                }`}
                handler={() => {
                  handleReInviteDialogOpen(rowData.email);
                }}
                disabled={rowData.isRegistered}
              />
              <ActionFieldItem
                icon={DeleteIcon}
                toolTip="Delete invite"
                handler={() => {
                  handleDeleteDialogOpen(rowData.id);
                }}
              />
            </>
          ),
        },
      ])
    : columns;

export default {};
