/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { VERIFICATION_SETTINGS } from '@store/slices/resources';
import {
  ThunkGetCompaniesVerificationSettings,
  ThunkGetVerificationPhone,
} from '@store/slices/verificationSettings/thunks';

const initialState = {
  companiesVerificationSettings: [],
  verificationPhone: '',
  loading: false,
};

const verificationSettingsSlice = createSlice({
  name: VERIFICATION_SETTINGS,
  initialState,
  reducers: {
    setVerificationPhone: (state, { payload }) => {
      state.verificationPhone = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      ThunkGetCompaniesVerificationSettings.fulfilled,
      (state, action) => {
        state.companiesVerificationSettings = action.payload;
      },
    );
    builder.addCase(ThunkGetVerificationPhone.pending, state => {
      state.loading = true;
    });
    builder.addCase(ThunkGetVerificationPhone.fulfilled, (state, action) => {
      state.verificationPhone = action.payload.data;
      state.loading = false;
    });
    builder.addCase(ThunkGetVerificationPhone.rejected, state => {
      state.loading = false;
    });
  },
});

export const { setVerificationPhone } = verificationSettingsSlice.actions;

export default verificationSettingsSlice.reducer;

export const verificationSettingsSelector = state =>
  state[VERIFICATION_SETTINGS];
