import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '16px 16px 0',
    '& tbody': {
      maxHeight: '77vh',
    },
  },
  center: {
    width: '100%',
    textAlign: 'center',
  },
  statusField: {
    color: 'white',
    margin: '0px',
    padding: '5px 15px',
    borderRadius: '5px',
  },
  bulkActions: {
    '& > *': {
      marginRight: '10px',
    },
  },
  fixButton: {
    '& > * > *': {
      marginRight: '5px',
    },
  },
}));

export default useStyles;
