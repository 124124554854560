import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { PSA_CONTACTS } from '@services/requests/requestResources';
import { ThunkGetConnectWiseIntegrationCompanies } from '@store/slices/integration/thunks';

import { getConnectWiseTableColumns } from '@components/PsaContacts/constants';

import Table from '@components/Table';
import PsaContactsTableBulkActions from '@components/PsaContacts/components/PsaContactsTableBulkActions';

import crmSources from '@constants/crmSources';

const ConnectwiseContacts = ({ psaId }) => {
  const [companies, setCompanies] = useState([]);

  const dispatch = useDispatch();

  const resource = useMemo(() => `${PSA_CONTACTS}/connectwise/${psaId}`, [
    psaId,
  ]);

  const onMount = useCallback(() => {
    dispatch(ThunkGetConnectWiseIntegrationCompanies({ crmId: psaId }))
      .unwrap()
      .then(res => {
        setCompanies(res);
      });
  }, [dispatch, psaId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Table
      resource={resource}
      columns={getConnectWiseTableColumns(psaId, companies)}
      bulkActions={
        <PsaContactsTableBulkActions
          psaId={psaId}
          psaName={crmSources.ConnectWise.name}
        />
      }
    />
  );
};

ConnectwiseContacts.propTypes = {
  psaId: PropTypes.number.isRequired,
};

export default ConnectwiseContacts;
