import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  ThunkGetAutotaskTicketCompanies,
  ThunkGetAutotaskTicketContacts,
  ThunkGetAutotaskTicketOptions,
  ThunkGetAutotaskTicketResources,
  ThunkGetTicketLink,
} from '@store/slices/crmTicket/thunks';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import AutotaskTicketTableFilters from './AutotaskTicketTableFilters';
import AutotaskTicketTableActions from './AutotaskTicketTableActions';

import useStyles from '../../styles';

const AutotaskTicketTable = ({ defaultId, crmId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleTicketIdClick = ({ ticketNumber: ticketId }) => {
    dispatch(ThunkGetTicketLink({ ticketId, crmId }))
      .unwrap()
      .then(res => {
        window.open(res.data, '_blank');
      });
  };

  const [options, setOptions] = useState({
    ticketType: [],
    issueType: [],
    queueID: [],
    priority: [],
    source: [],
    status: [],
    ticketCategory: [],
    company: [],
    resources: [],
    contacts: [],
  });

  const onMount = useCallback(() => {
    Promise.all([
      dispatch(
        ThunkGetAutotaskTicketOptions({
          crmId: defaultId,
          options: [
            'ticketType',
            'issueType',
            'queueID',
            'priority',
            'source',
            'status',
            'ticketCategory',
          ],
        }),
      ).unwrap(),
      dispatch(ThunkGetAutotaskTicketCompanies(defaultId)).unwrap(),
      dispatch(ThunkGetAutotaskTicketResources({ crmId: defaultId })).unwrap(),
      dispatch(ThunkGetAutotaskTicketContacts({ crmId: defaultId })).unwrap(),
    ]).then(res => {
      const responseOptions = {
        ...res[0],
        company: res[1],
        resources: res[2],
        contacts: res[3],
      };

      setOptions(responseOptions);
    });
  }, [dispatch, defaultId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const autotaskColumns = useMemo(
    () => [
      {
        name: 'ID',
        key: 'ticketNumber',
        isCustomAccessor: true,
        sortable: true,
        searchable: true,
        minWidth: 200,
        accessor: item => (
          <div
            className={classes.link}
            onClick={() => {
              handleTicketIdClick(item);
            }}
            role="presentation"
          >
            {item.ticketNumber}
          </div>
        ),
      },
      {
        name: 'Company',
        key: 'company',
        searchable: true,
        minWidth: 200,
        type: TableTypes.dropdown,
        dropDownValues: options.company,
        filterByAutocomplete: 'companyId',
      },
      {
        name: 'Queue',
        key: 'board',
        searchable: true,
        minWidth: 150,
        type: TableTypes.dropdown,
        dropDownValues: options.queueID,
        filterByAutocomplete: 'queueId',
      },
      {
        name: 'Issue Type',
        key: 'issueType',
        searchable: true,
        minWidth: 175,
        type: TableTypes.dropdown,
        dropDownValues: options.issueType,
        filterByAutocomplete: 'issueTypeId',
      },
      {
        name: 'Priority',
        key: 'priority',
        searchable: true,
        minWidth: 150,
        type: TableTypes.dropdown,
        dropDownValues: options.priority,
        filterByAutocomplete: 'priorityId',
      },
      {
        name: 'Source',
        key: 'source',
        minWidth: 150,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: options.source,
        filterByAutocomplete: 'sourceId',
      },
      {
        name: 'Status',
        key: 'status',
        minWidth: 150,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: options.status,
        filterByAutocomplete: 'statusId',
      },
      {
        name: 'Ticket Category',
        key: 'ticketCategory',
        minWidth: 200,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: options.ticketCategory,
        filterByAutocomplete: 'ticketCategoryId',
      },
      {
        name: 'Ticket Type',
        key: 'ticketType',
        minWidth: 175,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: options.ticketType,
        filterByAutocomplete: 'ticketTypeId',
      },
      {
        name: 'Title',
        key: 'title',
        minWidth: 200,
        sortable: true,
        searchable: true,
      },
      {
        name: 'Resource',
        key: 'assignedResource',
        minWidth: 150,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: options.resources,
        filterByAutocomplete: 'assignedResourceId',
      },
      {
        name: 'Contact',
        key: 'contact',
        minWidth: 150,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: options.contacts,
        filterByAutocomplete: 'contactId',
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        minWidth: 175,
        width: 175,
        accessor: item => (
          <AutotaskTicketTableActions
            record={item}
            crmDefaultId={defaultId}
            crmId={crmId}
          />
        ),
      },
    ],
    [classes, crmId, defaultId, handleTicketIdClick, options],
  );

  return (
    <div className={classes.table}>
      <Table
        actionsComponent={<AutotaskTicketTableFilters />}
        columns={autotaskColumns}
        resource={`TicketUpdate/autotask/${defaultId}/tickets`}
        defaultSort={{ fieldName: 'ticketNumber', order: 'DESC' }}
        defaultFilters={{ skipCompleted: true }}
      />
    </div>
  );
};

AutotaskTicketTable.propTypes = {
  defaultId: PropTypes.number.isRequired,
  crmId: PropTypes.number,
};
export default AutotaskTicketTable;
