import { createAsyncThunk } from '@reduxjs/toolkit';
import { helpdeskToggleState } from '@services/requests/helpdeskAI';

export const ThunkHelpdeskToggleState = createAsyncThunk(
  'helpdeskAI/toggleState',
  async ({ id, state }) =>
    helpdeskToggleState(id, state).then(res =>
      res.status === 200 ? { id, state } : null,
    ),
);

export default {};
