import React from 'react';
import PropTypes from 'prop-types';

import DateTimeField from '@common/TableComponents/DateTimeField';
import Table from '@components/Table';
import webHookStatuses from '@constants/webHookStatuses';
import { TableTypes } from '@components/Table/constants';
import BulkActions from './components/BulkActions';
import ActionsField from './components/ActionsFields';

import useStyles from './styles';

const WebHookResults = ({ match }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Table
        defaultSort={{ fieldName: 'date', order: 'DESC' }}
        bulkActions={<BulkActions />}
        columns={[
          {
            name: 'ID',
            key: 'id',
            sortable: true,
            searchable: true,
            minWidth: 100,
          },
          {
            name: 'Date',
            key: 'date',
            sortable: true,
            isCustomAccessor: true,
            accessor: rowData => (
              <DateTimeField record={rowData} key="date" source="date" />
            ),
            minWidth: 200,
          },
          {
            sortable: true,
            searchable: true,
            type: TableTypes.dropdown,
            dropDownValues: webHookStatuses,
            filterByAutocomplete: 'status',
            name: 'Status',
            key: 'status',
            minWidth: 200,
            width: 250,
            isCustomAccessor: true,
            accessor: rowData => (
              <div className={classes.center}>
                <span
                  style={{
                    backgroundColor: webHookStatuses[rowData.status]?.hex,
                  }}
                  className={classes.statusField}
                >
                  {webHookStatuses[rowData.status]?.name}
                </span>
              </div>
            ),
          },
          {
            name: 'Error',
            key: 'errorMessage',
            sortable: true,
            searchable: true,
            minWidth: 150,
          },
          {
            name: 'Actions',
            key: TableTypes.actions,
            type: TableTypes.actions,
            minWidth: 100,
            width: 100,
            accessor: rowData => (
              <ActionsField webHook={rowData} webHookId={match.params.id} />
            ),
          },
        ]}
        resource={`webHooks/${match.params.id}/results`}
      />
    </div>
  );
};

WebHookResults.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

export default WebHookResults;
