import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from '@common/form/FieldWrapper';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconPlus from '@assets/icons/plusGrey.svg';
import { Field } from 'react-final-form';
import Input from '@components/Auth/Common/Input';
import NumberInput from '@components/Auth/Common/NumberInput';
import Checkbox from '@common/Checkbox/Checkbox';
import {
  accordionStyle,
  contentSize,
  emailMaxLength,
  labelSize,
  smsMaxLength,
} from '@components/Settings/VerificationSettings/components/constants';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import {
  ThunkGetVerificationSettingsVariables,
  ThunkGetVerificationExpiredTicketNoteVariables,
} from '@store/slices/verificationSettings/thunks';
import { ThunkGetVerificationSettingsEmailSubjectVariables } from '@store/slices/verificationSettings/thunks/getVerificationSettingsEmailSubjectVariables';

import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import InfoBox from '@common/InfoBox/InfoBox';
import MinutesPeriodPicker from '@common/form/DateTime/MinutesPeriodPicker';

import useStyles from '../styles';

const ValidationCodeSettings = ({ processing, form, values }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [smsAnchorEl, setSmsAnchorEl] = useState(null);
  const [secureLinkAnchorEl, setSecureLinkAnchorEl] = useState(null);
  const [emailAnchorEl, setEmailAnchorEl] = useState(null);
  const [emailSubjectAnchorEl, setEmailSubjectAnchorEl] = useState(null);
  const [templateVariables, setTemplateVariables] = useState([]);
  const [emailSubjectVariables, setEmailSubjectVariables] = useState([]);
  const [ticketNoteAnchorEl, setTicketNoteAnchorEl] = useState(null);
  const [ticketNoteVariables, setTicketNoteVariables] = useState([]);

  const onMount = useCallback(async () => {
    Promise.all([
      new Promise(res =>
        res(dispatch(ThunkGetVerificationSettingsVariables()).unwrap()),
      ),
      new Promise(res =>
        res(
          dispatch(
            ThunkGetVerificationSettingsEmailSubjectVariables(),
          ).unwrap(),
        ),
      ),
      new Promise(res =>
        res(
          dispatch(ThunkGetVerificationExpiredTicketNoteVariables()).unwrap(),
        ),
      ),
    ]).then(res => {
      setTemplateVariables(res[0]);
      setEmailSubjectVariables(res[1]);
      setTicketNoteVariables(res[2]);
    });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          End-user verification settings
          <InfoBox text="Allows to configure messages and verification code lifetime" />
        </AccordionSummary>
        <AccordionDetails style={accordionStyle}>
          <div className={classes.formItem}>
            <div>
              Do not forget to use variable to paste verification code to the
              message!
            </div>
            <div>Left field blank to use default</div>
          </div>

          <FieldWrapper
            label="SMS template"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            classNameContainer={classes.formItem}
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            content={
              <div className={classes.dialogTemplate}>
                <ActionButton
                  handler={event => setSmsAnchorEl(event.currentTarget)}
                  icon={<img src={iconPlus} alt="iconPlus" />}
                  toolTip="Add template variable"
                  disabled={processing}
                />
                <Field
                  name="smsBody"
                  id="smsBody"
                  render={Input}
                  multiline
                  minRows={3}
                  maxRows={5}
                  dateProps={{ maxLength: smsMaxLength }}
                  helperText={`${
                    values.smsBody ? values.smsBody.length : 0
                  }/${smsMaxLength}`}
                  disabled={processing}
                />
              </div>
            }
          />

          <FieldWrapper
            label="Secure Link SMS template"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            classNameContainer={classes.formItem}
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            content={
              <div className={classes.dialogTemplate}>
                <ActionButton
                  handler={event => setSecureLinkAnchorEl(event.currentTarget)}
                  icon={<img src={iconPlus} alt="iconPlus" />}
                  toolTip="Add template variable"
                  disabled={processing}
                />
                <Field
                  name="secureLinkBody"
                  id="secureLinkBody"
                  render={Input}
                  multiline
                  minRows={3}
                  maxRows={5}
                  dateProps={{ maxLength: smsMaxLength }}
                  helperText={`${
                    values.secureLinkBody ? values.secureLinkBody.length : 0
                  }/${smsMaxLength}`}
                  disabled={processing}
                />
              </div>
            }
          />
          <FieldWrapper
            label="Email Subject"
            labelSize={labelSize}
            contentSize={contentSize}
            showLabel
            classNameContainer={classes.formItem}
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            content={
              <div className={classes.dialogTemplate}>
                <ActionButton
                  handler={event =>
                    setEmailSubjectAnchorEl(event.currentTarget)
                  }
                  icon={<img src={iconPlus} alt="iconPlus" />}
                  toolTip="Add template variable"
                  disabled={processing}
                />
                <Field
                  name="emailSubject"
                  id="emailSubject"
                  render={Input}
                  multiline
                  minRows={3}
                  maxRows={5}
                  dateProps={{ maxLength: emailMaxLength }}
                  helperText={`${
                    values.emailSubject ? values.emailSubject.length : 0
                  }/${emailMaxLength}`}
                  disabled={processing}
                />
              </div>
            }
          />
          <FieldWrapper
            label="Email message template"
            labelSize={labelSize}
            contentSize={contentSize}
            showLabel
            classNameContainer={classes.formItem}
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            content={
              <div className={classes.dialogTemplate}>
                <ActionButton
                  handler={event => setEmailAnchorEl(event.currentTarget)}
                  icon={<img src={iconPlus} alt="iconPlus" />}
                  toolTip="Add template variable"
                  disabled={processing}
                />
                <Field
                  name="emailBody"
                  id="emailBody"
                  render={Input}
                  multiline
                  minRows={3}
                  maxRows={5}
                  dateProps={{ maxLength: emailMaxLength }}
                  helperText={`${
                    values.emailBody ? values.emailBody.length : 0
                  }/${emailMaxLength}`}
                  disabled={processing}
                />
              </div>
            }
          />

          <FieldWrapper
            label="Email secure link template"
            labelSize={labelSize}
            contentSize={contentSize}
            showLabel
            classNameContainer={classes.formItem}
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            content={
              <div className={classes.dialogTemplate}>
                <ActionButton
                  handler={event => setEmailAnchorEl(event.currentTarget)}
                  icon={<img src={iconPlus} alt="iconPlus" />}
                  toolTip="Add template variable"
                  disabled={processing}
                />
                <Field
                  name="emailLinkBody"
                  id="emailLinkBody"
                  render={Input}
                  multiline
                  minRows={3}
                  maxRows={5}
                  dateProps={{ maxLength: emailMaxLength }}
                  helperText={`${
                    values.emailLinkBody ? values.emailLinkBody.length : 0
                  }/${emailMaxLength}`}
                  disabled={processing}
                />
              </div>
            }
          />

          <FieldWrapper
            label="Verification code expiration time"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            isRequired
            content={
              <div className={classes.dialogTemplate}>
                <Field
                  name="durationMin"
                  id="durationMin"
                  render={NumberInput}
                  disabled={processing}
                  min={0}
                  max={10}
                />
                <span>[min.]</span>
              </div>
            }
          />

          <FieldWrapper
            label="Hide verification code on Pod"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <Field
                name="hideVerificationCode"
                id="hideVerificationCode"
                render={Checkbox}
                type="checkbox"
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Repeat code through Voice Call"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <Field
                name="repeatCodeByVoice"
                id="repeatCodeByVoice"
                render={Checkbox}
                type="checkbox"
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Avoid Anti Spam Rejection"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <Field
                name="avoidAntiSpamRejection"
                id="avoidAntiSpamRejection"
                render={Checkbox}
                type="checkbox"
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Add ticket note if code expired"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <Field
                name="addTicketNoteIfVerificationExpired"
                id="addTicketNoteIfVerificationExpired"
                render={Checkbox}
                type="checkbox"
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Ticket note text"
            labelSize={labelSize}
            contentSize={contentSize}
            showLabel
            classNameContainer={classes.formItem}
            classNameLabelContainer={classes.dialogLabelContainer}
            classNameLabelInner={classes.dialogLabelText}
            content={
              <div className={classes.dialogTemplate}>
                <ActionButton
                  handler={event => setTicketNoteAnchorEl(event.currentTarget)}
                  icon={<img src={iconPlus} alt="iconPlus" />}
                  toolTip="Add template variable"
                  disabled={processing}
                />
                <Field
                  name="ticketNoteIfVerificationExpired"
                  id="ticketNoteIfVerificationExpired"
                  render={Input}
                  multiline
                  minRows={3}
                  maxRows={5}
                  dateProps={{ maxLength: emailMaxLength }}
                  helperText={`${
                    values.ticketNoteIfVerificationExpired
                      ? values.ticketNoteIfVerificationExpired.length
                      : 0
                  }/${emailMaxLength}`}
                  disabled={processing}
                />
              </div>
            }
          />
          <FieldWrapper
            label="Reset validation result"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <Field
                name="resetVerification"
                id="resetVerification"
                render={Checkbox}
                type="checkbox"
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Verification expires in"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            infoText="Verification of the contact will ends in defined period"
            content={
              <Field
                name="resetVerificationInMin"
                id="resetVerificationInMin"
                render={MinutesPeriodPicker}
                disabled={processing || !values.resetVerification}
              />
            }
          />

          <FieldWrapper
            label="Require Note if Phone Number changed"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <Field
                name="requireNoteIfPhoneChanged"
                id="requireNoteIfPhoneChanged"
                render={Checkbox}
                type="checkbox"
                disabled={processing}
              />
            }
          />

          <FieldWrapper
            label="Send Note via SMS when Phone Number changed"
            labelSize={labelSize}
            contentSize={contentSize}
            fullWidth
            content={
              <Field
                name="sendNoteAsSmsOnContactNumberChange"
                id="sendNoteAsSmsOnContactNumberChange"
                render={Checkbox}
                type="checkbox"
                disabled={processing}
              />
            }
          />
        </AccordionDetails>
      </Accordion>
      <TemplateMenu
        anchorEl={smsAnchorEl}
        setAnchorEl={setSmsAnchorEl}
        form={form}
        name="smsBody"
        templateVariables={templateVariables}
      />
      <TemplateMenu
        anchorEl={secureLinkAnchorEl}
        setAnchorEl={setSecureLinkAnchorEl}
        form={form}
        name="secureLinkBody"
        templateVariables={templateVariables}
      />
      <TemplateMenu
        anchorEl={emailSubjectAnchorEl}
        setAnchorEl={setEmailSubjectAnchorEl}
        form={form}
        name="emailSubject"
        templateVariables={emailSubjectVariables}
      />
      <TemplateMenu
        anchorEl={emailAnchorEl}
        setAnchorEl={setEmailAnchorEl}
        form={form}
        name="emailBody"
        templateVariables={templateVariables}
      />
      <TemplateMenu
        anchorEl={ticketNoteAnchorEl}
        setAnchorEl={setTicketNoteAnchorEl}
        form={form}
        name="ticketNoteIfVerificationExpired"
        templateVariables={ticketNoteVariables}
      />
    </>
  );
};

ValidationCodeSettings.propTypes = {
  processing: PropTypes.bool,
  form: PropTypes.objectOf(PropTypes.any),
  values: PropTypes.objectOf(PropTypes.any),
};

export default ValidationCodeSettings;
