import React from 'react';
import {
  Error,
  useAuthenticated,
  useAuthProvider,
  useGetOne,
  useTranslate,
} from 'react-admin';
import { Field, Form } from 'react-final-form';
import { Box } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import { confirmPasswordValidator, passwordValidator } from '@utils/validators';
import resources from '@constants/resources';
import Loading from '@common/Loading/Loading';
import BoxWrapper from '@common/BoxWrapper';
import Paper from '@common/Paper/Paper';
import FieldWrapper from '@common/form/FieldWrapper';
import useStyles from './styles';
import Input from '../common/Input/Input';
import ReusableButton from '../common/Button/Button';
import { TEXT_BASE_ERROR } from '@constants/texts/common';

const ChangePassword = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const authProvider = useAuthProvider();

  const history = useHistory();

  const id = authProvider.getUserId();

  useAuthenticated();

  const { loading, error } = useGetOne(resources.user, id);

  if (loading)
    return (
      <BoxWrapper>
        <Loading />
      </BoxWrapper>
    );
  if (error)
    return (
      <BoxWrapper>
        <Error error={error} />
      </BoxWrapper>
    );

  const submit = ({ newPassword, oldPassword }) => {
    const requestData = {
      oldPassword,
      newPassword,
    };

    authProvider
      .changePassword(requestData)
      .then(() => {
        enqueueSnackbar('Password has been changed', { variant: 'success' });
        history.goBack();
      })
      .catch(() => enqueueSnackbar(TEXT_BASE_ERROR, { variant: 'error' }));
  };
  const validate = values => {
    const errors = {};

    const pwdErrors = passwordValidator(values.newPassword);

    errors.oldPassword = !values.oldPassword
      ? 'validation.password.required'
      : undefined;

    errors.newPassword = pwdErrors || undefined;

    const pwdConfErrors = confirmPasswordValidator(
      values.confirmNewPassword,
      values,
      'newPassword',
    );
    errors.confirmNewPassword = pwdConfErrors || undefined;

    return errors;
  };

  return (
    <Paper
      classNameBlock={classes.block}
      classNameContainer={classes.containerPaper}
    >
      <Form
        onSubmit={submit}
        validate={validate}
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <div className={classes.inputContainer}>
              <FieldWrapper
                label="Old Password:"
                contentSize={12}
                classNameGridContainer={classes.contentWrapper}
                classNameLabelOuter={classes.fieldLabelOuter}
                spacing={0}
                content={
                  <Field
                    autoFocus
                    id="oldPassword"
                    name="oldPassword"
                    inputView="text"
                    styleType="main"
                    type="password"
                    component={Input}
                    fullWidth
                    placeholder="Old Password"
                  />
                }
              />
            </div>
            <div className={classes.inputContainer}>
              <FieldWrapper
                label="New Password:"
                contentSize={12}
                classNameLabelOuter={classes.fieldLabelOuter}
                classNameGridContainer={classes.contentWrapper}
                spacing={0}
                content={
                  <Field
                    autoFocus
                    id="newPassword"
                    name="newPassword"
                    inputView="text"
                    styleType="main"
                    type="password"
                    component={Input}
                    fullWidth
                    placeholder="New Password"
                  />
                }
              />
            </div>
            <div className={classes.inputContainer}>
              <FieldWrapper
                label="Confirm New Password:"
                contentSize={12}
                classNameLabelOuter={classes.fieldLabelOuter}
                classNameGridContainer={classes.contentWrapper}
                spacing={0}
                content={
                  <Field
                    id="confirmNewPassword"
                    name="confirmNewPassword"
                    inputView="text"
                    styleType="main"
                    fullWidth
                    type="password"
                    component={Input}
                    placeholder="Confirm New Password"
                    disabled={loading}
                  />
                }
              />
            </div>
            <Box className={classes.buttonWrapper}>
              <ReusableButton
                size="lg"
                viewType="black"
                type="submit"
                classNameWrapper={classes.button}
                fullWidth
                disabled={loading}
                loading={loading}
                label="resources.buttons.submit"
              />
            </Box>
            <Box className={classes.buttonWrapper}>
              <Link to="/email-handler?mode=forgotPassword">
                {translate('resources.buttons.forgot')}
                <>?</>
              </Link>
            </Box>
          </form>
        )}
      />
    </Paper>
  );
};

export default ChangePassword;
