export const INFO_IMAGE_TEXT =
  'The accepted file formats are PNG or JPEG. Your image will be automatically resized for the best fit.';

export const MAX_SMS_VALUE_COUNT = 1224;

export const optionsTypeName = {
  ContactVerificationTypes: 'ContactVerificationTypes',
};

export const DEFAULT_DIALOG_DATA = { isOpen: false };
export default {};
