/* eslint-disable react/jsx-indent */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useRefresh, useVersion } from 'react-admin';
import useAsync from '@services/api/common/useAsync';
import MuiVirtualizedTable from '@components/common/MuiVirtualizedTable';
import BulkActionButtons from '@common/BulkActionButtons';
import { deletePolicyUri } from '@constants/appRoutes';
import { customTypeIdMeasure } from '@constants/numericConstants';
import { sortOrders } from '@constants/filters';
import TableLabel from './TableLabel';
import { getHintUsers, getTypeValues } from './helpers';
import AggergatedData from './AggergatedData';
import DetailsDialog from './recordDetails/DetailsDialog';
import ColumnViewSelector from './ColumnViewSelector';
import ActionFields from './ActionFields';
import ListActions from './ListActions';
import Filters from './Filters';

const defaultFilters = { hideAcknowledged: false };

const GenericTable = ({ item, groupByValue }) => {
  const version = useVersion();
  const refresh = useRefresh();
  const resource = `UserData/grouping/${item.id}`;
  const columnData = useMemo(() => JSON.parse(item.data), [item.data]);
  const allTypes = useMemo(
    () => columnData.map(i => i.type).filter(i => i >= customTypeIdMeasure),
    [columnData],
  );

  const firstIdentified = useMemo(
    () => columnData.find(i => i.identifier)?.field ?? undefined,
    [columnData],
  );

  const types = useMemo(() => {
    return allTypes.length > 0 && Number.isInteger(allTypes[0])
      ? [...new Set(allTypes)]
      : [];
  }, [allTypes]);

  const [currentRecord, setCurrentRecord] = useState({});
  const [displayDetails, setDisplayDetails] = useState(false);
  const [typeValues, setTypeValues] = useState([]);

  const [users, setUsers] = useState([]);
  useAsync(getHintUsers, setUsers);

  useEffect(() => {
    if (groupByValue && groupByValue.type >= 1000 && groupByValue.displayType) {
      const dataMatches = id =>
        typeValues.find(i => i.typeId === groupByValue.type && i.id === +id);
      document
        .querySelectorAll('.rdg-group-cell-content')
        .forEach(i => (i.textContent = dataMatches(i.textContent)?.name));
    }
  }, [groupByValue, typeValues]);

  const getTypeValuesData = useCallback(
    () => getTypeValues(types.map(i => `typeIds=${i}`).join('&')),
    [types],
  );
  useAsync(getTypeValuesData, setTypeValues);

  const handleDisplayDetails = value => {
    setCurrentRecord(value);
    setDisplayDetails(true);
  };

  const handleCloseDetails = () => {
    setDisplayDetails(false);
  };

  const columns = useMemo(
    () => [
      ...columnData
        .filter(cd => !cd.isCombine)
        .map((cd, index) => ({
          name: <TableLabel item={cd} typeValues={typeValues} />,
          /**
           * we set here index because we have duplication of field value
           */
          key: `${cd.field}${index}`,
          columnTextKey: cd.field,
          align: cd.displayType === 'badge' ? 'center' : 'left',
          accessor: rowData => (
            <ColumnViewSelector
              item={cd}
              record={rowData}
              key={cd.field}
              source={cd.field}
              typeValues={typeValues}
              sortable={false}
            />
          ),
        })),
      ...columnData
        .filter(cd => cd.isCombine)
        .map(itemColumn => ({
          name: itemColumn.name,
          key: itemColumn.field,
          accessor: rowData => (
            <AggergatedData
              key={itemColumn.field}
              source={itemColumn.field}
              typeValues={typeValues}
              combCount={itemColumn.combCount}
              record={rowData}
            />
          ),
        })),
      {
        name: 'Assigned',
        key: 'assignment',
      },
      {
        name: 'Actions',
        key: 'actions',
        accessor: rowData => (
          <ActionFields
            handleUpdateClick={() => handleDisplayDetails(rowData)}
            record={rowData}
            handleRefresh={refresh}
            users={users}
            dataViewId={item.id}
          />
        ),
      },
    ],
    [columnData, item.id, refresh, typeValues, users],
  );

  return (
    <div key={version}>
      <MuiVirtualizedTable
        rowHeight={50}
        actions={<ListActions onRefresh={refresh} dataViewId={item.id} />}
        bulkActions={
          <BulkActionButtons
            users={users}
            url={deletePolicyUri}
            showAssignment
            dataViewId={item.id}
          />
        }
        additionalControllerProps={{ exporter: false }}
        defaultFilters={defaultFilters}
        groupByValue={groupByValue?.field ?? 'None'}
        filters={<Filters />}
        columns={columns}
        resource={resource}
        defaultSort={{ field: firstIdentified, order: sortOrders.desc }}
      />
      <DetailsDialog
        isOpen={displayDetails}
        onClose={handleCloseDetails}
        record={currentRecord}
        fields={columnData}
        typeValues={typeValues}
        onRefresh={refresh}
      />
    </div>
  );
};

GenericTable.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  groupByValue: PropTypes.objectOf(PropTypes.any),
};

export default GenericTable;
