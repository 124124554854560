import React from 'react';
import localStorageConst from '@constants/localStorage';
import localStorage from '@services/localStorage';

import { ReactComponent as IncidentsIcon } from '@assets/icons/notifications.svg';
import { ReactComponent as EmailIcon } from '@assets/icons/email_active.svg';
import { ReactComponent as CustomTypesIcon } from '@assets/icons/customTypes.svg';
import { ReactComponent as ChatsIcon } from '@assets/icons/share.svg';
import { ReactComponent as IconNetwork } from '@assets/icons/network.svg';
import { ReactComponent as TicketIcon } from '@assets/icons/ticket_active.svg';
import { ReactComponent as BillingIcon } from '@assets/icons/bill.svg';
import { ReactComponent as OutgoingMail } from '@assets/icons/outgoingMail.svg';
import { ReactComponent as SubscriptionIcon } from '@assets/icons/subscriptions.svg';
import { ReactComponent as TenantIcon } from '@assets/icons/tenants.svg';
import { ReactComponent as RoadmapIcon } from '@assets/icons/roadmap.svg';
import { ReactComponent as ParsersIcon } from '@assets/icons/parsers.svg';
import { ReactComponent as LinkIcon } from '@assets/icons/link_orange.svg';
import { ReactComponent as PercentsIcon } from '@assets/icons/percents.svg';
import { ReactComponent as UploadIcon } from '@assets/icons/upload_active.svg';
import { ReactComponent as CalendarIcon } from '@assets/icons/calendar.svg';
import { ReactComponent as SettingsIcon } from '@assets/icons/new/settings.svg';
import { ReactComponent as DashboardNewIcon } from '@assets/icons/new/dashboard.svg';
import { ReactComponent as SmsDialogIcon } from '@assets/icons/new/smsMessages.svg';
import { ReactComponent as TemplatesIcon } from '@assets/icons/new/templates.svg';
import { ReactComponent as TicketingIcon } from '@assets/icons/cash.svg';
import { ReactComponent as TriagesIcon } from '@assets/icons/new/notes.svg';
import { ReactComponent as DataSourceIcon } from '@assets/icons/new/dataSources.svg';
import { ReactComponent as MailBoxesIcon } from '@assets/icons/new/mailBoxes.svg';
import { ReactComponent as WebHookIcon } from '@assets/icons/new/webhook.svg';
import { ReactComponent as GenericSourceIcon } from '@assets/icons/new/genericSources.svg';
import { ReactComponent as NewDataIcon } from '@assets/icons/new/data.svg';
import { ReactComponent as NewDataViewIcon } from '@assets/icons/new/dataView.svg';
import { ReactComponent as InboundDocuments } from '@assets/icons/new/inboundDocuments.svg';
import { ReactComponent as NewCustomTypes } from '@assets/icons/new/customTypes.svg';
import { ReactComponent as UptimeMonitor } from '@assets/icons/new/uptimeMonitor.svg';
import { ReactComponent as NewIncidentsIcon } from '@assets/icons/new/incidents.svg';
import { ReactComponent as AlertsIcon } from '@assets/icons/new/alerts.svg';
import { ReactComponent as TriggersIcon } from '@assets/icons/new/triggers.svg';
import { ReactComponent as EscalationRulesIcon } from '@assets/icons/new/escalationRules.svg';
import { ReactComponent as UserIcon } from '@assets/icons/new/user.svg';
import { ReactComponent as NotificationGroupsIcon } from '@assets/icons/new/notificationGroups.svg';
import { ReactComponent as ChatsIcons } from '@assets/icons/new/chat.svg';
import { ReactComponent as IntegrationsIcons } from '@assets/icons/new/integrations.svg';
import { ReactComponent as AlertIntegrationsIcons } from '@assets/icons/new/alertIntegrations.svg';
import { ReactComponent as MyPlanIcon } from '@assets/icons/new/plan.svg';
import { ReactComponent as CompanyMappingIcon } from '@assets/icons/new/companyMapping.svg';
import { ReactComponent as PortalSettingsIcon } from '@assets/icons/new/portalSettings.svg';
import { ReactComponent as KeyIcon } from '@assets/icons/new/key.svg';
import { ReactComponent as PaletteIcon } from '@assets/icons/new/palette.svg';
import { ReactComponent as PhoneIcon } from '@assets/icons/new/phone.svg';

import {
  DATA,
  DATA_VIEWS,
  INCIDENTS_LIST,
  DASHBOARD,
  DOCUMENTS,
  INTEGRATIONS,
  TEMPLATES,
  AT_HOOKS,
  CW_HOOKS,
  MAIL_BOXES,
  WEB_HOOKS,
  GENERIC_SOURCES,
  CUSTOM_TYPES,
  DEVICE_MONITORS,
  TRIGGER,
  USERS,
  PSA_TICKETS_LOGS,
  BILLING,
  TENANT_STATISTIC,
  TENANT_SETTINGS,
  NOTIFICATIONS_GROUPS,
  COMPANY_MAPPING,
  API_KEYS,
  ROADMAP,
  TENANTS,
  ADMIN_PARSERS,
  SUBSCRIPTIONS,
  DEMO_LINKS,
  COUPONS,
  SMS_LOGS,
  PARSING_STATUS,
  BILLING_LINKS,
  CUSTOM_PALETTE,
  FTP_CONNECTIONS,
  DATA_SOURCES_INTEGRATION,
  ESCALATION_RULES,
  SCHEDULER_PAGE,
  CUSTOM_FIELDS,
  AUTOTASK_SMS,
  TEST_EMAIL,
  MESSAGING_CONFIGURATION,
  MESSAGING_CHANNELS,
  MESSAGING_TEMPLATES,
  TENANT_MODULES,
  TENANT_MODULES_TEMPLATE,
  PSA_TICKETS,
  TEAMS_CONNECTIONS,
  SMS_CONVERSATIONS,
  LIVE_CHAT_CONVERSATIONS,
  PASSWORDS,
  VERIFICATION_SETTINGS,
  SMS_BLACK_LIST,
  BLOCKED_SENDERS,
  OPT_IN_LIST,
  PSA_CONTACTS,
  FILE_UPLOAD,
  LIVE_CHAT,
  CLIENT_PORTAL,
  CLIENT_PORTAL_CONFIGS,
  CLIENT_PORTAL_DEFAULTS,
  ISSUE_TYPES,
  INVITED_USERS,
  VERIFICATION_HISTORY,
  SMS_CAMPAIGNS,
  SMS_CAMPAIGN_BUILDER,
  SECURE_DATA_SETTINGS,
  PSA_TECH_VERIFICATION,
  COMPANIES_VERIFICATION_SETTINGS,
  OPTIN_SETTINGS,
  CREATE_TICKET,
  SETUP_WIZARD,
  EMAIL_SETTINGS,
  CLIENT_PORTAL_USERS,
  PURCHASE_PHONE_NUMBERS,
  SECURITY_INTEGRATION,
  NOTIFICATIONS_DATA,
  OUTGOING_MAIL,
  MESSAGING_NUMBERS,
  HELPDESK_AI,
} from '@constants/routes';

const allUsersPermission = [1, 2, 3];
const adminsPermission = [1, 2];
const superAdminPermission = [1];

export const res = {
  mailBoxes: 'mailBoxes',
  webHooks: 'webHooks',
  genericSources: 'genericSources',
  triggers: 'triggers',
  uptimeMonitors: 'uptimeMonitors',
  smsMessaging: 'smsMessaging',
  chats: 'chats',
  liveChats: 'liveChats',
  alertSystemIntegrations: 'alertSystemIntegrations',
  webHookIntegrations: 'webHookIntegrations',
  autotaskNoteToSms: 'autotaskNoteToSms',
};

const sidebarItems = Object.freeze({
  clientPortal: {
    label: 'Client Portal',
    icon: <TriagesIcon />,
    submenuItems: [
      {
        path: EMAIL_SETTINGS,
        label: 'Invite Email Template',
        icon: <EmailIcon />,
        dependencies: [],
      },
      {
        path: CLIENT_PORTAL,
        label: 'Client Portal',
        icon: <TriagesIcon />,
        dependencies: [],
      },
      {
        path: CLIENT_PORTAL_CONFIGS,
        label: 'Configurations',
        icon: <TriagesIcon />,
        dependencies: [],
      },
      {
        path: CLIENT_PORTAL_DEFAULTS,
        label: 'Ticket Defaults',
        icon: <TriagesIcon />,
        dependencies: [],
      },
      {
        path: ISSUE_TYPES,
        label: 'Issue Types',
        icon: <TriagesIcon />,
        dependencies: [],
      },
      {
        path: CLIENT_PORTAL_USERS,
        label: 'Portal Users',
        icon: <IconNetwork />,
        dependencies: [],
      },
    ],
  },
  verification: {
    label: 'Verification',
    icon: <MyPlanIcon />,
    submenuItems: [
      {
        path: PSA_TECH_VERIFICATION,
        label: 'Tech Verification',
        icon: <ChatsIcons />,
        dependencies: [],
      },
      {
        path: VERIFICATION_HISTORY,
        label: 'Verification History',
        icon: <SmsDialogIcon />,
        dependencies: [],
      },
      {
        path: VERIFICATION_SETTINGS,
        label: 'Verification Settings',
        icon: <PortalSettingsIcon />,
        dependencies: [],
      },
      {
        path: COMPANIES_VERIFICATION_SETTINGS,
        label: 'Policies',
        icon: <PortalSettingsIcon />,
        dependencies: [],
      },
    ],
  },
  dataSources: {
    label: 'Data Sources',
    icon: <DataSourceIcon />,
    submenuItems: [
      {
        path: MAIL_BOXES,
        label: 'Mail Boxes',
        icon: <MailBoxesIcon />,
        dependencies: [res.mailBoxes],
      },
      {
        path: WEB_HOOKS,
        label: 'Web Hooks',
        icon: <WebHookIcon />,
        dependencies: [res.webHooks],
      },
      {
        path: GENERIC_SOURCES,
        label: 'App Bots',
        icon: <GenericSourceIcon />,
        dependencies: [res.genericSources],
      },
    ],
  },
  data: {
    path: DATA,
    label: 'Data',
    icon: <NewDataIcon />,
    submenuItems: [
      {
        path: DATA,
        label: 'Data',
        icon: <NewDataIcon />,
        dependencies: [res.mailBoxes, res.webHooks, res.genericSources],
      },
      {
        path: DATA_VIEWS,
        label: 'Data Views',
        icon: <NewDataViewIcon />,
        dependencies: [res.mailBoxes, res.webHooks, res.genericSources],
      },
      {
        path: DOCUMENTS,
        label: 'Inbound Emails',
        icon: <InboundDocuments />,
        dependencies: [res.mailBoxes],
      },
      {
        path: DASHBOARD,
        label: 'Dashboard',
        icon: <DashboardNewIcon />,
        dependencies: [res.mailBoxes, res.webHooks, res.genericSources],
      },
      {
        path: CUSTOM_TYPES,
        label: 'Custom Types',
        icon: <NewCustomTypes />,
        dependencies: [res.mailBoxes, res.webHooks, res.genericSources],
      },
      {
        path: DEVICE_MONITORS,
        label: 'Uptime Monitors',
        icon: <UptimeMonitor />,
        dependencies: [res.uptimeMonitors],
      },
    ],
  },
  notifications: {
    path: NOTIFICATIONS_DATA,
    label: 'Notifications',
    icon: <IncidentsIcon />,
    dependencies: [
      res.mailBoxes,
      res.webHooks,
      res.genericSources,
      res.triggers,
      res.uptimeMonitors,
    ],
  },
  messaging: {
    label: 'Messaging',
    icon: <EmailIcon />,
    submenuItems: [
      {
        path: MESSAGING_CONFIGURATION,
        label: 'Configurations',
        icon: <SettingsIcon />,
        dependencies: [res.smsMessaging],
      },
      {
        path: MESSAGING_CHANNELS,
        label: 'Messaging Channels',
        icon: <SmsDialogIcon />,
        dependencies: [res.smsMessaging],
      },
      {
        path: SMS_CONVERSATIONS,
        label: 'Message Templates',
        icon: <SmsDialogIcon />,
        dependencies: [],
      },
      {
        path: SMS_CAMPAIGN_BUILDER,
        label: 'Broadcast Messages',
        icon: <TenantIcon />,
        dependencies: [],
      },
      {
        path: SMS_CAMPAIGNS,
        label: 'Messaging Groups',
        icon: <TenantIcon />,
        dependencies: [],
      },
      {
        path: SMS_BLACK_LIST,
        label: 'Opted-Out Recipients',
        icon: <TenantIcon />,
        dependencies: [],
      },
      {
        path: BLOCKED_SENDERS,
        label: 'Blocked Senders',
        icon: <TenantIcon />,
        dependencies: [],
      },
      {
        path: OPTIN_SETTINGS,
        label: 'Opt-in Settings',
        icon: <PortalSettingsIcon />,
        dependencies: [],
      },
      {
        path: SMS_LOGS,
        label: 'Messaging Logs',
        icon: <SmsDialogIcon />,
        dependencies: [],
      },
      {
        path: OPT_IN_LIST,
        label: 'Opt-in Data',
        icon: <TicketingIcon />,
        dependencies: [],
      },
    ],
  },
  integrations: {
    label: 'Integrations',
    icon: <IntegrationsIcons />,
    submenuItems: [
      {
        path: DATA_SOURCES_INTEGRATION,
        label: 'Alert Integrations',
        icon: <AlertIntegrationsIcons />,
        dependencies: [res.alertSystemIntegrations],
      },
      {
        path: INTEGRATIONS,
        label: 'PSA Integrations',
        icon: <IntegrationsIcons />,
        dependencies: [
          res.mailBoxes,
          res.webHooks,
          res.genericSources,
          res.triggers,
          res.uptimeMonitors,
          res.smsMessaging,
          res.webHookIntegrations,
          res.autotaskNoteToSms,
        ],
      },
      {
        path: SECURITY_INTEGRATION,
        label: 'Security Integrations',
        icon: <IntegrationsIcons />,
        dependencies: [],
      },
      {
        path: TEMPLATES,
        label: 'Alert Templates',
        icon: <TemplatesIcon />,
        dependencies: [res.alertSystemIntegrations],
      },
      {
        path: AT_HOOKS,
        label: 'Autotask Webhooks',
        icon: <IntegrationsIcons />,
        dependencies: [res.webHookIntegrations],
      },
      {
        path: CW_HOOKS,
        label: 'ConnectWise Webhooks',
        icon: <IntegrationsIcons />,
        dependencies: [res.webHookIntegrations],
      },

      {
        path: AUTOTASK_SMS,
        label: 'Autotask Note to SMS',
        icon: <IntegrationsIcons />,
        dependencies: [res.autotaskNoteToSms],
      },

      {
        path: FILE_UPLOAD,
        label: 'Upload files',
        icon: <UploadIcon />,
        dependencies: [],
      },
    ],
  },
  tenant: {
    label: 'Portal Settings',
    icon: <PortalSettingsIcon />,
    submenuItems: [
      {
        path: BILLING,
        label: 'Billing',
        icon: <BillingIcon />,
        dependencies: [],
      },
      {
        path: HELPDESK_AI,
        label: 'Helpdesk AI Assistant',
        icon: <PortalSettingsIcon />,
        dependencies: [],
      },
      {
        path: OUTGOING_MAIL,
        label: 'Outgoing Mail',
        icon: <OutgoingMail />,
        dependencies: [],
      },
      {
        path: TENANT_STATISTIC,
        label: 'Usage Statistics',
        icon: <ChatsIcon />,
        dependencies: [],
      },
      {
        path: SETUP_WIZARD,
        label: 'Setup Wizard',
        icon: <PortalSettingsIcon />,
        dependencies: [],
      },
      {
        path: TENANT_SETTINGS,
        label: 'Settings',
        icon: <TenantIcon />,
        dependencies: [],
      },
      {
        path: CUSTOM_FIELDS,
        label: 'Custom Fields',
        icon: <CustomTypesIcon />,
        dependencies: [
          res.mailBoxes,
          res.webHooks,
          res.genericSources,
          res.triggers,
          res.uptimeMonitors,
        ],
      },
      {
        path: API_KEYS,
        label: 'API Keys',
        icon: <KeyIcon />,
        dependencies: [res.mailBoxes, res.webHooks, res.genericSources],
      },
      {
        path: PASSWORDS,
        label: 'Passwords',
        icon: <KeyIcon />,
        dependencies: [],
      },
      {
        path: ROADMAP,
        label: 'Roadmap',
        icon: <RoadmapIcon />,
        dependencies: [],
      },
      {
        path: CUSTOM_PALETTE,
        label: 'Custom Palette',
        icon: <PaletteIcon />,
        dependencies: [],
      },
      {
        path: FTP_CONNECTIONS,
        label: 'FTP Connections',
        icon: <UploadIcon />,
        dependencies: [res.mailBoxes, res.webHooks, res.genericSources],
      },
      {
        path: TEAMS_CONNECTIONS,
        label: 'Teams Connections',
        icon: <PortalSettingsIcon />,
        dependencies: [],
      },
      {
        path: TEST_EMAIL,
        label: 'Test Email',
        icon: <TenantIcon />,
        dependencies: [],
      },
      {
        path: SECURE_DATA_SETTINGS,
        label: 'Secure Data Settings',
        icon: <PortalSettingsIcon />,
        dependencies: [],
      },
    ],
  },
  admin: {
    label: 'Admin',
    icon: <PortalSettingsIcon />,
    submenuItems: [
      {
        path: TENANTS,
        label: 'Tenants',
        icon: <TenantIcon />,
        dependencies: [],
      },
      {
        path: ADMIN_PARSERS,
        label: 'Parsers',
        icon: <ParsersIcon />,
        dependencies: [],
      },
      {
        path: SUBSCRIPTIONS,
        label: 'Subscriptions',
        icon: <SubscriptionIcon />,
        dependencies: [],
      },
      {
        path: DEMO_LINKS,
        label: 'Demo Links',
        icon: <LinkIcon />,
        dependencies: [],
      },
      {
        path: COUPONS,
        label: 'Coupons',
        icon: <BillingIcon />,
        dependencies: [],
      },
      {
        path: PARSING_STATUS,
        label: 'Parsing Status',
        icon: <PercentsIcon />,
        dependencies: [],
      },
      {
        path: BILLING_LINKS,
        label: 'Billing Links',
        icon: <LinkIcon />,
        dependencies: [],
      },
      {
        path: MESSAGING_NUMBERS,
        label: 'Messaging Numbers',
        icon: <PhoneIcon />,
        dependencies: [],
      },
      {
        path: PURCHASE_PHONE_NUMBERS,
        label: 'Purchase Numbers',
        icon: <PhoneIcon />,
        dependencies: [],
      },
      {
        path: TENANT_MODULES,
        label: 'Tenant modules',
        icon: <TenantIcon />,
        dependencies: [],
      },
      {
        path: TENANT_MODULES_TEMPLATE,
        label: 'New tenant modules',
        icon: <TenantIcon />,
        dependencies: [],
      },
    ],
  },
  contacts: {
    label: 'Contacts',
    path: PSA_CONTACTS,
    icon: <UserIcon />,
    dependencies: [],
  },
  ticketing: {
    label: 'Ticketing',
    icon: <TicketIcon />,
    submenuItems: [
      {
        path: PSA_TICKETS_LOGS,
        label: 'Ticket Logs',
        icon: <TicketIcon />,
        dependencies: [],
      },
      {
        path: COMPANY_MAPPING,
        label: 'Company Mapping',
        icon: <CompanyMappingIcon />,
        dependencies: [
          res.mailBoxes,
          res.webHooks,
          res.genericSources,
          res.triggers,
          res.uptimeMonitors,
        ],
      },
      {
        path: PSA_TICKETS,
        label: 'PSA Tickets',
        icon: <TicketingIcon />,
        dependencies: [res.smsMessaging],
      },
      {
        path: MESSAGING_TEMPLATES,
        label: 'Templates',
        icon: <TemplatesIcon />,
        dependencies: [res.smsMessaging],
      },
      {
        path: CREATE_TICKET,
        label: 'Quick Ticket',
        icon: <TemplatesIcon />,
        dependencies: [],
      },
    ],
  },
  incidents: {
    label: 'Incidents',
    icon: <NewIncidentsIcon />,
    submenuItems: [
      {
        path: INCIDENTS_LIST,
        label: 'Alerts',
        icon: <AlertsIcon />,
        dependencies: [res.triggers],
      },
      {
        path: TRIGGER,
        label: 'Triggers',
        icon: <TriggersIcon />,
        dependencies: [res.triggers],
      },
      {
        path: ESCALATION_RULES,
        label: 'Escalation Rules',
        icon: <EscalationRulesIcon />,
        dependencies: [res.triggers],
      },
    ],
  },
  teams: {
    label: 'Teams',
    icon: <IconNetwork />,
    submenuItems: [
      {
        path: USERS,
        label: 'Users',
        icon: <UserIcon />,
        dependencies: [],
      },
      {
        path: INVITED_USERS,
        label: 'Invite Users',
        icon: <UserIcon />,
        dependencies: [],
      },
      {
        path: NOTIFICATIONS_GROUPS,
        label: 'Notification Groups',
        icon: <NotificationGroupsIcon />,
        dependencies: [
          res.mailBoxes,
          res.webHooks,
          res.genericSources,
          res.triggers,
          res.uptimeMonitors,
        ],
      },
      // {
      //   path: CHATS,
      //   label: 'Chats',
      //   icon: <ChatsIcons />,
      //   dependencies: [res.chats],
      // },
      {
        path: LIVE_CHAT,
        label: 'Live Chats',
        icon: <ChatsIcons />,
        dependencies: [res.chats],
      },
      {
        path: LIVE_CHAT_CONVERSATIONS,
        label: 'Live Chat Conversations',
        icon: <ChatsIcons />,
        dependencies: [res.chats],
      },
      {
        path: SCHEDULER_PAGE,
        label: 'Scheduling',
        icon: <CalendarIcon />,
        dependencies: [
          res.mailBoxes,
          res.webHooks,
          res.genericSources,
          res.triggers,
          res.uptimeMonitors,
        ],
      },
    ],
  },
});

const process = (result, propName, allowedResources) => {
  const item = { ...sidebarItems[propName] };
  const dataSrcItems = item.submenuItems
    .filter(
      i =>
        !!i.dependencies &&
        (!i.dependencies.length ||
          i.dependencies.some(d => allowedResources.includes(d))),
    )
    .sort((a, b) =>
      // eslint-disable-next-line no-nested-ternary
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
    );
  if (dataSrcItems.length) {
    result[propName] = item;
    result[propName].submenuItems = dataSrcItems;
  }
};

export const getSideBarItems = () => {
  const allowedResources = localStorage.tryGetItem(
    localStorageConst.ALLOWED_RESOURCES,
  );
  if (!allowedResources) return sidebarItems;
  const result = {};

  Object.keys(sidebarItems).forEach(key => {
    if (key === 'notifications') {
      if (
        sidebarItems.notifications.dependencies.some(i =>
          allowedResources.includes(i),
        )
      )
        result.notifications = { ...sidebarItems.notifications };
    } else if (key === 'contacts') {
      result.contacts = { ...sidebarItems.contacts };
    } else {
      process(result, key, allowedResources);
    }
  });

  return result;
};

const sidebarItemsLabelsRaw = [
  { label: 'contacts', permissions: allUsersPermission },
  { label: 'messaging', permissions: allUsersPermission },
  { label: 'ticketing', permissions: allUsersPermission },
  { label: 'teams', permissions: allUsersPermission },
  { label: 'clientPortal', permissions: allUsersPermission },
  { label: 'verification', permissions: allUsersPermission },
  { label: 'notifications', permissions: allUsersPermission },
  { label: 'data', permissions: allUsersPermission },
  {
    label: 'dataSources',
    permissions: allUsersPermission,
  },
  { label: 'integrations', permissions: allUsersPermission },
  { label: 'incidents', permissions: allUsersPermission },
  { label: 'tenant', permissions: adminsPermission },
  { label: 'settings', permissions: allUsersPermission },
  { label: 'chats', permissions: allUsersPermission },
  { label: 'admin', permissions: superAdminPermission },
];

export const sidebarItemsLabels = sidebarItemsLabelsRaw.map(item => ({
  ...item,
  search: sidebarItems[item.label]?.submenuItems
    ? sidebarItems[item.label].submenuItems?.map(i => i.label).join(',')
    : sidebarItems[item.label]?.label,
}));

export default {};
