import { actionTypes } from '../actions';

const initialState = {
  userData: {},
  currentUser: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_PROFILE_DATA:
      return { ...state, userData: action.payload };
    case actionTypes.USER_SET_CURRENT_USER:
      return { ...state, currentUser: action.payload };
    case actionTypes.DELETE_PROFILE:
      return { ...state, userData: {} };
    default:
      return state;
  }
};

export default userReducer;
