import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { ThunkDeleteTeamsConnections } from '@store/slices/teamsConnections/thunks';
import { actions } from '@store/actions';

import DeleteIcon from '@assets/icons/delete.svg';
import EditIcon from '@assets/icons/edit.svg';

import ActionFieldItem from '@ui/components/common/ActionButtons/ActionFieldItem';
import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';
import AddNewTeamsConnection from '../dialogs/AddNewTeamsConnection';

const TeamsConnectionsTableItemActions = React.memo(({ record }) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();

  const handleDelete = useCallback(
    async id => {
      setProcessing(true);
      const itemId = await dispatch(ThunkDeleteTeamsConnections(id)).unwrap();
      dispatch(actions.removeItemFromList({ id: itemId }));
      setProcessing(false);
    },
    [dispatch],
  );

  return (
    <div>
      <ActionFieldItem
        icon={EditIcon}
        alt="edit"
        handler={() => {
          setOpenEditDialog(true);
        }}
        toolTip="Edit"
      />

      <ActionFieldItem
        icon={DeleteIcon}
        alt="delete"
        handler={() => {
          setOpenDeleteDialog(true);
        }}
        toolTip="Delete"
      />

      {openDeleteDialog && (
        <ConfirmDialog
          loading={processing}
          onAccept={() => {
            handleDelete(record.id);
          }}
          onCancel={() => {
            setOpenDeleteDialog(false);
          }}
          open
          setOpen={setOpenDeleteDialog}
          title="Confirm Action"
          content="Are you sure you want to delete this item?"
        />
      )}

      {openEditDialog && (
        <AddNewTeamsConnection
          open={openEditDialog}
          setOpen={setOpenEditDialog}
          item={record}
        />
      )}
    </div>
  );
});

TeamsConnectionsTableItemActions.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default TeamsConnectionsTableItemActions;
