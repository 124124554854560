import React from 'react';

import { TableTypes } from '@components/Table/constants';
import { types } from '@constants/deviceMonitorTypes';
import { deviceMonitorsStatuses } from '@ui/pages/uptimeMonitors/DeviceMonitors/constants';

import Percentage from './components/Percentage';
import RunningState from './components/RunningState';
import Statuses from './components/Statuses';
import TableRowActions from './components/TableRowActions';

export const getDeviceMonitorsColumns = regionsOptions => [
  {
    name: 'Status',
    key: 'lastEventType',
    dropDownValues: deviceMonitorsStatuses,
    sortable: true,
    searchable: true,
    type: TableTypes.dropdown,
    filterByAutocomplete: 'lastEventType',
    isCustomAccessor: true,
    minWidth: 130,
    accessor: ({ lastEventType, successRate }) => (
      <Statuses lastStatus={lastEventType} successRate={successRate} />
    ),
  },
  {
    name: 'Uptime',
    key: 'percentage',
    isCustomAccessor: true,
    accessor: row => <Percentage successRate={+row.successRate} />,
    minWidth: 100,
  },
  {
    name: 'Company',
    key: 'company',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Site',
    key: 'site',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Device Name',
    key: 'deviceName',
    sortable: true,
    searchable: true,
    minWidth: 180,
  },
  {
    name: 'Type',
    key: 'type',
    sortable: true,
    searchable: true,
    type: TableTypes.dropdown,
    filterByAutocomplete: 'type',
    dropDownValues: types,
    minWidth: 150,
  },
  {
    name: 'URL',
    key: 'url',
    sortable: true,
    searchable: true,
    minWidth: 150,
  },
  {
    name: 'Region',
    key: 'region',
    sortable: true,
    searchable: true,
    type: TableTypes.dropdown,
    filterByAutocomplete: 'region',
    dropDownValues: regionsOptions,
    minWidth: 150,
  },
  {
    name: 'Period',
    key: 'period',
    sortable: true,
    labelAlignment: 'center',
    minWidth: 130,
    isCustomAccessor: true,
    accessor: row => `${row.period} min`,
  },
  {
    name: 'Latency',
    key: 'latency',
    sortable: true,
    labelAlignment: 'center',
    minWidth: 130,
    isCustomAccessor: true,
    accessor: row => `${row.latency} ms`,
  },
  {
    name: 'Running State',
    key: 'enabled',
    sortable: true,
    isCustomAccessor: true,
    minWidth: 150,
    labelAlignment: 'center',
    accessor: row => <RunningState row={row} />,
  },
  {
    name: 'Actions',
    key: 'actions',
    isCustomAccessor: true,
    minWidth: 150,
    accessor: row => <TableRowActions itemId={row.id} />,
  },
];

export default {};
