import React, { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import PropTypes from 'prop-types';
import ReusableButton from '@common/Button/Button';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { useDispatch } from 'react-redux';
import { actions } from '@store/actions';
import postWithResponse from '@services/api/common/postWithResponse';
import appConfig from '@configs/appConfig';
import putWithResponse from '@services/api/common/putWithResponse';
import useTranslation from '@common/hooks/useTranslation';
import { partial } from 'lodash';
import { TableTypes } from '@components/Table/constants';
import Table from '@components/Table';
import ActionField from './ActionField';
import FormDialog from './FormDialog';
import useStyles from '../../Admin/styles';

const FtpConnections = ({ resource }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const { getText } = useTranslation(resource);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [itemForEdit, setItemForEdit] = useState({});
  const resetAfterSubmit = () => {
    setLoading(false);
    setItemForEdit(null);
    setFormDialogOpen(false);
  };

  const onDelete = record => {
    setIdForDelete(record.id);
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = () => {
    setItemForEdit(null);
    setFormDialogOpen(false);
  };

  const handleCreate = values => {
    setLoading(true);
    postWithResponse(`${appConfig.baseUrl}/${resource}`, values)
      .then(data => {
        dispatch(actions.updateItemInList(data));
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => {
        resetAfterSubmit();
      });
  };

  const handleUpdate = (values, id) => {
    setLoading(true);
    putWithResponse(`${appConfig.baseUrl}/${resource}/${id}`, values)
      .then(data => {
        dispatch(actions.updateItemInList(data));
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => {
        resetAfterSubmit();
      });
  };

  const handleDialogSubmit = values => {
    if (itemForEdit?.id) {
      handleUpdate(values, itemForEdit.id);
    } else {
      handleCreate(values);
    }
  };

  const handleCreateOrUpdateBase = record => {
    setItemForEdit(record);
    setFormDialogOpen(true);
  };

  const handleCreateOrUpdate = () => {
    handleCreateOrUpdateBase();
  };

  const handleCancelDelete = () => {
    setIdForDelete(null);
    setDeleteDialogOpen(false);
    setLoading(false);
  };

  const handleDelete = () => {
    setLoading(true);
    dataProvider
      .delete(resource, { id: idForDelete })
      .then(() => {
        dispatch(actions.removeItemFromList({ id: idForDelete }));
      })
      .catch(e => notify(e.message, 'error'))
      .finally(handleCancelDelete);
  };

  return (
    <div className={classes.ftpTableContainer}>
      <ReusableButton
        size="md"
        label="common.add"
        onClick={handleCreateOrUpdate}
        classNameWrapper={classes.ftpActionButton}
        disabled={loading}
      />
      <Table
        actions={null}
        columns={[
          {
            name: 'Name',
            key: 'name',
          },
          {
            name: 'FTP address',
            key: 'url',
          },
          {
            name: 'User Name',
            key: 'userName',
          },
          {
            name: 'Actions',
            key: TableTypes.actions,
            type: TableTypes.actions,
            accessor: rowData => (
              <ActionField
                label="Actions"
                onEdit={partial(handleCreateOrUpdateBase, rowData)}
                onDelete={partial(onDelete, rowData)}
              />
            ),
            width: 100,
          },
        ]}
        resource={resource}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDelete}
        onCancel={handleCancelDelete}
        title={getText('caption')}
        content={getText('text')}
        loading={loading}
      />
      <FormDialog
        open={formDialogOpen}
        onClose={handleDialogClose}
        item={itemForEdit}
        onSubmit={handleDialogSubmit}
        loading={loading}
      />
    </div>
  );
};

FtpConnections.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default FtpConnections;
