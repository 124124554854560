import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '16px 16px 0',
    '& tbody': {
      maxHeight: '73vh',
    },
  },
  actionContainer: {
    margin: '15px 0',
    textAlign: 'right',
  },
  dialogLabelContainer: {
    width: '100%',
  },
  dialogLabelText: {
    textAlign: 'start',
  },
  form: {
    margin: '5px 0px',
  },
  buttonContainer: {
    margin: '15px 0px',
    '& > button': {
      marginRight: '10px',
    },
  },
}));

export default useStyles;
