import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isHtml from 'is-html';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Typography } from '@material-ui/core';

import { ThunkGetTicketInfo } from '@store/slices/crmTicket/thunks';
import {
  ThunkGetKaseyaAccounts,
  ThunkGetKaseyaAssignees,
  ThunkGetKaseyaContacts,
  ThunkGetKaseyaIssueSubTypes,
  ThunkGetKaseyaIssueTypes,
  ThunkGetKaseyaLocations,
  ThunkGetKaseyaPriorities,
  ThunkGetKaseyaQueues,
  ThunkGetKaseyaStatuses,
  ThunkGetKaseyaTypes,
  ThunkUpdateKaseyaTicket,
} from '@store/slices/kaseyaTicket/thunks';
import { KaseyaTicketOptionsSelector } from '@store/slices/kaseyaTicket';
import { requiredValidator } from '@utils/validators';

import crmSources from '@constants/crmSources';

import EditKaseyaDetailsDialog from '@components/CrmTicket/Edit/dialog/EditKaseyaDetailsDialog';
import Loading from '@common/Loading/Loading';
import AutocompleteFormInput from '@common/AutocompleteFormInput/AutocompleteFormInput';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import TicketNotes from '@components/CrmTicket/Notes/TicketNotes';

import useStyles from '../styles';

const EditKaseyaInfoForm = ({ crmDefaultId, ticketId, handleBackRedirect }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [ticketInfo, setTicketInfo] = useState(undefined);
  const [openHtmlContentDialog, setOpenHtmlContentDialog] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    accounts,
    locations,
    contacts,
    queues,
    statuses,
    priorities,
    types,
    issueTypes,
    issueSubTypes,
    assignees,
  } = useSelector(KaseyaTicketOptionsSelector);

  const validate = values => {
    return {
      accountId: requiredValidator(values.accountId),
      locationId: requiredValidator(values.locationId),
      queueId: requiredValidator(values.queueId),
      statusId: requiredValidator(values.statusId),
      priorityId: requiredValidator(values.priorityId),
      typeId: requiredValidator(values.typeId),
      title: requiredValidator(values.title),
      details: requiredValidator(values.details),
    };
  };

  const submit = async (values, skipRedirectBack = false) => {
    setProcessing(true);
    await dispatch(
      ThunkUpdateKaseyaTicket({
        crmDefaultId,
        payload: values,
      }),
    )
      .unwrap()
      .then(() => {
        if (skipRedirectBack) return;

        handleBackRedirect();
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleIssueTypeChange = useCallback(
    async (value, form, skipDrop = false) => {
      setProcessing(true);
      if (!skipDrop) {
        form.change('subIssueTypeId', '');
      }

      await dispatch(
        ThunkGetKaseyaIssueSubTypes({ crmDefaultId, issueTypeId: value }),
      );

      setProcessing(false);
    },
    [crmDefaultId, dispatch],
  );

  const handleAccountChange = useCallback(
    async (value, form, skipDrop = false) => {
      setProcessing(true);
      if (!skipDrop) {
        form.change('locationId', '');
        form.change('contactId', '');
      }

      await Promise.all([
        dispatch(
          ThunkGetKaseyaLocations({ crmDefaultId, accountId: value }),
        ).unwrap(),
        dispatch(
          ThunkGetKaseyaContacts({ crmDefaultId, accountId: value }),
        ).unwrap(),
      ]).finally(() => {
        setProcessing(false);
      });
    },
    [dispatch, crmDefaultId],
  );

  const onMount = useCallback(async () => {
    setLoading(true);

    // obtaining ticket info to get relative date ex. locations depends on accountId
    const ticketData = await dispatch(
      ThunkGetTicketInfo({
        crmId: crmDefaultId,
        ticketId,
      }),
    )
      .unwrap()
      .then(res => {
        setTicketInfo(res);
        return res;
      });

    await Promise.all([
      dispatch(ThunkGetKaseyaAccounts({ crmDefaultId })).unwrap(),
      dispatch(ThunkGetKaseyaQueues({ crmDefaultId })).unwrap(),
      dispatch(ThunkGetKaseyaStatuses({ crmDefaultId })).unwrap(),
      dispatch(ThunkGetKaseyaPriorities({ crmDefaultId })).unwrap(),
      dispatch(ThunkGetKaseyaTypes({ crmDefaultId })).unwrap(),
      dispatch(ThunkGetKaseyaIssueTypes({ crmDefaultId })).unwrap(),
      dispatch(ThunkGetKaseyaAssignees({ crmDefaultId })).unwrap(),
    ]);

    if (ticketData.accountId) {
      await handleAccountChange(ticketData.accountId, {}, true);
    }
    if (ticketData.issueTypeId) {
      await handleIssueTypeChange(ticketData.issueTypeId, {}, true);
    }

    setLoading(false);
  }, [
    dispatch,
    crmDefaultId,
    ticketId,
    handleAccountChange,
    handleIssueTypeChange,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <div className={classes.withNotesContainer}>
      <Form
        onSubmit={submit}
        initialValues={ticketInfo}
        validate={validate}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <Typography variant="h5">Edit Ticket Information</Typography>
            <div className={classes.twoColumns}>
              <FieldWrapper
                label="Title"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="title"
                    name="title"
                    fullWidth
                    variant="outlined"
                    component={Input}
                    disabled={processing}
                  />
                }
              />

              {ticketInfo?.details && !isHtml(ticketInfo?.details) ? (
                <FieldWrapper
                  label="Details"
                  labelSize={12}
                  contentSize={12}
                  classNameLabelInner={classes.deleteDialogFieldLabelInner}
                  classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                  classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
                  content={
                    <Field
                      id="details"
                      name="details"
                      fullWidth
                      variant="outlined"
                      multiline
                      minRows={4}
                      component={Input}
                      disabled={processing}
                    />
                  }
                />
              ) : (
                <FieldWrapper
                  label="Details"
                  labelSize={12}
                  contentSize={12}
                  classNameLabelInner={classes.deleteDialogFieldLabelInner}
                  classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                  classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
                  content={
                    <div
                      style={{
                        color: 'blue',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setOpenHtmlContentDialog(true);
                      }}
                      role="presentation"
                    >
                      The ticket details contains HTML; click to view it.
                    </div>
                  }
                />
              )}

              <FieldWrapper
                label="Account"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="accountId"
                    name="accountId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    items={accounts}
                    loading={processing}
                  />
                }
              />

              <OnChange name="accountId">
                {value => {
                  handleAccountChange(value, form);
                }}
              </OnChange>
              <FieldWrapper
                label="Location"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="locationId"
                    name="locationId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    items={locations}
                    loading={processing || !values.accountId}
                  />
                }
              />

              <FieldWrapper
                label="Contacts"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="contactId"
                    name="contactId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    items={contacts}
                    loading={processing || !values.accountId}
                  />
                }
              />

              <FieldWrapper
                label="Queue"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="queueId"
                    name="queueId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    items={queues}
                    loading={processing}
                  />
                }
              />

              <FieldWrapper
                label="Status"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="statusId"
                    name="statusId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    items={statuses}
                    loading={processing}
                  />
                }
              />

              <FieldWrapper
                label="Priority"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="priorityId"
                    name="priorityId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    items={priorities}
                    loading={processing}
                  />
                }
              />

              <FieldWrapper
                label="Type"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="typeId"
                    name="typeId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    items={types}
                    loading={processing}
                  />
                }
              />

              <FieldWrapper
                label="Issue Type"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="issueTypeId"
                    name="issueTypeId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    items={issueTypes}
                    loading={processing}
                  />
                }
              />
              <OnChange name="issueTypeId">
                {value => {
                  handleIssueTypeChange(value, form);
                }}
              </OnChange>

              <FieldWrapper
                label="Sub Issue Type"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="subIssueTypeId"
                    name="subIssueTypeId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    items={issueSubTypes}
                    loading={processing || !values.issueTypeId}
                  />
                }
              />

              <FieldWrapper
                label="Assignee"
                labelSize={12}
                contentSize={12}
                classNameLabelInner={classes.deleteDialogFieldLabelInner}
                classNameLabelOuter={classes.deleteDialogFieldLabelOuter}
                classNameLabelContainer={classes.deleteDialogFieldLabelOuter}
                content={
                  <Field
                    id="assigneeId"
                    name="assigneeId"
                    fullWidth
                    variant="outlined"
                    component={AutocompleteFormInput}
                    items={assignees}
                    loading={processing}
                  />
                }
              />
            </div>

            <div className={classes.deleteDialogActions}>
              <ReusableButton
                label="Cancel"
                onClick={() => {
                  handleBackRedirect();
                }}
                disabled={processing}
              />
              <ReusableButton
                label="Submit"
                type="submit"
                disabled={processing}
                loading={processing}
              />
            </div>
            {openHtmlContentDialog && (
              <EditKaseyaDetailsDialog
                onClose={() => {
                  setOpenHtmlContentDialog(false);
                }}
                form={form}
                onUpdate={form.submit}
              />
            )}
          </form>
        )}
      />
      <TicketNotes psaType={crmSources.Kaseya.name} />
    </div>
  );
};

EditKaseyaInfoForm.propTypes = {
  crmDefaultId: PropTypes.string,
  ticketId: PropTypes.string,
  handleBackRedirect: PropTypes.func,
};

export default EditKaseyaInfoForm;
