import localStorageConst from '@constants/localStorage';

export const parseJwtToken = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const setTenantIdToStorage = value =>
  localStorage.setItem(localStorageConst.TENANT_ID, value);

export const getRoleIdFromStorage = () =>
  +localStorage.getItem(localStorageConst.ROLE_ID);

export const setRoleIdToStorage = roleId =>
  localStorage.setItem(localStorageConst.ROLE_ID, roleId);

export const setUserImageUrlToStorage = value =>
  localStorage.setItem(localStorageConst.PROFILE_IMAGE, value);

export const setAllowedResources = value =>
  localStorage.setItem(localStorageConst.ALLOWED_RESOURCES, value);

export const setTenantImageUrlToStorage = value =>
  localStorage.setItem(localStorageConst.TENANT_IMAGE, value);

export const getTenantIdFromStorage = () =>
  localStorage.getItem(localStorageConst.TENANT_ID);

export const getTenantNameFromStorage = () =>
  localStorage.getItem(localStorageConst.TENANT_NAME);

export const getUserImageFromStorage = () =>
  localStorage.getItem(localStorageConst.PROFILE_IMAGE);

export const getTenantImageFromStorage = () =>
  localStorage.getItem(localStorageConst.TENANT_IMAGE);

export const removeTenantImageFromStorage = () =>
  localStorage.removeItem(localStorageConst.TENANT_IMAGE);

export const removeUserDataFromStorage = () => {
  localStorage.removeItem(localStorageConst.FIREBASE_TOKEN);
  localStorage.removeItem(localStorageConst.ROLE_ID);
  localStorage.removeItem(localStorageConst.PROFILE_IMAGE);
  localStorage.removeItem(localStorageConst.USER_ID);
  localStorage.removeItem(localStorageConst.CLIENT_PERMISSIONS);
};

export const getUserId = () => +localStorage.getItem(localStorageConst.USER_ID);

export const getAccessToken = () =>
  localStorage.getItem(localStorageConst.ACCESS_TOKEN);

export const getRefreshToken = () =>
  localStorage.getItem(localStorageConst.REFRESH_TOKEN);
