const notificationSources = Object.freeze({
  0: { name: 'Data View', color: '#67AD5B' },
  1: { name: 'Trigger', color: '#2443ec' },
});

export const notificationSourcesOptions = [
  { value: 0, label: 'Data View' },
  { value: 1, label: 'Trigger' },
];

export default notificationSources;
