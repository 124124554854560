import React, { useState } from 'react';
import { FormControlLabel } from '@material-ui/core';

import Switch from '@common/FilterInputs/Switch';

import { useTableContext } from '../../../../../hooks/table';

import useStyles from '../../styles';

const AutotaskTicketTableFilters = () => {
  const classes = useStyles();

  const [skipCompleted, setSkipCompleted] = useState(true);

  const { setFilteringObject, filtering } = useTableContext();

  return (
    <div className={classes.filtersContainer}>
      <FormControlLabel
        style={{ alignItems: 'flex-end' }}
        control={
          <Switch
            input={{
              onChange: (_, value) => {
                setSkipCompleted(value);
                setFilteringObject(filtering, 'skipCompleted', value);
              },
              value: skipCompleted,
            }}
            checked={skipCompleted}
          />
        }
        label="Skip Completed"
        labelPlacement="start"
      />
    </div>
  );
};

export default AutotaskTicketTableFilters;
