import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import crmSources from '@constants/crmSources';
import { CRM_TICKET_EDIT } from '@constants/routes';
import {
  getPodDisabledTooltip,
  TEXT_OPEN_POD_DIALOG,
  TEXT_OPEN_POD_SECURE_DATA,
} from '@constants/texts/pod';

import EditIcon from '@assets/icons/edit.svg';
import UserIcon from '@assets/icons/user.svg';
import SMSIcon from '@assets/icons/sms.svg';
import ShieldPersonIcon from '@assets/icons/shield_person.svg';
import SyncIcon from '@assets/icons/secureDataSend.svg';

import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import CreateMessagingChannelDialog from '@components/Messaging/dialogs/CreateMessagingChannelDialog';
import AssignKaseyaContactDialog from '@components/PsaTickets/dialogs/AssignKaseyaContactDialog';
import AssignMemberDialog from '@components/PsaTickets/dialogs/AssignAssigneeDialog';
import ItemMenu from '@common/ItemMenu/ItemMenu';
import PodDialog from '@components/PodDialog';

import useStyles from '../../styles';

const KaseyaTicketTableActions = ({ record, crmDefaultId, crmId }) => {
  const [openCreateChannelDialog, setOpenCreateChannelDialog] = useState(false);
  const [openAssignContactDialog, setOpenAssignContactDialog] = useState(false);
  const [openAssignAssigneeDialog, setOpenAssignAssigneeDialog] = useState(
    false,
  );
  const [openSecureDialog, setOpenSecureDialog] = useState(false);
  const [openPodDialog, setOpenPodDialog] = useState(false);

  const classes = useStyles();
  const { push } = useHistory();

  const handleEditRedirect = () => {
    push(
      CRM_TICKET_EDIT.replace(':crmDefaultId', crmDefaultId)
        .replace(':ticketId', record.ticketNumber)
        .replace(':crmName', crmSources.Kaseya.name),
    );
  };

  const handleAssignContactClick = useCallback(() => {
    setOpenAssignContactDialog(true);
    setOpenCreateChannelDialog(false);
  }, []);

  const menuOptions = useMemo(
    () => [
      {
        icon: <img src={SMSIcon} alt="assignResource" />,
        caption: 'Add SMS Channel',
        handler: () => setOpenCreateChannelDialog(true),
      },
      {
        icon: <img src={UserIcon} alt="assignContact" />,
        caption: 'Assign Contact',
        handler: () => setOpenAssignContactDialog(true),
      },
      {
        icon: <img src={UserIcon} alt="addAssignee" />,
        caption: 'Add Assignee',
        handler: () => setOpenAssignAssigneeDialog(true),
      },
    ],
    [],
  );

  const disabled = !record?.contactId;

  return (
    <div className={classes.tableActions}>
      <ActionFieldItem
        alt={TEXT_OPEN_POD_DIALOG}
        handler={() => {
          setOpenPodDialog(true);
        }}
        toolTip={disabled ? getPodDisabledTooltip() : TEXT_OPEN_POD_DIALOG}
        icon={ShieldPersonIcon}
        ignoreDefaultIconSize
        disabled={disabled}
      />
      <ActionFieldItem
        alt={TEXT_OPEN_POD_SECURE_DATA}
        handler={() => setOpenSecureDialog(true)}
        toolTip={disabled ? getPodDisabledTooltip() : TEXT_OPEN_POD_SECURE_DATA}
        icon={SyncIcon}
        ignoreDefaultIconSize
        disabled={disabled}
      />

      <ActionFieldItem
        alt="Edit"
        handler={handleEditRedirect}
        toolTip="Edit"
        icon={EditIcon}
      />

      <ItemMenu options={menuOptions} itemsHeightMultiplier={10} openOnHover />

      {openCreateChannelDialog && (
        <CreateMessagingChannelDialog
          open={openCreateChannelDialog}
          setOpen={setOpenCreateChannelDialog}
          record={{ ...record, id: record.ticketNumber }}
          crmDefaultId={crmDefaultId}
          crmType={crmSources.Kaseya.name}
          handleAssignContactClick={handleAssignContactClick}
        />
      )}

      {openAssignContactDialog && (
        <AssignKaseyaContactDialog
          setOpen={setOpenAssignContactDialog}
          crmDefaultId={crmDefaultId}
          open={openAssignContactDialog}
          record={record}
        />
      )}

      {openAssignAssigneeDialog && (
        <AssignMemberDialog
          crmDefaultId={crmDefaultId}
          open={openAssignAssigneeDialog}
          setOpen={setOpenAssignAssigneeDialog}
          record={record}
        />
      )}

      {openPodDialog && (
        <PodDialog
          itemId={record.contactId}
          onClose={() => {
            setOpenPodDialog(false);
          }}
          itemType="contact"
          psaId={crmId}
          psa={crmSources.Kaseya.label}
        />
      )}

      {openSecureDialog && (
        <PodDialog
          itemId={record.contactId}
          onClose={() => {
            setOpenSecureDialog(false);
          }}
          itemType="contact"
          psaId={crmId}
          psa={crmSources.Kaseya.label}
          page="secureData"
        />
      )}
    </div>
  );
};

KaseyaTicketTableActions.propTypes = {
  record: PropTypes.shape({
    ticketNumber: PropTypes.string.isRequired,
    crmDefaultId: PropTypes.number.isRequired,
    contactId: PropTypes.number,
    requester: PropTypes.shape({
      userId: PropTypes.string,
    }),
  }),
  crmDefaultId: PropTypes.number,
  crmId: PropTypes.number,
};
export default KaseyaTicketTableActions;
