import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { enqueueSnackbar } from 'notistack';

import { TEXT_DELETE_SUCESS } from '@constants/texts/common';

import { ReactComponent as EditIcon } from '@assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/delete.svg';

import ConfirmDialog from '@ui/components/common/ConfirmDialog/ConfirmDialog';
import EditTypeValueDialog from './dialogs/EditTypeValueDialog';
import ColorCircle from './subComponents/ColorCircle';

import { deleteTypeValue } from '../helpers';

import useStyles from './styles';

const TypeValuesItem = React.memo(
  ({
    name,
    circleBgColor,
    circleBorderColor,
    icon,
    backgroundColor,
    fontColor,
    values,
    onEdit,
    onDelete,
    ...rest
  }) => {
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const classes = useStyles({ backgroundColor, fontColor });

    const valuesArray = useMemo(() => values.split(','), [values]);

    const handleDeleteClick = useCallback(
      id => {
        setDeleteLoading(true);
        deleteTypeValue(id)
          .then(() => {
            onDelete({ id });
            enqueueSnackbar(TEXT_DELETE_SUCESS, { variant: 'success' });
            setOpenDeleteDialog(false);
          })
          .catch(err => {
            enqueueSnackbar(err.message, { variant: 'error' });
          })
          .finally(() => {
            setDeleteLoading(false);
          });
      },
      [onDelete],
    );

    return (
      <div className={classes.typeValuesItemContainer}>
        <div className={classes.typeValuesLabelsContainer}>
          {circleBgColor && circleBorderColor && (
            <ColorCircle
              circleBgColor={circleBgColor}
              circleBorderColor={circleBorderColor}
            />
          )}
          <div className={classes.typeValuesItemName}>{name}</div>
        </div>
        <div className={classes.typeValuesItemValues}>
          {valuesArray[0] !== '' ? (
            valuesArray.map((value, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${value}${index}`}>{value}</div>
            ))
          ) : (
            <></>
          )}
        </div>

        <div className={classes.typeValuesItemActions}>
          <Tooltip title="Edit">
            <EditIcon
              onClick={() => {
                setOpenEditDialog(true);
              }}
            />
          </Tooltip>

          <Tooltip title="Delete">
            <DeleteIcon
              onClick={() => {
                setOpenDeleteDialog(true);
              }}
            />
          </Tooltip>
        </div>

        {openEditDialog && (
          <EditTypeValueDialog
            open={openEditDialog}
            onClose={() => {
              setOpenEditDialog(false);
            }}
            onSubmit={item => {
              setOpenEditDialog(false);
              onEdit(item);
            }}
            item={{
              name,
              circleBgColor,
              circleBorderColor,
              icon,
              backgroundColor,
              fontColor,
              values,
              ...rest,
            }}
          />
        )}

        {openDeleteDialog && (
          <ConfirmDialog
            open={openDeleteDialog}
            setOpen={setOpenDeleteDialog}
            onCancel={() => {
              setOpenDeleteDialog(false);
            }}
            loading={deleteLoading}
            onAccept={() => {
              handleDeleteClick(rest.id);
            }}
            title="Confirm delete action"
            content="Are you sure you want to delete this item?"
          />
        )}
      </div>
    );
  },
);

TypeValuesItem.propTypes = {
  name: PropTypes.string,
  circleBorderColor: PropTypes.string,
  circleBgColor: PropTypes.string,
  icon: PropTypes.string,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  values: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default TypeValuesItem;
