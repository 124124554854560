import { createAsyncThunk } from '@reduxjs/toolkit';
import { OUTGOING_MAIL } from '@store/slices/resources';
import { getOutboundMailboxUsers } from '@services/requests/outGoingMail';

export const ThunkGetOutgoingMailboxUsers = createAsyncThunk(
  `${OUTGOING_MAIL}/getOutGoingMailboxUsers`,
  () => getOutboundMailboxUsers(),
);

export default {};
