import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMicrosoft365IntegrationCompanies } from '@services/requests/integration';
import { INTEGRATION } from '@store/slices/resources';

export const ThunkGetMicrosoft365CompaniesCompanies = createAsyncThunk(
  `${INTEGRATION}/getMicrosoft365Companies`,
  async ({ crmId }) => {
    const companies = await getMicrosoft365IntegrationCompanies(crmId);
    return companies;
  },
);

export default {};
