import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '10px',
    '& > div': {
      width: '32.9%',
    },
  },
}));

export default useStyles;
