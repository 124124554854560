import { createAsyncThunk } from '@reduxjs/toolkit';
import { OUTGOING_MAIL } from '@store/slices/resources';
import { updateOutboundMailboxResult } from '@services/requests/outGoingMail';

export const ThunkUpdateOutgoingMailbox = createAsyncThunk(
  `${OUTGOING_MAIL}/updateOutGoingMailbox`,
  payload => updateOutboundMailboxResult(payload),
);

export default {};
