import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ThunkDeleteSmsCampaignGroupItems } from '@store/slices/smsCampaigns/thunks/deleteSmsCampaignGroupItem';
import { ThunkGetSmsCampaignGroups } from '@store/slices/smsCampaigns/thunks/getSmsCampaignGroups';
import { ThunkDeleteSmsCampaignGroup } from '@store/slices/smsCampaigns/thunks/deleteSmsCampaignGroup';
import {
  deleteSmsCampaignGroup,
  SmsCampaignGroupsAutocompleteSelector,
} from '@store/slices/smsCampaigns';

import { actions } from '@store/actions';
import { SMS_GROUP_IMPORT_FROM_PSA } from '@constants/routes';

import AddIcon from '@assets/icons/add_blue.svg';
import DeleteIcon from '@assets/icons/delete.svg';
import EditIcon from '@assets/icons/edit.svg';

import Loading from '@common/Loading/Loading';
import DropDown from '@components/Auth/Common/DropDown';
import EditItemDialog from '@ui/pages/smsCampaigns/SmsGroups/dialogs/EditItemDialog';
import CreateSmsCampaignGroupDialog from '@ui/pages/smsCampaigns/SmsGroups/dialogs/CreateSmsCampaignGroupDialog';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import ReusableButton from '@common/Button/Button';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import { TableTypes } from '@components/Table/constants';
import Table from '@components/Table';
import SmsCampaignManualUsers from './dialogs/SmsCampaignManualUsers';
import BulkActions from './components/BulkActions';

import useStyles from './styles';

const SmsGroups = () => {
  const [loading, setLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(
    localStorage.getItem('selectedSMSGroup') ?? undefined,
  );
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editedItem, setEditedItem] = useState(null);
  const [deletedItem, setDeletedItem] = useState(null);
  const [openAddManually, setOpenAddManually] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const groups = useSelector(SmsCampaignGroupsAutocompleteSelector);
  const { push } = useHistory();

  const columns = [
    {
      name: 'ID',
      key: 'id',
    },
    {
      name: 'Full Name',
      key: 'fullName',
      sortable: true,
      searchable: true,
    },
    {
      name: 'Phone number',
      key: 'phoneNumber',
      sortable: true,
      searchable: true,
      type: TableTypes.phoneNumber,
    },
    {
      name: 'Actions',
      key: TableTypes.actions,
      type: TableTypes.actions,
      accessor: item => (
        <div className={classes.actionsContainer}>
          <ActionFieldItem
            icon={EditIcon}
            alt="edit"
            handler={() => setEditedItem(item)}
            toolTip="Edit"
          />
          <ActionFieldItem
            icon={DeleteIcon}
            alt="delete"
            handler={() => setDeletedItem(item)}
            toolTip="Remove"
          />
        </div>
      ),
    },
  ];

  const handleAdd = () => setCreateDialogOpen(true);
  const handleDelete = () => setDeleteDialogOpen(true);

  const onDeleteConfirmed = () => {
    dispatch(ThunkDeleteSmsCampaignGroup(selectedGroup))
      .unwrap()
      .then(() => {
        dispatch(deleteSmsCampaignGroup(selectedGroup));
        setDeleteDialogOpen(false);
        setSelectedGroup(null);
      });
  };

  const onDeleteItemConfirmed = () => {
    dispatch(ThunkDeleteSmsCampaignGroupItems([deletedItem.id]))
      .unwrap()
      .then(() => {
        dispatch(actions.removeItemFromList({ id: deletedItem.id }));
        setDeletedItem(null);
      });
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    await dispatch(ThunkGetSmsCampaignGroups());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (selectedGroup) {
      localStorage.setItem('selectedSMSGroup', selectedGroup);
    }
  }, [selectedGroup]);

  if (loading) return <Loading />;
  return (
    <div className={classes.container}>
      <div className={classes.tableActions}>
        <div className={classes.tableActionContainer}>
          <DropDown
            options={groups}
            labelName="name"
            valueName="id"
            input={{
              onChange: e => {
                setSelectedGroup(e.target.value);
              },
              value: selectedGroup,
            }}
          />
          <div className={classes.groupActionButtons}>
            <ActionFieldItem handler={handleAdd} icon={AddIcon} toolTip="Add" />
            {selectedGroup && (
              <ActionFieldItem
                handler={handleDelete}
                icon={DeleteIcon}
                toolTip="Delete"
                disabled={!selectedGroup}
              />
            )}
          </div>
        </div>
        <div className={classes.actionButtons}>
          <ReusableButton
            label="Add From PSA Company"
            onClick={() => {
              push(
                SMS_GROUP_IMPORT_FROM_PSA.replace(':smsGroupId', selectedGroup),
              );
            }}
            disabled={!selectedGroup}
          />
          <ReusableButton
            label="Add Users Manually"
            onClick={() => {
              setOpenAddManually(true);
            }}
            disabled={!selectedGroup}
          />
        </div>
      </div>

      {selectedGroup && (
        <div className={classes.tableContainer}>
          <Table
            actions={null}
            columns={columns}
            bulkActions={<BulkActions />}
            resource={`SmsCampaignGroup/${selectedGroup}/items`}
          />
        </div>
      )}
      <CreateSmsCampaignGroupDialog
        open={createDialogOpen}
        setOpen={setCreateDialogOpen}
        onSubmit={d => setSelectedGroup(d.id)}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={onDeleteConfirmed}
        onCancel={() => setDeleteDialogOpen(false)}
        title="Delete current SMS Campaign Group"
        content="Warning! This operation will delete current group and cannot be undone"
      />
      <EditItemDialog open={editedItem} setOpen={setEditedItem} />
      <ConfirmDialog
        open={!!deletedItem}
        setOpen={setDeletedItem}
        onAccept={onDeleteItemConfirmed}
        onCancel={() => setDeletedItem(null)}
        title="Delete current SMS Campaign Group Item"
        content="Warning! This operation will delete current record and cannot be undone"
      />

      {openAddManually && (
        <SmsCampaignManualUsers
          open
          smsCampaignGroupId={selectedGroup}
          onClose={() => {
            setOpenAddManually(false);
          }}
        />
      )}
    </div>
  );
};

export default SmsGroups;
