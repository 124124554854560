import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BooleanField, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import cx from 'classnames';
import moment from 'moment';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import { enqueueSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom';

import ReusableButton from '@common/Button/Button';
import MuiVirtualizedTable from '@common/MuiVirtualizedTable/MuiVirtualizedTable';
import ChipField from '@common/Chip/ChipField';
import FormWrapper from '@components/DataSources/HookTemplates/components/FormWrapper';
import Input from '@common/Input/Input';
import FieldWrapper from '@common/form/FieldWrapper';
import {
  createTemplateData,
  deleteTemplateData,
  getTemplateData,
  updateTemplateData,
} from '@components/DataSources/helpers';
import { actions } from '@store/actions';
import CreateWizard from '@components/DataSources/HookTemplates/CreateWizard/CreateWizard';
import { listSelector } from '@store/selectors';
import DateTimeField from '@common/TableComponents/DateTimeField';
import ActionField from './components/ActionField';
import useStyles from './styles';

const HookTemplates = () => {
  const resource = 'merakiAlertTemplates';
  const classes = useStyles();
  const translate = useTranslate();
  const list = useSelector(listSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const { push } = useHistory();
  const params = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(null);
  const [data, setData] = useState(null);
  const [wizardOpen, setWizardOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(0);

  const getLabel = useCallback(
    (src, prefix = 'fields') =>
      translate(`resources.alertTemplates.${prefix}.${src}`),
    [translate],
  );

  const onNameChange = event => {
    setName(event.target.value);
    setNameError(null);
  };

  const create = payload => {
    createTemplateData(payload)
      .then(d => {
        dispatch(actions.updateItemInList(d));
        setName('');
        setData(null);
        setFormOpen(false);
        enqueueSnackbar('Template created', { variant: 'success' });
      })
      .catch(e =>
        enqueueSnackbar(`${e.message}: ${e.exception}`, { variant: 'error' }),
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const update = (payload, id) => {
    updateTemplateData(id, payload)
      .then(d => {
        dispatch(actions.updateItemInList(d));
        setName('');
        setData(null);
        setFormOpen(false);
        enqueueSnackbar('Template created', { variant: 'success' });
      })
      .catch(e => {
        enqueueSnackbar(`${e.message}: ${e.exception}`, { variant: 'error' });
      })
      .finally(() => setLoading(false));
  };

  const onSubmit = (values, id) => {
    if (!name) {
      setNameError('required');
      return;
    }
    setLoading(true);
    const payload = {
      id: id || 0,
      name,
      ...values,
    };
    if (id) {
      update(payload, id);
    } else {
      create(payload);
    }
  };

  const onDelete = useCallback(
    id => {
      deleteTemplateData(id)
        .then(() => {
          dispatch(actions.removeItemFromList({ id }));
          enqueueSnackbar('Template erased', { variant: 'success' });
        })
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
    },
    [dispatch],
  );

  const onEdit = useCallback(id => {
    setLoading(true);
    getTemplateData(id)
      .then(d => {
        setName(d.name);
        setData(d);
        setFormOpen(true);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setLoading(false));
  }, []);

  const onApplyTemplate = id => {
    setSelectedTemplateId(+id);
    setWizardOpen(true);
  };

  const onWizardClose = () => {
    setSelectedTemplateId(0);
    setWizardOpen(false);
  };

  const onCreate = () => {
    setName('');
    setNameError(null);
    setData(null);
    setFormOpen(true);
  };

  const onApply = (templateId, count) => {
    if (count) {
      const newList = list.map(obj => {
        if (+obj.id === templateId) {
          return {
            ...obj,
            lastApplyDateTime: moment.utc(),
            wasChangedAfterApply: false,
          };
        }
        return obj;
      });
      dispatch(actions.saveList(newList));
    }
  };

  useEffect(() => {
    const id = +params.get('id'); // convert to number
    if (params.get('id')) {
      push(location.pathname);
      onEdit(id);
    }
  }, [params]);

  const columns = useMemo(
    () => [
      {
        name: getLabel('name'),
        key: 'name',
      },
      {
        name: getLabel('allAdmins'),
        key: 'allAdmins',
        accessor: rowData => (
          <div className={classes.booleanFieldContainer}>
            <BooleanField
              record={rowData}
              source="allAdmins"
              label={getLabel('allAdmins')}
            />
          </div>
        ),
        width: 70,
      },
      {
        name: getLabel('snmp'),
        key: 'snmp',
        accessor: rowData => (
          <div className={classes.booleanFieldContainer}>
            <BooleanField
              record={rowData}
              source="snmp"
              label={getLabel('snmp')}
            />
          </div>
        ),
        width: 70,
      },
      {
        name: getLabel('emailsTemplateBehaviour'),
        key: 'emailsTemplateBehaviour',
        width: 100,
      },
      {
        name: getLabel('hooksTemplateBehaviour'),
        key: 'hooksTemplateBehaviour',
        width: 100,
      },
      {
        name: getLabel('emails'),
        key: 'emails',
        accessor: rowData => (
          <ChipField
            record={rowData}
            source="emails"
            label={getLabel('emails')}
          />
        ),
        cropCell: true,
      },
      {
        name: getLabel('httpServerIds'),
        key: 'httpServerIds',
        accessor: rowData => (
          <ChipField
            record={rowData}
            source="httpServerIds"
            label={getLabel('httpServerIds')}
          />
        ),
        cropCell: true,
      },
      {
        name: getLabel('lastApplyDateTime'),
        key: 'lastApplyDateTime',
        sort: 'lastApplyDateTime',
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="lastApplyDateTime"
            source="lastApplyDateTime"
            label={getLabel('lastApplyDateTime')}
            sortable
          />
        ),
        width: 165,
      },
      {
        name: getLabel('lastApplyUser'),
        key: 'lastApplyUser',
        width: 165,
      },
      {
        name: getLabel('wasChangedAfterApply'),
        key: 'wasChangedAfterApply',
        accessor: rowData => (
          <div className={classes.booleanFieldContainer}>
            {rowData.wasChangedAfterApply && (
              <BooleanField
                record={rowData}
                source="wasChangedAfterApply"
                label={getLabel('wasChangedAfterApply')}
              />
            )}
          </div>
        ),
        width: 165,
      },
      {
        name: 'Actions',
        key: 'actions',
        accessor: rowData => (
          <ActionField
            label="Actions"
            record={rowData}
            onEdit={onEdit}
            onDelete={onDelete}
            onApply={onApplyTemplate}
          />
        ),
        width: 200,
      },
    ],
    [classes.booleanFieldContainer, getLabel, onDelete, onEdit],
  );

  return (
    <div className={classes.tableWrapper}>
      <ReusableButton
        size="md"
        viewType="black"
        label="common.add"
        onClick={onCreate}
        classNameWrapper={classes.buttonWrapper}
        disabled={loading}
      />
      <MuiVirtualizedTable
        actions={null}
        columns={columns}
        resource={resource}
      />
      <Modal open={formOpen} onClose={() => setFormOpen(false)}>
        <div className={classes.modalBox}>
          <Paper className={classes.smallPadding}>
            <Paper>
              <Form onSubmit={() => {}}>
                {() => {
                  return (
                    <FieldWrapper
                      label="Template name"
                      contentSize={8}
                      labelSize={2}
                      content={
                        <Field
                          id="templateName"
                          name="name"
                          disabled={false}
                          styleType="main"
                          inputView="text"
                          fullWidth
                          meta={{ touched: true, error: nameError }}
                          input={{
                            value: name,
                            onChange: onNameChange,
                          }}
                          component={Input}
                        />
                      }
                    />
                  );
                }}
              </Form>
            </Paper>
            <FormWrapper
              onCancel={() => setFormOpen(false)}
              onSubmit={onSubmit}
              data={data}
            />
          </Paper>
        </div>
      </Modal>
      <Modal open={wizardOpen} onClose={onWizardClose}>
        <div className={cx(classes.modalBox, classes.wizardModalBox)}>
          <CreateWizard
            templateId={selectedTemplateId}
            onClose={onWizardClose}
            onApply={onApply}
          />
        </div>
      </Modal>
    </div>
  );
};

export default HookTemplates;
