import React, { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import useTranslation from '@common/hooks/useTranslation';
import copyText from '@utils/copy';
import { actions } from '@store/actions';
import ReusableButton from '@ui/components/common/Button/Button';
import ActionField from '@common/ActionFields/ActionField';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import DateTimeField from '@common/TableComponents/DateTimeField';
import useAsyncDispatch from '@services/api/common/useAsyncDispatch';
import { getTenants } from '@components/Billing/SmsLogs/helpers';
import { SHARED_BILLING } from '@constants/routes';
import { tenantOptions } from '@store/selectors/smsList';
import Table from '@components/Table';
import EllipsisTooltip from '@common/EllipsisTooltip';
import { sortOrders } from '@constants/filters';
import { TableTypes } from '@components/Table/constants';
import CreateDialog from './CreateDialog';
import { createLink } from './helpers';

import useStyles from './styles';

const getFullLink = key =>
  `${window.location.origin}/#${SHARED_BILLING}?auth-key=${key}`;

const BillingLinks = ({ resource }) => {
  const classes = useStyles();
  const { getText } = useTranslation(resource);
  const options = useSelector(tenantOptions);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  useAsyncDispatch(getTenants, actions.smsList_setOptions);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const onCopy = key => {
    copyText(getFullLink(key));
  };

  const onDelete = record => {
    setIdForDelete(record.id);
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setIdForDelete(null);
    setDeleteDialogOpen(false);
    setLoading(false);
  };

  const handleDelete = () => {
    setLoading(true);
    dataProvider
      .delete(resource, { id: idForDelete })
      .then(() => {
        dispatch(actions.removeItemFromList({ id: idForDelete }));
      })
      .catch(e => notify(e.message, 'error'))
      .finally(handleCancelDelete);
  };

  const handleAddKey = model => {
    setCreateDialogOpen(false);
    setLoading(true);
    createLink(model)
      .then(d => {
        const item = {
          ...d,
          tenantName: options.find(o => o.id === d.tenantId).value,
        };
        dispatch(actions.updateItemInList(item));
      })
      .catch(e => notify(e.message, 'error'))
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.table}>
      <ReusableButton
        size="md"
        label="common.add"
        onClick={() => setCreateDialogOpen(true)}
        disabled={loading}
        classNameWrapper={classes.button}
      />
      <Table
        actions={null}
        columns={[
          {
            name: 'Name',
            key: 'name',
            sortable: true,
          },
          {
            name: 'Link',
            key: 'link',
            minWidth: 300,
            isCustomAccessor: true,
            accessor: rowData => (
              <EllipsisTooltip text={getFullLink(rowData.link)} />
            ),
          },
          {
            name: 'Tenant Name',
            key: 'tenantName',
          },
          {
            name: 'Expiration date',
            key: 'expirationDateUtc',
            isCustomAccessor: true,
            accessor: rowData => (
              <DateTimeField
                record={rowData}
                key="expirationDateUtc"
                source="expirationDateUtc"
                highlightExpired
              />
            ),
          },
          {
            name: 'Actions',
            key: TableTypes.actions,
            type: TableTypes.actions,
            accessor: rowData => (
              <ActionField
                label="Actions"
                record={rowData}
                onCopy={onCopy}
                onDelete={onDelete}
                fieldKey="link"
              />
            ),
            width: 100,
          },
        ]}
        resource={resource}
        defaultSort={{
          fieldName: 'name',
          order: sortOrders.asc,
        }}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDelete}
        onCancel={handleCancelDelete}
        title={getText('caption')}
        content={getText('text')}
        loading={loading}
      />
      <CreateDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onCreate={handleAddKey}
      />
    </div>
  );
};

BillingLinks.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default BillingLinks;
