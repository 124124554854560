import React, { useState } from 'react';

import ReusableButton from '@common/Button/Button';
import AddBlackListNumberDialog from '@components/Settings/SmsBlackList/dialogs/AddBlackListNumberDialog';

import useStyles from '../styles';

const SmsBlackListTableActions = () => {
  const classes = useStyles();
  const [openAddNumberDialog, setOpenAddNumberDialog] = useState(false);

  return (
    <div className={classes.addButton}>
      <ReusableButton
        label="Add"
        onClick={() => {
          setOpenAddNumberDialog(true);
        }}
      />

      {openAddNumberDialog && (
        <AddBlackListNumberDialog open setOpen={setOpenAddNumberDialog} />
      )}
    </div>
  );
};

export default SmsBlackListTableActions;
