import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import HelpBox from '@common/HelpBox/HelpBox';
import help from '@constants/help';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconCopy from '@assets/icons/copy.svg';
import { ThunkGetHaloPodLink } from '@store/slices/psaPods/thunks';
import copyText from '@utils/copy';

import useStyles from './styles';

const HaloPodLink = ({ crmId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const copyPodLink = () => {
    dispatch(ThunkGetHaloPodLink({ crmId, useNewPodLink: true }))
      .unwrap()
      .then(d => {
        copyText(d.data);
      });
  };

  return (
    <div className={classes.integrationBox}>
      <span>ServiceDesk Pod integration</span>
      <HelpBox resource={help.integrationHaloCreatePod} />

      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div>Get Link to Pod</div>
        <ActionButton
          icon={<img src={iconCopy} alt="iconCopy" />}
          handler={copyPodLink}
          toolTip="Copy URL"
        />
      </div>
    </div>
  );
};

HaloPodLink.propTypes = {
  crmId: PropTypes.number.isRequired,
};

export default HaloPodLink;
