import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  center: {
    width: '100%',
    textAlign: 'center',
  },
  button: {
    marginBottom: '8px',
  },
}));

export default useStyles;
