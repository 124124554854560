import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { enqueueSnackbar } from 'notistack';

import { TEXT_UPDATE_SUCESS } from '@constants/texts/common';
import { requiredValidator } from '@utils/validators';
import {
  ThunkEditResourceAssignedTemplates,
  ThunkGetResourceAssignedTemplateOptions,
  ThunkGetResourceAssignedTemplates,
} from '@store/slices/messaging/thunks';
import Loading from '@common/Loading/Loading';

import FieldWrapper from '@common/form/FieldWrapper';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconPlus from '@assets/icons/plusGrey.svg';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';

import useStyles from './styles';

const ResourcesForm = ({ onSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState(null);
  const [templateVariables, setTemplateVariables] = useState([]);

  const submit = values => {
    setProcessing(true);
    dispatch(ThunkEditResourceAssignedTemplates(values))
      .unwrap()
      .then(() => enqueueSnackbar(TEXT_UPDATE_SUCESS, { variant: 'success' }))
      .finally(() => {
        setProcessing(false);
        onSubmit();
      });
  };

  const validate = values => {
    return {
      notificationMessage: requiredValidator(values.notificationMessage),
    };
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    await Promise.all([
      new Promise(res =>
        res(dispatch(ThunkGetResourceAssignedTemplates()).unwrap()),
      ),
      new Promise(res =>
        res(dispatch(ThunkGetResourceAssignedTemplateOptions()).unwrap()),
      ),
    ])
      .then(res => {
        setData(res[0]);
        setTemplateVariables(res[1]);
      })
      .finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;

  return (
    <div className={classes.container}>
      <Form
        onSubmit={submit}
        validate={validate}
        initialValues={data}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <FieldWrapper
              label="Notification SMS message "
              labelSize={4}
              contentSize={8}
              showLabel
              isRequired
              content={
                <div className={classes.dialogTemplate}>
                  <ActionButton
                    handler={event => setAnchorEl(event.currentTarget)}
                    icon={<img src={iconPlus} alt="iconPlus" />}
                    toolTip="Add template variable"
                    disabled={processing}
                  />
                  <Field
                    name="notificationMessage"
                    id="notificationMessage"
                    render={Input}
                    multiline
                    minRows={3}
                    maxRows={5}
                    disabled={processing}
                  />
                </div>
              }
            />

            <div className={classes.dialogActions}>
              <ReusableButton
                size="lg"
                label="Save Templates"
                type="submit"
                loading={processing}
                disabled={processing}
              />
            </div>
            <TemplateMenu
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              form={form}
              name="notificationMessage"
              templateVariables={templateVariables}
            />
          </form>
        )}
      />
    </div>
  );
};

ResourcesForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ResourcesForm;
