import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { useAuthProvider, usePermissions } from 'react-admin';
import { enqueueSnackbar } from 'notistack';

import { TEXT_DELETE_SUCESS } from '@constants/texts/common';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import copyText from '@utils/copy';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import { basePermissions } from '@constants/permissions';
import iconVisible from '@assets/icons/visibility.svg';
import iconDelete from '@assets/icons/delete.svg';
import iconUser from '@assets/icons/user.svg';
import iconCopy from '@assets/icons/copy.svg';
import iconShield from '@assets/icons/shield.svg';
import iconShieldDone from '@assets/icons/shield_done.svg';
import iconTicket from '@assets/icons/ticket_active.svg';
import {
  addAssignee,
  deletePolicy,
  toggleAcknowledge,
} from '@components/Ticketing/Integrations/helpers';
import TicketsListDialog from '@components/Audit/TicketsList/TicketsListDialog';
import ackTypes from '@constants/ackTypes';
import AssignDialog from './dialogs/AssignDialog';
import AckDialog from './dialogs/AckDialog';
import useStyles from './actionFieldsStyles';

const Tooltip = ({ record, users }) => {
  const classes = useStyles();
  const userEmail = users.find(item => item.name === record.assignment)?.email;

  return (
    <Box className={classes.hintBlock}>
      <Typography component="span" className={classes.hintArrow} />
      <Typography className={classes.hintTitle}>Assign to:&nbsp;</Typography>
      <Box className={classes.hintUserInfo}>
        <Typography className={classes.hintEmail}>{userEmail}</Typography>
        <ActionButton
          icon={<img src={iconCopy} alt="iconCopy" />}
          handler={() => copyText(userEmail)}
          toolTip="Copy email"
        />
      </Box>
    </Box>
  );
};

const ActionFields = ({
  record = {},
  handleUpdateClick,
  handleRefresh,
  users,
  dataViewId,
}) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const resourcePermissions = permissions ? permissions.data : {};

  const authProvider = useAuthProvider();
  const list = useSelector(listSelector);

  const dispatch = useDispatch();

  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [ackDialogOpen, setAckDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [ticketsPopupOpen, setTicketsPopupOpen] = useState(false);

  const acknowledgeIcon = record?.acknowledge
    ? {
        icon: iconShieldDone,
        text: 'iconShieldDone',
      }
    : {
        icon: iconShield,
        text: 'iconShield',
      };

  const handleAttachmentAction = (key, id, activate = false) => {
    const userName = activate ? users.find(item => item.id === id)?.name : null;
    return list.map(item =>
      record.id === item.id ? { ...item, [key]: userName } : item,
    );
  };

  const handleAddAcknowledgeSelectValue = async value => {
    const activate = !record.acknowledge;
    const payload = {
      activate,
      policyIds: [record.id],
      ackType: value,
    };
    try {
      await toggleAcknowledge(payload);
      const newList = handleAttachmentAction(
        'acknowledge',
        authProvider.getUserId(),
        activate,
      );
      dispatch(actions.saveList(newList));
      setAckDialogOpen(false);
      enqueueSnackbar('Acknowledged', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const handleAcknowledgeClick = async () => {
    if (!record.acknowledge) {
      setAckDialogOpen(true);
    } else {
      await handleAddAcknowledgeSelectValue(ackTypes.resetAck);
    }
  };

  const handleAssignDialogSelectValue = async value => {
    try {
      await addAssignee(value, [record.id], dataViewId);
      const newList = handleAttachmentAction('assignment', value);
      dispatch(actions.saveList(newList));
      setAssignDialogOpen(false);
      enqueueSnackbar();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const handleOpenUserAssigment = () => {
    setAssignDialogOpen(true);
  };

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteAlert = () => {
    setDeleteDialogOpen(false);
    deletePolicy(record.id)
      .then(() => {
        const newList = list.filter(item => item.id !== record.id);
        dispatch(actions.saveList(newList));
        enqueueSnackbar(TEXT_DELETE_SUCESS, { variant: 'success' });
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
    handleRefresh();
  };

  return (
    <div className={classes.container}>
      <ActionButton
        icon={<img src={iconTicket} alt="iconTicket" />}
        handler={() => setTicketsPopupOpen(true)}
        toolTip="Tickets"
        disabled={!record?._tickets_ids}
      />
      <ActionButton
        icon={<img src={acknowledgeIcon.icon} alt={acknowledgeIcon.text} />}
        handler={handleAcknowledgeClick}
        toolTip="Acknowledge"
      />
      <ActionButton
        icon={<img src={iconUser} alt="iconUser" />}
        handler={handleOpenUserAssigment}
        toolTip="Assign to"
      />
      <ActionButton
        icon={<img src={iconVisible} alt="iconVisible" />}
        handler={() => handleUpdateClick(record)}
        toolTip="Display details"
      />
      {resourcePermissions[basePermissions.delete] && (
        <ActionButton
          icon={<img src={iconDelete} alt="iconDelete" />}
          handler={handleOpenDeleteDialog}
        />
      )}
      <AssignDialog
        open={assignDialogOpen}
        onSelect={handleAssignDialogSelectValue}
        onClose={() => setAssignDialogOpen(false)}
        users={users}
      />
      <AckDialog
        open={ackDialogOpen}
        onSelect={handleAddAcknowledgeSelectValue}
        onClose={() => setAckDialogOpen(false)}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDeleteAlert}
        onCancel={() => setDeleteDialogOpen(false)}
        title="Delete current alert"
        content="Warning! This operation will delete current alert and cannot be undone"
      />
      <TicketsListDialog
        isOpen={ticketsPopupOpen}
        onClose={() => setTicketsPopupOpen(false)}
        ticketIds={record?._tickets_ids}
      />
    </div>
  );
};

ActionFields.propTypes = {
  record: PropTypes.objectOf(PropTypes.any),
  handleUpdateClick: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.any),
};

Tooltip.propTypes = {
  record: PropTypes.shape({
    assignment: PropTypes.string,
  }),
  users: PropTypes.arrayOf(PropTypes.any),
};

export default ActionFields;
