import React, { useCallback, useMemo, useState } from 'react';
import { BooleanField, useDataProvider, useRedirect } from 'react-admin';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

import { TEXT_DELETE_SUCESS } from '@constants/texts/common';
import { actions } from '@store/actions';
import resources from '@constants/resources';
import { sortOrders } from '@constants/filters';
import {
  CREATE_ESCALATION_RULES,
  EDIT_ESCALATION_RULES,
} from '@constants/routes';

import ListActions from '@common/buttons/ListActionButtons/ListActions';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import useTranslatedText from '@common/hooks/useTranslatedText';
import ActionField from '@common/ActionFields/ActionField';
import StatusCircle from '@common/StatusCircle/StatusCircle';
import { TableTypes } from '@components/Table/constants';
import Table from '@components/Table';
import { cloneEntity, toggleIsActive } from './helpers';

import useStyles from './styles';

const resource = resources.escalationRules;

const EscalationRules = () => {
  const classes = useStyles();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();

  const { getFieldText, getText } = useTranslatedText(resource);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState(null);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const handleDelete = () => {
    setDeleteInProgress(true);
    dataProvider
      .delete(resources.escalationRules, { id: deletedItemId })
      .then(() => {
        dispatch(actions.removeItemFromList({ id: deletedItemId }));
        setDeletedItemId(null);
        enqueueSnackbar(TEXT_DELETE_SUCESS, { variant: 'success' });
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => {
        setDeleteDialogOpen(false);
        setDeleteInProgress(false);
      });
  };

  const handleClone = item => {
    cloneEntity(item.id)
      .then(data => dispatch(actions.updateItemInList(data)))
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
  };

  const handleMakeEnabled = useCallback(
    (id, isActive) => {
      toggleIsActive(id, !isActive)
        .then(() => {
          dispatch(
            actions.patchListItem({
              id,
              field: 'isActive',
              value: !isActive,
            }),
          );
        })
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        key: 'name',
        sortable: true,
        searchable: true,
      },
      {
        name: getFieldText('isActive'),
        key: 'isActive',
        isCustomAccessor: true,
        labelAlignment: 'center',
        width: 100,
        accessor: row => (
          <StatusCircle
            handleClick={handleMakeEnabled}
            row={row}
            rowKey="isActive"
          />
        ),
      },
      {
        name: getFieldText('smsEnabled'),
        key: 'sms.isEnabled',
        labelAlignment: 'center',
        isCustomAccessor: true,
        accessor: rowData => (
          <div className={classes.activeFieldContainer}>
            <BooleanField
              record={rowData}
              source="sms.isEnabled"
              label={getFieldText('smsEnabled')}
            />
          </div>
        ),
        width: 100,
      },
      {
        name: getFieldText('emailEnabled'),
        key: 'email.isEnabled',
        isCustomAccessor: true,
        accessor: rowData => (
          <div className={classes.activeFieldContainer}>
            <BooleanField
              record={rowData}
              source="email.isEnabled"
              label={getFieldText('emailEnabled')}
            />
          </div>
        ),
        minWidth: 200,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        accessor: rowData => (
          <ActionField
            record={rowData}
            onEdit={() =>
              redirect(EDIT_ESCALATION_RULES.replace(':id', rowData.id))
            }
            onDelete={item => {
              setDeletedItemId(item.id);
              setDeleteDialogOpen(true);
            }}
            onClone={handleClone}
          />
        ),
        width: 150,
      },
    ],
    [
      classes.activeFieldContainer,
      getFieldText,
      handleClone,
      handleMakeEnabled,
      redirect,
    ],
  );

  return (
    <div className={classes.container}>
      <ListActions onCreate={() => redirect(CREATE_ESCALATION_RULES)} />
      <Table
        columns={columns}
        resource={resource}
        defaultSort={{
          fieldName: 'name',
          order: sortOrders.asc,
        }}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDelete}
        onCancel={() => setDeleteDialogOpen(false)}
        title={getText('deleteCaption')}
        content={getText('deleteText')}
        loading={deleteInProgress}
      />
    </div>
  );
};

export default EscalationRules;
