import PropTypes from 'prop-types';

export const TypeMeta = PropTypes.shape({
  touched: PropTypes.bool,
  error: PropTypes.string,
  submitError: PropTypes.string,
  dirtySinceLastSubmit: PropTypes.bool,
  valid: PropTypes.bool,
  pristine: PropTypes.bool,
});

export const TypeInput = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string,
});

export const TypeOptions = PropTypes.shape({
  [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
});

export const TypeLabelValue = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
});

export const TypeUserData = PropTypes.shape({
  username: PropTypes.string,
  imageUri: PropTypes.string,
});

export const TypeSize = PropTypes.oneOf(['small', 'medium', 'large']);
export default {};
