import React from 'react';
import { useHistory } from 'react-router-dom';
import MaterialAvatar from '@material-ui/core/Avatar';
import { getUserImageFromStorage } from '@services/api';
import { PROFILE_ROUTE } from '@constants/routes';

import { Tooltip } from '@material-ui/core';
import { TypeUserData } from '@common/propTypes/common';

import useStyles from './styles';

const Avatar = ({ userData }) => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <Tooltip title="Profile">
      <MaterialAvatar
        className={classes.avatar}
        onClick={() => history.push(PROFILE_ROUTE)}
        src={userData.imageUri || getUserImageFromStorage()}
      />
    </Tooltip>
  );
};

Avatar.propTypes = {
  userData: TypeUserData,
};

export default Avatar;
