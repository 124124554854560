import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { OnChange } from 'react-final-form-listeners';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { ThunkUpdatePricePlan } from '@store/slices/billing/thunks';
import { ThunkCheckDealerCode } from '@store/slices/auth/thunks';

import {
  BillingDataSelector,
  BillingEmailSelector,
  DiscountDataSelector,
  MinTechCountSelectorSelector,
  PricePlansSelector,
  TechCountSelector,
} from '@store/slices/billing';

import { BILLING } from '@constants/routes';
import { requiredValidator } from '@utils/validators';

import ReusableButton from '@common/Button/Button';
import PricePlansList from '@ui/pages/billing/EditPricePlan/components/PricePlansList';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ListItem from '@ui/pages/billing/Billing/components/ListItem';
import TechnicianCount from '@components/Auth/Registration/components/TechnicianCount';

import useBillingStyles from '../../Billing/styles';

const PricePlanForm = () => {
  const [processing, setProcessing] = useState(false);
  const [discount, setDiscount] = useState(undefined);

  const billingStyles = useBillingStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const billingEmail = useSelector(BillingEmailSelector);
  const minTechCount = useSelector(MinTechCountSelectorSelector);
  const pricePlans = useSelector(PricePlansSelector);
  const techCount = useSelector(TechCountSelector);
  const discountData = useSelector(DiscountDataSelector);
  const plan = useSelector(BillingDataSelector);

  const checkDealerCode = useCallback(
    form => code => {
      if (code.length === 0) {
        form.change('discountData', '');
      }

      if (code.length > 5) {
        setProcessing(true);
        dispatch(ThunkCheckDealerCode({ code }))
          .unwrap()
          .then(res => {
            form.change('discountData', res);

            if (!res.amount) {
              form.change('discountData', 'Wrong Discount');
            }
          })
          .finally(() => {
            setProcessing(false);
          });
      }
    },
    [dispatch],
  );

  const submit = useCallback(
    ({ techCount: licensesCount, pricingPlan, comment, code: dealerCode }) => {
      setProcessing(true);
      dispatch(
        ThunkUpdatePricePlan({
          planId: pricingPlan,
          licensesCount,
          dealerCode,
          comment,
        }),
      )
        .unwrap()
        .then(() => {
          enqueueSnackbar('Plan Updated', { variant: 'success' });
          push(BILLING);
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [dispatch, push],
  );

  const validate = useCallback(
    values => {
      const oldPlan = pricePlans.find(i => i.id === plan.id);
      const newPlan = pricePlans.find(i => i.id === values.pricingPlan);

      return {
        pricingPlan: requiredValidator(values.pricingPlan),
        comment:
          oldPlan?.basePriceCents > newPlan?.basePriceCents ||
          values.techCount < techCount
            ? requiredValidator(values.comment)
            : undefined,
      };
    },
    [plan.id, pricePlans, techCount],
  );

  const getDowngradeState = useCallback(
    newPricePlan => {
      const oldPlan = pricePlans.find(i => i.id === plan.id);
      const newPlan = pricePlans.find(i => i.id === newPricePlan);

      return oldPlan?.basePriceCents > newPlan?.basePriceCents;
    },
    [plan.id, pricePlans],
  );

  useEffect(() => {
    if (discountData?.code) {
      setProcessing(true);
      dispatch(ThunkCheckDealerCode({ code: discountData?.code }))
        .unwrap()
        .then(res => {
          setDiscount(res);
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  }, [discountData?.code]);

  return (
    <Form
      onSubmit={submit}
      initialValues={{
        techCount,
        pricingPlan: plan.id,
        discountData: discount ?? undefined,
        code: discountData?.code,
      }}
      validate={validate}
      render={({ handleSubmit, form, values }) => (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '10px',
          }}
        >
          <PricePlansList form={form} values={values} />
          <form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              padding: '20px 0px',
            }}
          >
            <div>
              <div className={billingStyles.header}>Subscription Details</div>

              <ListItem text={billingEmail} title="Billing Email:" />
            </div>
            <FieldWrapper
              label="Code"
              labelSize={12}
              contentSize={12}
              fullWidth
              content={
                <Field
                  placeholder="Enter your dealer code"
                  name="code"
                  id="code"
                  render={Input}
                  disabled={processing}
                  meta={{
                    touched: true,
                    error:
                      values.discountData === 'Wrong Discount'
                        ? 'Wrong Discount Code'
                        : undefined,
                  }}
                />
              }
            />
            <OnChange name="code">{checkDealerCode(form)}</OnChange>

            <div>
              <TechnicianCount
                form={form}
                loading={processing}
                values={values}
                pricePlansOptions={pricePlans}
                minTechCount={minTechCount}
                discountData={values.discountData ?? undefined}
              />
              {values.techCount < minTechCount && (
                <div
                  style={{
                    color: 'red',
                    opacity: '0.7',
                    fontSize: 'small',
                    fontWeight: 'bold',
                  }}
                >
                  {`The minimum possible Technician license count is ${minTechCount}`}
                </div>
              )}
            </div>

            {(values.techCount < techCount ||
              getDowngradeState(values.pricingPlan)) &&
              values.techCount >= minTechCount && (
                <FieldWrapper
                  label="Note"
                  labelSize={12}
                  contentSize={12}
                  fullWidth
                  content={
                    <Field
                      placeholder="Please provide some feedback"
                      name="comment"
                      id="comment"
                      render={Input}
                      multiline
                      disabled={processing}
                      minRows={8}
                    />
                  }
                />
              )}
            <div>
              <ReusableButton
                label="Checkout"
                type="submit"
                viewType="blue"
                loading={processing}
                disabled={
                  values.discountData === 'Wrong Discount' ||
                  processing ||
                  values.techCount < minTechCount
                }
              />
            </div>
          </form>
        </div>
      )}
    />
  );
};

export default PricePlanForm;
