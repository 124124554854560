import { parsePhoneNumberFromString } from 'libphonenumber-js';

const InternationalFormatPhoneNumber = number => {
  if (!number) {
    return number;
  }
  // Remove all characters except + and digits
  const cleanedNumber = (number.toString() || '').replace(/[^+\d]+/g, '');

  // Attempt to parse the number as a national number
  const phoneNumber = parsePhoneNumberFromString(
    cleanedNumber.startsWith('+') ? cleanedNumber : `+${cleanedNumber}`,
  );

  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber.formatInternational();
  }

  // If all parsing attempts fail, return the number formatted as (XXX) XXX-XXXX
  return number;
};

export default InternationalFormatPhoneNumber;
