import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteHelpdeskConfiguration } from '@services/requests/helpdeskAI';

export const ThunkDeleteHelpdeskConfiguration = createAsyncThunk(
  'helpdeskAI/deleteHelpdeskConfiguration',
  async id =>
    deleteHelpdeskConfiguration(id).then(res =>
      res.status === 200 ? {} : undefined,
    ),
);

export default {};
