import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  table: {
    margin: '4rem 16px 0',
    '& tbody': {
      maxHeight: '70vh',
    },
  },
  button: {
    marginBottom: '8px',
  },
  formText: {
    marginTop: '1rem',
    fontStyle: 'normal',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputWrapper: {
    marginTop: '0.625rem',
  },
}));

export default useStyles;
