/* eslint-disable react/no-danger */
import React from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';

import ReusableButton from '@common/Button/Button';

import useStyles from './styles';

const HtmlDialog = ({ htmlContent, onClose, open = true }) => {
  const classes = useStyles();

  const santisedHtmlContent = DOMPurify.sanitize(htmlContent);

  return (
    <Dialog open={open} onClose={onClose}>
      <div
        className={classes.htmlContent}
        dangerouslySetInnerHTML={{ __html: santisedHtmlContent }}
      />

      <div className={classes.buttons}>
        <ReusableButton onClick={onClose} label="Close" />
      </div>
    </Dialog>
  );
};

HtmlDialog.propTypes = {
  htmlContent: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default HtmlDialog;
