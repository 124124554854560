import React, { useEffect, useState } from 'react';
import {
  Error,
  useAuthenticated,
  useAuthProvider,
  useGetOne,
  useRedirect,
} from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import MuiPhoneNumber from 'material-ui-phone-number';
import emailValidator from '@utils/validators/emailValidator';
import Loading from '@common/Loading/Loading';
import cx from 'classnames';
import { enqueueSnackbar } from 'notistack';
import { TEXT_BASE_ERROR, TEXT_UPDATE_SUCESS } from '@constants/texts/common';

import { Field, Form } from 'react-final-form';
import resources from '@constants/resources';
import { HOME_ROUTE, MULTI_FACTOR_SETUP } from '@constants/routes';
import { actions } from '@store/actions';
import putData from '@services/api/common/putData';
import Input from '@common/Input/Input';
import ReusableButton from '@common/Button/Button';
import appConfig from '@configs/appConfig';
import FormSwitch from '@common/FilterInputs/Switch';
import FieldWrapper from '@common/form/FieldWrapper';
import BoxWrapper from '@common/BoxWrapper';
import FormControlCheckBox from '@common/Checkbox/FormControlCheckBox';
import Paper from '@common/Paper/Paper';
import UploadImageField from '@components/Profile/components/UploadImageField/UploadImageField';
import FormSwitchWrapper from '@components/Ticketing/Notifications/TicketSettings/components/FormSwitchWrapper';
import { enrollUserDuo, getExternalLogin } from '@components/Auth/helpers';
import externalLoginType from '@constants/externalLoginType';
import { linkAccount, unLinkAccount } from '@components/Profile/helpers';
import crmSources from '@constants/crmSources';
import LinkCrmUserDialog from '@components/Profile/components/LinkCrmUser/LinkCrmUserDialog';
import { currentUserDataSelector } from '@store/selectors';
import { ThunkLinkCrmUsersToAccount } from '@store/slices/user/thunks/linkCrmUserToAccount';
import { ThunkGetDuoAuthUrl } from '@store/slices/user/thunks/getDuoAuthUrl';
import FormattedInputs from '@common/MaskedInput';
import { phoneNumberFormat } from '@constants/phoneNumber';
import SetupWizard from '@components/SetupWizard';
import useStyles from './styles';
import DeleteProfileDialog from './components/DeleteProfileDialog/Dialog';
import useUploadImage from './hook/useUploadImage';
import useSharedStyle from '../CrmTicket/sharedStyles';

const MuiPhoneNumberWrapper = ({
  input,
  inputView,
  classNameWrapper,
  styleType,
  ...rest
}) => {
  return <MuiPhoneNumber defaultCountry="us" {...rest} {...input} />;
};

const Edit = () => {
  const classes = useStyles();
  const sharedClasses = useSharedStyle();
  const redirect = useRedirect();
  const dispatch = useDispatch();

  const id = useAuthProvider().getUserId();
  const [open, toggle] = useState(false);
  const [linkUserDialogOpen, setLinkUserDialogOpen] = useState(false);
  const [crmInfo, setCrmInfo] = useState(null);
  const [openSetupWizard, setOpenSetupWizard] = useState(false);

  const onToggle = () => toggle(!open);
  const currentUserData = useSelector(currentUserDataSelector);

  const {
    imageUri,
    showLoader,
    startLoading,
    finishLoading,
    removeImage,
    setImage,
  } = useUploadImage();

  useAuthenticated();

  const { data, loading, error } = useGetOne(resources.user, id);

  useEffect(() => {
    if (data) setImage(data.imageUri);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading)
    return (
      <BoxWrapper>
        <Loading />
      </BoxWrapper>
    );
  if (error)
    return (
      <BoxWrapper>
        <Error error={error} />
      </BoxWrapper>
    );

  const submit = async values => {
    /**
     * Added in "requestBody" property by default "notificationEmail:''", for overwriting "data.notificationEmail"
     * if property notificationEmail doesn't exist in "values" we can
     */

    const requestBody = {
      ...data,
      notificationEmail: '',
      ...values,
      imageUri,
    };
    const result = await putData(
      `${appConfig.baseUrl}/user/${id}`,
      requestBody,
    );
    if (result) {
      dispatch(actions.saveProfile(requestBody));
      enqueueSnackbar(TEXT_UPDATE_SUCESS, { variant: 'success' });
      redirect(HOME_ROUTE);
    } else {
      enqueueSnackbar(TEXT_BASE_ERROR, { variant: 'error' });
    }
  };

  const handleOpenMfaSetupPage = () => redirect(MULTI_FACTOR_SETUP);

  const handleDuoLinked = async (checked, change) => {
    change('duoLinked', checked);
    if (checked) {
      await dispatch(ThunkGetDuoAuthUrl())
        .unwrap()
        .then(p => {
          redirect(p.url);
        });
    }
  };

  const getError = (value, emailChecked, emailError) => {
    return (!value && emailChecked) || value ? emailError : undefined;
  };

  const validate = values => {
    const errors = {};
    const emailError = emailValidator(values.notificationEmail);
    const errorMsg = getError(
      values.notificationEmail,
      values.notifyByEmail,
      emailError,
    );

    errors.notificationEmail = errorMsg;
    errors.notifyByEmail = errorMsg;

    return errors;
  };

  const parseResult = async result => {
    const parsedResult = await result.text();
    return parsedResult;
  };

  const linkToExternalAcc = (value, type, change, name) => {
    if (value) {
      if (type === externalLoginType.Duo) {
        enrollUserDuo()
          .then(token =>
            linkAccount(token, type).then(() => {
              change(name, value);
              enqueueSnackbar('Successfully linked', { variant: 'success' });
            }),
          )
          .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
      }

      getExternalLogin(type)
        .then(token =>
          linkAccount(token, type).then(() => {
            change(name, value);
            enqueueSnackbar('Successfully linked', { variant: 'success' });
          }),
        )
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
    } else {
      unLinkAccount(type)
        .then(() => change(name, value))
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
    }
  };

  const linkToCrmUser = async (
    isChecked,
    crmSourceType,
    formChange,
    fieldName,
  ) => {
    if (isChecked) {
      setCrmInfo({ type: crmSourceType, field: fieldName });
      setLinkUserDialogOpen(true);
    } else {
      await dispatch(
        ThunkLinkCrmUsersToAccount({
          isChecked: false,
          type: crmSourceType.name,
        }),
      ).unwrap();
      const newUserData = {
        ...currentUserData,
      };

      if (crmSourceType.name === crmSources.ConnectWise.name) {
        newUserData.cwUserLinked = false;
        newUserData.connectwiseLinkedUser = null;
      }
      if (crmSourceType.name === crmSources.Autotask.name) {
        newUserData.atUserLinked = false;
        newUserData.autotaskLinkedUser = null;
      }

      dispatch(actions.setCurrentUser(newUserData));
      formChange(fieldName, isChecked);
    }
  };

  return (
    <Paper>
      <Form
        onSubmit={submit}
        validate={validate}
        initialValues={{
          email: data.email,
          username: data.username,
          useMfa: data.useMfa,
          notifyByEmail: data.notifyByEmail,
          notifyBySms: data.notifyBySms,
          notificationEmail: data.notificationEmail || undefined,
          notificationPhone: data.notificationPhone,
          microsoftLinked: data.microsoftLinked,
          googleLinked: data.googleLinked,
          cwUserLinked: data.cwUserLinked,
          atUserLinked: data.atUserLinked,
          customLocation: data.customLocation,
          duoLinked: data.duoLinked,
        }}
        render={({ handleSubmit, values, form: { change } }) => (
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <UploadImageField
              title="pages.profile.image.upload"
              label="pages.profile.image.hint"
              uploadApiUrl="/user-image-upload"
              imageUrl={parseResult}
              imageActions={{
                imageUri,
                showLoader,
                startLoading,
                finishLoading,
                removeImage,
              }}
            />
            <div className={classes.boxWrapper}>
              <FieldWrapper
                label="Email"
                classNameLabelOuter={classes.fieldLabelOuter}
                classNameContainer={classes.displayBlock}
                contentSize={12}
                content={
                  <Field
                    autoFocus
                    id="email"
                    name="email"
                    inputView="text"
                    styleType="main"
                    classNameWrapper={classes.inputClassNameWrapper}
                    component={Input}
                    placeholder="email"
                    disabled
                  />
                }
              />
              <FieldWrapper
                label="Username"
                contentSize={12}
                classNameLabelOuter={classes.fieldLabelOuter}
                classNameContainer={classes.displayBlock}
                content={
                  <Field
                    id="username"
                    name="username"
                    inputView="text"
                    styleType="main"
                    classNameWrapper={classes.inputClassNameWrapper}
                    component={Input}
                    placeholder="username"
                    disabled={loading}
                    autoComplete="nope"
                  />
                }
              />
            </div>
            <div className={cx(classes.boxWrapper, classes.halfWidth)}>
              <FieldWrapper
                label="Location"
                contentSize={12}
                classNameLabelOuter={classes.fieldLabelOuter}
                classNameContainer={classes.displayBlock}
                content={
                  <Field
                    id="customLocation"
                    name="customLocation"
                    inputView="text"
                    styleType="main"
                    classNameWrapper={classes.inputClassNameWrapper}
                    component={Input}
                    disabled={loading}
                  />
                }
              />
            </div>
            <div className={classes.boxWrapper}>
              <FieldWrapper
                contentSize={12}
                classNameLabelOuter={classes.fieldLabelOuter}
                classNameContainer={classes.displayBlock}
                content={
                  <>
                    <Field
                      name="notifyByEmail"
                      label="Notify by email"
                      type="checkbox"
                      component={FormControlCheckBox}
                    />
                    <Field
                      id="notificationEmail"
                      name="notificationEmail"
                      placeholder="Notify by email"
                      inputView="text"
                      fullWidth
                      disabled={!values.notifyByEmail}
                      classNameWrapper={classes.disabled}
                      styleType="main"
                      component={Input}
                      autoComplete="nope"
                    />
                  </>
                }
              />
              <FieldWrapper
                contentSize={12}
                classNameLabelOuter={classes.fieldLabelOuter}
                classNameContainer={classes.displayBlock}
                content={
                  <>
                    <Field
                      name="notifyBySms"
                      label="Notify by SMS"
                      type="checkbox"
                      component={FormControlCheckBox}
                    />
                    <Field
                      name="notificationPhone"
                      id="notificationPhone"
                      render={FormattedInputs}
                      type="phone"
                      disabled={!values.notifyBySms}
                      mask={phoneNumberFormat}
                    />
                  </>
                }
              />
            </div>
            <div className={classes.boxWrapper}>
              <FieldWrapper
                label="Link Microsoft account"
                labelSize={12}
                contentSize={3}
                classNameContainer={classes.checkboxContainer}
                classNameLabelInner={sharedClasses.alignLeft}
                classNameContentContainer={classes.checkboxContainer}
                content={
                  <Field
                    id="microsoftLinked"
                    name="microsoftLinked"
                    type="checkbox"
                    component={FormSwitchWrapper}
                    input={{
                      checked: values.microsoftLinked,
                      onChange: event => {
                        linkToExternalAcc(
                          event.target.checked,
                          externalLoginType.Microsoft,
                          change,
                          'microsoftLinked',
                        );
                      },
                    }}
                  />
                }
              />
              <FieldWrapper
                label="Link Google account"
                labelSize={12}
                contentSize={3}
                classNameContainer={classes.checkboxContainer}
                classNameLabelInner={sharedClasses.alignLeft}
                classNameContentContainer={classes.checkboxContainer}
                content={
                  <Field
                    id="googleLinked"
                    name="googleLinked"
                    type="checkbox"
                    component={FormSwitchWrapper}
                    input={{
                      checked: values.googleLinked,
                      onChange: event => {
                        linkToExternalAcc(
                          event.target.checked,
                          externalLoginType.Google,
                          change,
                          'googleLinked',
                        );
                      },
                    }}
                  />
                }
              />
            </div>
            <div className={classes.boxWrapper}>
              <FieldWrapper
                label="Link Connectwise user"
                labelSize={12}
                contentSize={3}
                classNameLabelInner={sharedClasses.alignLeft}
                classNameContainer={classes.checkboxContainer}
                classNameContentContainer={classes.checkboxContainer}
                content={
                  <Field
                    id="cwUserLinked"
                    name="cwUserLinked"
                    type="checkbox"
                    component={FormSwitchWrapper}
                    input={{
                      checked: values.cwUserLinked,
                      onChange: event => {
                        linkToCrmUser(
                          event.target.checked,
                          crmSources.ConnectWise,
                          change,
                          event.target.id,
                        );
                      },
                    }}
                  />
                }
              />
              <FieldWrapper
                label="Link Autotask user"
                labelSize={12}
                contentSize={3}
                classNameLabelInner={sharedClasses.alignLeft}
                classNameContainer={classes.checkboxContainer}
                classNameContentContainer={classes.checkboxContainer}
                content={
                  <Field
                    id="atUserLinked"
                    name="atUserLinked"
                    type="checkbox"
                    component={FormSwitchWrapper}
                    input={{
                      checked: values.atUserLinked,
                      onChange: event => {
                        linkToCrmUser(
                          event.target.checked,
                          crmSources.Autotask,
                          change,
                          event.target.id,
                        );
                      },
                    }}
                  />
                }
              />
            </div>
            <div className={classes.linkedUserWrapper}>
              <div>{currentUserData?.connectwiseLinkedUser?.name}</div>
              <div>{currentUserData?.autotaskLinkedUser?.name}</div>
            </div>
            {currentUserData?.hasDuoWebSdkIntegration && (
              <div className={classes.boxWrapper}>
                <FieldWrapper
                  label={
                    <>
                      <span>Link Duo</span>
                      <Field
                        id="duoLinked"
                        name="duoLinked"
                        type="checkbox"
                        component={FormSwitch}
                        classNameWrapper={classes.switcher}
                        disabled={loading}
                        input={{
                          checked: values.duoLinked,
                          onChange: event => {
                            handleDuoLinked(event.target.checked, change);
                          },
                        }}
                      />
                    </>
                  }
                  contentSize={6}
                  classNameLabelOuter={classes.fieldLabelOuter}
                  classNameContainer={classes.displayBlock}
                />
              </div>
            )}
            <div className={classes.boxWrapper}>
              <FieldWrapper
                label={
                  <>
                    <span>Use MFA</span>
                    <Field
                      id="useMfa"
                      name="useMfa"
                      type="checkbox"
                      component={FormSwitch}
                      classNameWrapper={classes.switcher}
                      disabled={loading}
                    />
                  </>
                }
                contentSize={6}
                classNameLabelOuter={classes.fieldLabelOuter}
                classNameContainer={classes.displayBlock}
                content={
                  <>
                    <ReusableButton
                      size="lg"
                      type="button"
                      classNameWrapper={classes.buttonContainer}
                      label="Open MFA setup"
                      disabled={loading}
                      onClick={handleOpenMfaSetupPage}
                    />
                  </>
                }
              />
            </div>
            <div className={classes.boxWrapper}>
              <FieldWrapper
                contentSize={6}
                spacing={0}
                classNameLabelContainer={classes.hidden}
                classNameContainer={classes.displayBlock}
                content={
                  <>
                    <ReusableButton
                      size="lg"
                      classNameWrapper={classes.buttonContainer}
                      viewType="black"
                      type="submit"
                      disabled={loading}
                      loading={loading}
                      label="resources.buttons.submit"
                    />

                    <ReusableButton
                      size="lg"
                      type="button"
                      classNameWrapper={classes.buttonContainer}
                      label="Open Setup Wizard"
                      disabled={loading}
                      onClick={() => {
                        setOpenSetupWizard(true);
                      }}
                    />
                  </>
                }
              />
            </div>
            {linkUserDialogOpen && (
              <LinkCrmUserDialog
                open={linkUserDialogOpen}
                onClose={() => setLinkUserDialogOpen(false)}
                crmInfo={crmInfo}
                formChange={change}
              />
            )}

            {openSetupWizard && (
              <SetupWizard
                setOpen={() => {
                  setOpenSetupWizard(false);
                }}
                open={openSetupWizard}
              />
            )}
          </form>
        )}
      />
      <DeleteProfileDialog open={open} onToggle={onToggle} />
    </Paper>
  );
};

export default Edit;
