import React, { useEffect, useState } from 'react';
import { useDataProvider, useRedirect } from 'react-admin';
import { Field, Form } from 'react-final-form';
import { enqueueSnackbar } from 'notistack';

import { TEXT_CREATE_SUCESS } from '@constants/texts/common';
import Input from '@ui/components/Auth/Common/Input';
import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import ReusableButton from '@ui/components/common/Button/Button';
import { GENERIC_SOURCES } from '@constants/routes';
import { useHistory } from 'react-router-dom';
import {
  createDataView,
  getDetails,
  getGenericPipelinesOptions,
} from '@components/GenericSources/helpers';
import SwitchInput from '@common/FilterInputs/Switch';
import Loading from '@common/Loading/Loading';
import useAsync from '@services/api/common/useAsync';
import resources from '@constants/resources';
import { requiredValidator } from '@utils/validators';
import DataViewCreator from '@components/WebHooks/components/DataViewCreator';
import maxLengthValidator from '@utils/validators/maxLengthValidator';
import PipelineWrapper from './PipelineWrapper';
import useStyles from './styles';

const initData = {
  genericPipelineId: 0,
  id: 0,
  inputData: {},
  isActive: true,
  name: null,
  processData: true,
};

const EditGenericSource = ({ match }) => {
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [initialValues, setInitialValues] = useState({});
  const [pipelineOptions, setPipelineOptions] = useState([]);
  const [inputFields, setInputFields] = useState([]);
  const [createViewOpen, setCreateViewOpen] = useState(false);
  const [modelForSave, setModelForSave] = useState({});
  const loadingOptions = useAsync(
    getGenericPipelinesOptions,
    setPipelineOptions,
  );
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const { goBack } = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (!Number.isNaN(+match.params.id)) {
      setLoading(true);
      getDetails(match.params.id)
        .then(d => {
          const input = JSON.parse(d.inputData);
          setInitialValues({
            ...d,
            inputData: input,
          });
        })
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
        .finally(() => setLoading(false));
      return;
    }
    if (match.params.id === 'createBot') {
      setInitialValues(initData);
      return;
    }
    redirect(GENERIC_SOURCES);
  }, [match]);

  const create = data =>
    dataProvider.create(resources.genericSources, { data });

  const update = data =>
    dataProvider.update(resources.genericSources, { id: data.id, data });

  const submit = values => {
    const data = {
      ...values,
      inputData: JSON.stringify(values.inputData),
    };
    setModelForSave(data);
    setCreateViewOpen(true);
  };

  const submitAfterDataView = (model, dwInfo) => {
    const action = modelForSave.id ? update : create;
    setProcessing(true);
    action(modelForSave)
      .then(response => {
        if (dwInfo) {
          createDataView(response.data.id, {
            name: dwInfo.name,
            linkGenericSource: dwInfo.linkWebHook,
          })
            .then(() =>
              enqueueSnackbar(TEXT_CREATE_SUCESS, { variant: 'success' }),
            )
            .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
        }
        enqueueSnackbar('Successfully stored', { variant: 'success' });
        setProcessing(false);
        redirect(GENERIC_SOURCES);
      })
      .catch(e => {
        enqueueSnackbar(e.message, { variant: 'error' });
        setProcessing(false);
      });
  };

  const validate = values => {
    const errors = { inputData: {} };
    errors.name = maxLengthValidator(values.name, 250);
    errors.genericPipelineId = requiredValidator(values.genericPipelineId);
    inputFields.forEach(el => {
      if (!values.inputData || !values.inputData[el.name]) {
        errors.inputData[el.name] = 'ra.validation.required';
      }
    });
    return errors;
  };

  if (loading || loadingOptions) return <Loading />;

  return (
    <div className={classes.container}>
      <Form
        onSubmit={submit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <FieldWrapper
              label="Enable"
              classNameLabelOuter={classes.alignLeft}
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="isActive"
                  name="isActive"
                  type="checkbox"
                  component={SwitchInput}
                />
              }
            />
            <FieldWrapper
              label="Name"
              classNameLabelOuter={classes.alignLeft}
              labelSize={2}
              contentSize={10}
              content={
                <Field
                  id="name"
                  name="name"
                  fullWidth
                  component={Input}
                  placeholder="Name"
                />
              }
            />
            <FieldWrapper
              label="Process data"
              classNameLabelOuter={classes.alignLeft}
              labelSize={2}
              contentSize={10}
              content={
                <div className={classes.center}>
                  <Field
                    id="processData"
                    name="processData"
                    type="checkbox"
                    component={SwitchInput}
                  />
                  <span className={classes.descriptionMargin}>
                    {values.processData
                      ? 'Process identity and non identity fields'
                      : 'Process identity fields only'}
                  </span>
                </div>
              }
            />
            <PipelineWrapper
              form={form}
              values={values}
              pipelineOptions={pipelineOptions}
              inputFields={inputFields}
              setInputFields={setInputFields}
            />
            <div className={classes.buttonContainer}>
              <ReusableButton
                label="Cancel"
                size="md"
                onClick={goBack}
                disabled={processing}
              />
              <ReusableButton
                viewType="black"
                type="submit"
                label="Submit"
                size="md"
                disabled={processing}
                loading={processing}
              />
            </div>
          </form>
        )}
      />
      <DataViewCreator
        open={createViewOpen}
        setOpen={setCreateViewOpen}
        onSubmit={submitAfterDataView}
        model={modelForSave}
        resource="linkSource"
      />
    </div>
  );
};

export default EditGenericSource;
