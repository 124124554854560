import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import DeleteIcon from '@assets/icons/delete.svg';
import { actions } from '@store/actions';

import {
  deleteM365Tenant,
  setupM365Tenant,
} from '@services/requests/integration';
import { TEXT_DELETE_SUCESS } from '@constants/texts/common';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { DEFAULT_DIALOG_DATA } from '@constants/common';
import DateTimeField from '@common/TableComponents/DateTimeField';
import ReusableButton from '@common/Button/Button';

import useStyles from './styles';

const Microsoft365Table = ({ crmId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [dialogData, setDialogData] = useState(DEFAULT_DIALOG_DATA);
  const [loading, setLoading] = useState(false);

  const handleCreate = () => {
    setLoading(true);
    setupM365Tenant({
      crmId,
      redirectUrl: window.location.href.split('?')[0],
    })
      .then(({ data }) => {
        window.location.href = data;
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = () => {
    setLoading(true);
    deleteM365Tenant(dialogData.id)
      .then(() => {
        dispatch(actions.removeItemFromList({ id: dialogData.id }));
        enqueueSnackbar(TEXT_DELETE_SUCESS, { variant: 'success' });
        setDialogData(DEFAULT_DIALOG_DATA);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (location.search.includes('errorMessage')) {
      enqueueSnackbar(
        decodeURIComponent(location.search.replace('?errorMessage=', '')),
        { variant: 'error' },
      );
    }
  }, [enqueueSnackbar, location]);

  return (
    <div>
      <ReusableButton
        size="md"
        onClick={handleCreate}
        classNameWrapper={classes.button}
        disabled={loading}
      >
        Connect to Microsoft 365
      </ReusableButton>
      <Table
        resource={`/MicrosoftContactsIntegrations/${crmId}`}
        columns={[
          {
            name: 'ID',
            key: 'id',
            sortable: true,
            searchable: true,
            minWidth: 150,
          },
          {
            name: 'Name',
            key: 'msTenantName',
            sortable: true,
            searchable: true,
            minWidth: 150,
          },
          {
            name: 'Date',
            key: 'createdAt',
            sortable: true,
            isCustomAccessor: true,
            minWidth: 175,
            accessor: rowData => (
              <DateTimeField
                record={rowData}
                key="createdAt"
                source="createdAt"
              />
            ),
          },
          {
            name: 'Actions',
            minWidth: 100,
            width: 100,
            key: TableTypes.actions,
            type: TableTypes.actions,
            accessor: item => (
              <div className={classes.center}>
                <ActionFieldItem
                  icon={DeleteIcon}
                  alt="delete"
                  handler={() => setDialogData({ isOpen: true, id: item.id })}
                  toolTip="Remove"
                />
              </div>
            ),
          },
        ]}
      />
      {dialogData.isOpen && (
        <ConfirmDialog
          onAccept={handleDelete}
          onCancel={() => {
            setDialogData(DEFAULT_DIALOG_DATA);
          }}
          title="Confirm Action"
          content="Are you sure you want to delete this item?"
          open
          loading={loading}
        />
      )}
    </div>
  );
};

Microsoft365Table.propTypes = {
  crmId: PropTypes.number.isRequired,
};

export default Microsoft365Table;
