import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    margin: '2rem',
    height: '83vh',
  },
}));

export default useStyles;
