import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

import { TEXT_DELETE_SUCESS } from '@constants/texts/common';
import DeleteIcon from '@assets/icons/delete.svg';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import { ThunkDeleteSmsBlackListNumbers } from '@store/slices/smsBlackList/thunks';
import { actions } from '@store/actions';

const SmsBlackListTableItemActions = ({ record }) => {
  const dispatch = useDispatch();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleDelete = id => {
    setProcessing(true);
    dispatch(ThunkDeleteSmsBlackListNumbers([id]))
      .unwrap()
      .then(() => {
        enqueueSnackbar(TEXT_DELETE_SUCESS, { variant: 'success' });
        dispatch(actions.removeItemFromList({ id }));
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => {
        setOpenDeleteDialog(false);
        setProcessing(false);
      });
  };

  return (
    <div>
      <ActionFieldItem
        icon={DeleteIcon}
        alt="delete"
        handler={() => {
          setOpenDeleteDialog(true);
        }}
        toolTip="Delete"
      />
      {openDeleteDialog && (
        <ConfirmDialog
          loading={processing}
          onAccept={() => {
            handleDelete(record.id);
          }}
          onCancel={() => {
            setOpenDeleteDialog(false);
          }}
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          title="Confirm Action"
          content="Are you sure you want to delete this item?"
        />
      )}
    </div>
  );
};

SmsBlackListTableItemActions.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    blockedPhoneNumber: PropTypes.string,
    dateOfBlocking: PropTypes.string,
    tenantName: PropTypes.string,
  }),
};

export default SmsBlackListTableItemActions;
