import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logout: {
    paddingTop: '5px',
    marginLeft: '15px',
    cursor: 'pointer',
  },
  languageButton: {
    minWidth: 40,
  },
  userButton: {
    display: 'flex',
    '& > div > button': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  iconButtonUser: {
    fontSize: '0.875rem',
    color: theme.palette.info.main,
    fontWeight: 'bold',
    padding: '0 0.625rem',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconUser: {
    minWidth: '1.8125rem',
  },
  number: {
    width: 'max-content',
    padding: ' 4px 5px 0 5px',
  },
}));

export default useStyles;
