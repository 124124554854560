import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useDataProvider, useNotify, useTranslate } from 'react-admin';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import { isEmailExists } from '@components/Settings/NotificationGroups/helpers';
import { emailGroupNameLength } from '@constants/fieldLengths';
import { validateRequired } from '@common/functions/validators';
import { actions } from '@store/actions';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import emailValidator from '@utils/validators/emailValidator';
import FieldWrapper from '@common/form/FieldWrapper';
import phoneValidation from '@constants/phoneValidation';
import FormSwitch from '@components/Auth/Common/FormSwitch';
import predicateRequiredValidator from '@utils/validators/predicateRequiredValidator';
import useStyles from '../styles';

const initialValue = {
  name: '',
  email: '',
  phone: '',
  useEmail: false,
  usePhone: false,
  sendVoiceMessage: false,
};

const NotificationGroupUserForm = ({
  groupId,
  open,
  onClose,
  init = initialValue,
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);

  const initial = useMemo(() => init, [init]);

  const create = value => {
    setLoading(true);
    dataProvider
      .create('NotificationGroups/users', {
        data: { ...value, notificationGroupId: groupId },
      })
      .then(d => dispatch(actions.updateItemInList(d.data)))
      .catch(e => notify(e.message, 'error'))
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const update = value => {
    setLoading(true);
    dataProvider
      .update('NotificationGroups/users', {
        id: value.id,
        data: { ...value, notificationGroupId: groupId },
      })
      .then(d => dispatch(actions.updateItemInList(d.data)))
      .catch(e => notify(e.message, 'error'))
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const submit = value => {
    if (value.id) {
      update(value);
    } else {
      create(value);
    }
  };

  const validate = async value => {
    let asyncError;
    let nameLengthError;
    if (value.email) {
      if (!initial || initial.email !== value.email) {
        const emailExists = await isEmailExists(groupId, value.email);
        asyncError = emailExists ? 'Email already taken' : undefined;
      }
      nameLengthError =
        value.name?.length > emailGroupNameLength
          ? `${emailGroupNameLength} symbols length limit`
          : undefined;
      const isEmail = emailValidator(value.email);
      asyncError = isEmail ? translate(isEmail) : asyncError;
    }

    return {
      name: validateRequired(value.name) || nameLengthError,
      email: validateRequired(value.email) || asyncError,
      phone: value.phone
        ? phoneValidation(value.phone)
        : predicateRequiredValidator(
            value.usePhone || value.sendVoiceMessage,
            value.phone,
          ),
    };
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        {init?.id ? 'Update ' : 'Create '}
        notification user
      </DialogTitle>
      <DialogContent className={classes.editUserDialog}>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={init}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <FieldWrapper
                  label="Name"
                  isRequired
                  labelSize={3}
                  content={
                    <Field
                      id="name"
                      name="name"
                      fullWidth
                      component={Input}
                      placeholder="Name"
                      disabled={loading}
                    />
                  }
                />
                <FieldWrapper
                  label="Email"
                  isRequired
                  labelSize={3}
                  content={
                    <Field
                      id="email"
                      name="email"
                      fullWidth
                      component={Input}
                      placeholder="Email"
                      disabled={loading}
                    />
                  }
                />
                <FieldWrapper
                  label="Send email"
                  labelSize={3}
                  content={
                    <Field
                      id="useEmail"
                      name="useEmail"
                      type="checkbox"
                      component={FormSwitch}
                      disabled={loading}
                    />
                  }
                />
                <FieldWrapper
                  label="Phone"
                  labelSize={3}
                  infoText="Format: +{country code}{state code}9876543"
                  content={
                    <Field
                      id="phone"
                      name="phone"
                      fullWidth
                      component={Input}
                      placeholder="Phone"
                      disabled={
                        loading ||
                        (!values?.usePhone && !values?.sendVoiceMessage)
                      }
                    />
                  }
                />
                <FieldWrapper
                  label="Make automatic call"
                  labelSize={3}
                  content={
                    <Field
                      id="sendVoiceMessage"
                      name="sendVoiceMessage"
                      type="checkbox"
                      component={FormSwitch}
                      disabled={loading}
                    />
                  }
                />
                <FieldWrapper
                  label="Send SMS"
                  labelSize={3}
                  content={
                    <Field
                      id="usePhone"
                      name="usePhone"
                      type="checkbox"
                      component={FormSwitch}
                      disabled={loading}
                    />
                  }
                />
              </div>
              <div className={classes.buttonGroup}>
                <ReusableButton
                  label="resources.buttons.cancel"
                  onClick={onClose}
                  disabled={loading}
                />
                <ReusableButton
                  type="submit"
                  viewType="black"
                  label="resources.buttons.confirm"
                  loading={loading}
                  disabled={loading}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

NotificationGroupUserForm.propTypes = {
  init: PropTypes.objectOf(PropTypes.any),
  onClose: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
};

export default NotificationGroupUserForm;
