/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CREATE_TICKET } from '@store/slices/resources';

import { ThunkGetHaloClientsCrmId } from '@store/slices/HaloTickets/thunks';
import { ThunkGetSuperOpsCompanies } from '@store/slices/SuperOps/thunks';
import {
  ThunkGetAutotaskCompanies,
  ThunkGetConnectWiseCompanies,
  ThunkGetKaseyaCompanies,
} from './thunks';

const initialState = {
  companies: [],
};

const createTicketSlice = createSlice({
  name: CREATE_TICKET,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ThunkGetAutotaskCompanies.fulfilled, (state, action) => {
      state.companies = action.payload;
    });

    builder.addCase(ThunkGetConnectWiseCompanies.fulfilled, (state, action) => {
      state.companies = action.payload;
    });

    builder.addCase(ThunkGetKaseyaCompanies.fulfilled, (state, action) => {
      state.companies = action.payload;
    });

    builder.addCase(ThunkGetHaloClientsCrmId.fulfilled, (state, action) => {
      state.companies = action.payload;
    });

    builder.addCase(ThunkGetSuperOpsCompanies.fulfilled, (state, action) => {
      state.companies = action.payload;
    });
  },
});

export default createTicketSlice.reducer;

export const PSACompaniesSelector = state => state[CREATE_TICKET].companies;
