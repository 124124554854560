import React, { useCallback, useEffect, useState } from 'react';

import { getDeviceMonitorsColumns } from '@ui/pages/uptimeMonitors/DeviceMonitors/utils';
import { getRegionsAutocompleteOptions } from '@components/DeviceMonitors/components/helpers';
import { sortOrders } from '@constants/filters';

import Table from '@components/Table';
import BagelContainer from './components/BagelContainer';
import BulkActions from './components/BulkActions';
import TableActions from './components/TableActions';

import useStyles from './styles';

const DeviceMonitors = () => {
  const [regionsOptions, setRegionsOptions] = useState([]);

  const classes = useStyles();

  const onMount = useCallback(() => {
    getRegionsAutocompleteOptions().then(options => {
      setRegionsOptions(options);
    });
  }, []);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div>
      <div className={classes.tableWrapper}>
        <Table
          resource="deviceMonitors"
          columns={getDeviceMonitorsColumns(regionsOptions)}
          actionsComponent={
            <div>
              <BagelContainer />
              <TableActions />
            </div>
          }
          bulkActions={<BulkActions />}
          defaultSort={{
            fieldName: 'company',
            order: sortOrders.asc,
          }}
        />
      </div>
    </div>
  );
};

export default DeviceMonitors;
