import { useNotify } from 'react-admin';
import { actions } from '@store/actions';
import { useDispatch } from 'react-redux';
import { useTableContext } from '../../../hooks/table';

const useBulkDelete = (apiRequest, setOpenDialog) => {
  const { selectedIds, deselectAllItems } = useTableContext();
  const notify = useNotify();

  const dispatch = useDispatch();

  return () => {
    const params = selectedIds.map(i => `id=${i}`).join('&');

    return apiRequest(params)
      .then(() => {
        dispatch(actions.removeItemFromList({ selectedIds }));
        deselectAllItems();
        notify(`Successfully removed`);
      })
      .catch(() => notify(`elements deleting was failed`, 'error'))
      .finally(() => setOpenDialog(false));
  };
};

export default useBulkDelete;
