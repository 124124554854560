import putData from '@services/api/common/putData';
import getData from '@services/api/common/getData';
import deleteData from '@services/api/common/deleteData';
import appConfig from '@configs/appConfig';
import { deviceMonitors, exportUrl } from '@constants/appRoutes';
import postWithResponse from '@services/api/common/postWithResponse';
import { createQueryParams } from '@utils/index';

const toggleEnabled = (id, data) =>
  putData(`${appConfig.baseUrl.concat(deviceMonitors)}${id}/makeEnabled`, data);

export const testDeviceMonitor = data =>
  postWithResponse(
    `${appConfig.baseUrl.concat(deviceMonitors)}test?${createQueryParams(
      data,
    )}`,
  );

export const getDeviceMonitor = id =>
  getData(`${appConfig.baseUrl.concat(deviceMonitors)}${id}`);

export const getDeviceMonitorMetrics = id => {
  /**
   * later add functionality handle query params
   */
  return getData(
    `${appConfig.baseUrl.concat(
      deviceMonitors,
    )}${id}/getMetrics?${createQueryParams({
      _end: 100,
      _order: 'DESC',
      _sort: 'id',
      _start: '0',
    })}`,
  );
};

export const getDeviceStatistic = id =>
  getData(`${appConfig.baseUrl.concat(deviceMonitors)}${id}/getStatistic`);

export const deleteDeviceMonitor = id =>
  deleteData(`${appConfig.baseUrl.concat(deviceMonitors)}${id}`);

export const deleteDeviceMonitors = params =>
  deleteData(
    `${appConfig.baseUrl.concat(deviceMonitors)}bulkDelete?${params}`,
    true,
  );

export const updateDeviceMonitors = (params, data) =>
  putData(
    `${appConfig.baseUrl.concat(deviceMonitors)}bulkMakeEnabled?${params}`,
    data,
  );

export const getMonitorCountStatuses = () =>
  getData(`${appConfig.baseUrl.concat(deviceMonitors)}monitorCountStatuses`);

export const getDownloadLinks = () =>
  getData(`${appConfig.baseUrl.concat(exportUrl)}monitoring/getLinks`);

export default toggleEnabled;
