import React, { useMemo, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import copyText from '@utils/copy';
import appConfig from '@configs/appConfig';
import { actions } from '@store/actions';
import ReusableButton from '@common/Button/Button';
import DateTimeField from '@common/TableComponents/DateTimeField';
import KeyField from '@common/TableComponents/KeyField';
import postWithResponse from '@services/api/common/postWithResponse';
import useTranslation from '@common/hooks/useTranslation';

import ActionField from '@common/ActionFields/ActionField';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import CreateDialog from './CreateDialog';

import useStyles from './styles';

const ApiKeys = ({ resource }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const { getLabel, getText } = useTranslation(resource);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const onCopy = key => {
    copyText(key);
  };

  const onDelete = record => {
    setIdForDelete(record.id);
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setIdForDelete(null);
    setDeleteDialogOpen(false);
    setLoading(false);
  };

  const handleDelete = () => {
    setLoading(true);
    dataProvider
      .delete(resource, { id: idForDelete })
      .then(() => {
        dispatch(actions.removeItemFromList({ id: idForDelete }));
      })
      .catch(e => notify(e.message, 'error'))
      .finally(handleCancelDelete);
  };

  const handleAddKey = model => {
    setLoading(true);
    postWithResponse(`${appConfig.baseUrl}/${resource}`, model)
      .then(data => dispatch(actions.updateItemInList(data)))
      .catch(e => notify(e.message, 'error'))
      .finally(() => setLoading(false));
  };

  const columns = useMemo(
    () => [
      {
        name: getLabel('name'),
        key: 'name',
        sortable: true,
        searchable: true,
      },
      {
        name: getLabel('key'),
        key: 'key',
        isCustomAccessor: true,
        accessor: rowData => (
          <KeyField record={rowData} key="key" source="key" />
        ),
      },
      {
        name: getLabel('date'),
        key: 'expirationDateUtc',
        isCustomAccessor: true,
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="expirationDateUtc"
            source="expirationDateUtc"
            highlightExpired
          />
        ),
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        accessor: rowData => (
          <ActionField
            record={rowData}
            onCopy={onCopy}
            onDelete={onDelete}
            fieldKey="key"
          />
        ),
        width: 100,
      },
    ],
    [getLabel, onCopy],
  );

  return (
    <div className={classes.container}>
      <ReusableButton
        size="md"
        viewType="black"
        label="common.add"
        onClick={() => setCreateDialogOpen(true)}
        classNameWrapper={classes.buttonWrapper}
        disabled={loading}
      />
      <Table
        columns={columns}
        resource={resource}
        defaultSort={{ fieldName: 'name', order: 'ASC' }}
      />
      <ConfirmDialog
        open={!!deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onAccept={handleDelete}
        onCancel={handleCancelDelete}
        title={getText('caption')}
        content={getText('text')}
        loading={loading}
      />
      <CreateDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onCreate={handleAddKey}
      />
    </div>
  );
};

ApiKeys.propTypes = {
  resource: PropTypes.string,
};

export default ApiKeys;
