import crmSources, { crmSourcesByType } from '@constants/crmSources';

const usePodSaveContactRoute = ({ psaType }) => {
  switch (psaType) {
    case crmSources.ConnectWise.label:
      return crmSourcesByType.ConnectWiseRestApi.name;
    case crmSources.Autotask.label:
      return crmSourcesByType.Autotask.name;
    case crmSources.Kaseya.label:
      return crmSourcesByType.Kaseya.name;
    case crmSources.Halo.label:
      return crmSourcesByType.Halo.name;
    case crmSources.Zendesk.label:
      return crmSources.Zendesk.name;
    case crmSources.SuperOps.label:
      return crmSources.SuperOps.name;
    case crmSources.SyncroMsp.label:
      return 'syncro';
    case crmSources.Microsoft.label:
      return 'Microsoft';
    default:
      return 'Unknown type';
  }
};
export default usePodSaveContactRoute;
