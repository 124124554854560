import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  htmlContent: {
    padding: '20px',
    boxSizing: 'border-box',
  },
  buttons: {
    padding: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
  },
}));

export default useStyles;
