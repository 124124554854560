import React, { useMemo, useState } from 'react';

import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import TableActions from './components/TableActions';
import TableItemActions from './components/TableItemActions';
import CreateAutotaskWebhookDialog from './components/CreateAutotaskWebhookDialog';

import useStyles from './styles';

const resource = 'autotaskWebHooks';

const AutotaskNoteToSMS = () => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const classes = useStyles();

  const columns = useMemo(
    () => [
      {
        name: 'ID',
        key: 'id',
        sortable: true,
        searchable: true,
        minWidth: 150,
      },
      {
        sortable: true,
        searchable: true,
        name: 'Integration ID',
        key: 'integrationId',
        minWidth: 150,
      },
      {
        name: 'Integration Name',
        key: 'integrationName',
        sortable: true,
        searchable: true,
        minWidth: 200,
      },
      {
        name: 'Autotask Hook ID',
        key: 'autotaskHookId',
        sortable: true,
        searchable: true,
        minWidth: 150,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 100,
        minWidth: 100,
        accessor: rowData => <TableItemActions record={rowData} />,
      },
    ],
    [],
  );

  return (
    <div className={classes.container}>
      <TableActions
        openDialog={() => {
          setOpenCreateDialog(true);
        }}
      />
      <Table
        resource={resource}
        columns={columns}
        defaultSort={{ fieldName: 'integrationName', order: 'ASC' }}
      />
      {openCreateDialog && (
        <CreateAutotaskWebhookDialog
          open={openCreateDialog}
          setOpen={setOpenCreateDialog}
        />
      )}
    </div>
  );
};

export default AutotaskNoteToSMS;
