import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import crmSources from '@constants/crmSources';
import SMSIcon from '@assets/icons/sms.svg';
import ShieldPersonIcon from '@assets/icons/shield_person.svg';
import SyncIcon from '@assets/icons/secureDataSend.svg';

import ItemMenu from '@common/ItemMenu/ItemMenu';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import CreateMessagingChannelDialog from '@ui/components/Messaging/dialogs/CreateMessagingChannelDialog';
import PodDialog from '@components/PodDialog';

import useStyles from '../../styles';
import {
  TEXT_OPEN_POD_DIALOG,
  TEXT_OPEN_POD_SECURE_DATA,
} from '@constants/texts/pod';

const ZendeskTicketTableActions = ({ record, crmId, crmDefaultId }) => {
  const [openCreateChannelDialog, setOpenCreateChannelDialog] = useState(false);
  const [openSecureDialog, setOpenSecureDialog] = useState(false);
  const [openPodDialog, setOpenPodDialog] = useState(false);

  const classes = useStyles();

  const menuOptions = useMemo(
    () => [
      {
        icon: <img src={SMSIcon} alt="createChannel" />,
        caption: 'Add SMS Channel',
        handler: () => {
          setOpenCreateChannelDialog(true);
        },
      },
    ],
    [],
  );

  return (
    <div className={classes.tableActions}>
      {record?.requesterId && (
        <>
          <ActionFieldItem
            alt={TEXT_OPEN_POD_DIALOG}
            handler={() => {
              setOpenPodDialog(true);
            }}
            toolTip={TEXT_OPEN_POD_DIALOG}
            icon={ShieldPersonIcon}
            ignoreDefaultIconSize
          />
          <ActionFieldItem
            alt={TEXT_OPEN_POD_SECURE_DATA}
            handler={() => setOpenSecureDialog(true)}
            toolTip={TEXT_OPEN_POD_SECURE_DATA}
            icon={SyncIcon}
            ignoreDefaultIconSize
          />
        </>
      )}

      <ItemMenu options={menuOptions} itemsHeightMultiplier={10} openOnHover />

      {openPodDialog && record.requesterId && (
        <PodDialog
          itemId={record.id}
          onClose={() => {
            setOpenPodDialog(false);
          }}
          itemType="ticket"
          psaId={crmId}
          psa={crmSources.Zendesk.label}
        />
      )}

      {openCreateChannelDialog && (
        <CreateMessagingChannelDialog
          open={openCreateChannelDialog}
          setOpen={setOpenCreateChannelDialog}
          record={record}
          crmDefaultId={crmDefaultId}
          crmType={crmSources.Zendesk.name}
        />
      )}

      {openSecureDialog && record.requesterId && (
        <PodDialog
          itemId={record.id}
          onClose={() => {
            setOpenSecureDialog(false);
          }}
          itemType="ticket"
          psaId={crmId}
          psa={crmSources.Zendesk.label}
          page="secureData"
        />
      )}
    </div>
  );
};

ZendeskTicketTableActions.propTypes = {
  record: PropTypes.shape({
    ticketNumber: PropTypes.string.isRequired,
    crmDefaultId: PropTypes.number.isRequired,
    requesterId: PropTypes.number,
    id: PropTypes.number.isRequired,
  }),
  crmDefaultId: PropTypes.number,
  crmId: PropTypes.number,
};
export default ZendeskTicketTableActions;
