import { enqueueSnackbar } from 'notistack';
import { TEXT_COPIED_TO_CLIPBOARD } from '@constants/texts/common';

const copyText = text =>
  navigator.clipboard.writeText(text).then(() => {
    try {
      enqueueSnackbar(TEXT_COPIED_TO_CLIPBOARD, { variant: 'success' });
    } catch (error) {
      console.error('Failed to copy: ', error);
    }
  });

export default copyText;
