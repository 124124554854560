import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  container: {
    margin: '16px 16px 0',
    position: 'relative',
    '& tbody': {
      maxHeight: '65vh',
    },
  },
  tableActionContainer: {
    width: '40%',
    margin: '20px 26px',
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    zIndex: 22,
  },
  liveChatDialogHeader: {
    '& > h2': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  liveChatHeaderVerified: {
    fontSize: '15px',
    fontWeight: '500 !important',
    color: '#118b2ead',
  },
  liveChatContainer: {
    alignSelf: 'center',
    marginTop: '1rem',
    height: '100%',
    maxHeight: '100%',
    display: 'grid',
    gridTemplateRows: '20px 1fr',
    minWidth: '50%',
  },
  createTicketContainer: {
    padding: '20px',
    display: 'flex',
  },
  item: {
    margin: '0.5rem',
  },
  userMessagesContainer: {
    padding: '0.7rem',
    height: '80vh',
    borderRadius: '15px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  userMessagesHeader: {
    textAlign: 'center',
    margin: '1rem',
  },
  title: {
    textAlign: 'center',
  },
  loadingWrapper: {
    height: '300px',
  },
  list: {
    maxHeight: '450px',
    overflow: 'scroll',
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px',
  },
  dropDownWrapper: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  dropDown: {
    width: '365px',
  },
}));

export default useStyles;
