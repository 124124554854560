import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import cx from 'classnames';
import moment from 'moment';

import ViewAutoComplete from '@common/ViewAutoComplete/ViewAutoComplete';
import useAsyncDispatch from '@services/api/common/useAsyncDispatch';
import { actions } from '@store/actions';
import { smsInfo, tenantOptions } from '@store/selectors/smsList';
import { defaultDateFormat } from '@constants/formats';
import { getInfo, getTenants } from './helpers';
import { useTableContext } from '../../../../hooks/table';

import useStyles from './styles';

const getDate = value =>
  value
    ? moment
        .utc(value)
        .local()
        .format(defaultDateFormat)
    : '';

const statisticArray = [
  {
    key: 1,
    label: 'Subscription starts at:',
    field: 'subscriptionStart',
    transformToDate: true,
  },
  { key: 2, label: 'Total sent:', field: 'usageTotal' },
  { key: 3, label: 'Successfully delivered:', field: 'usageSuccess' },
  { key: 4, label: 'Subscription quota:', field: 'quota' },
];

const TenantPanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const options = useSelector(tenantOptions);
  const info = useSelector(smsInfo);
  const loadData = useAsyncDispatch(getTenants, actions.smsList_setOptions);
  const { setFilteringObject, filtering } = useTableContext();

  useEffect(() => {
    if (value) {
      setLoading(true);
      getInfo(value.id)
        .then(d => dispatch(actions.smsList_setInfo(d)))
        .catch(e => notify(e.message, 'error'))
        .finally(() => setLoading(false));
    }
  }, [dispatch, notify, value]);

  const handleChange = item => {
    setValue(item);
    setFilteringObject(filtering, 'tenantId', item?.id);
  };

  return (
    <div className={cx(classes.border, classes.displayFlex)}>
      <ViewAutoComplete
        options={options}
        onChange={handleChange}
        currentView={value}
        optionLabel="value"
        textFieldLabel="Tenant"
        loading={loadData}
      />
      {value && !loading && (
        <div className={classes.displayFlex}>
          {statisticArray.map(i => (
            <div className={classes.marginEnd} key={i.key}>
              <span className={cx(classes.marginEnd, classes.bold)}>
                {i.label}
              </span>
              <span>
                {i.transformToDate ? getDate(info[i.field]) : info[i.field]}
              </span>
              <span>;</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TenantPanel;
