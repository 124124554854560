import React from 'react';
import PropTypes from 'prop-types';

import getRecordColor from '@components/Helpers/getRecordColor';

import useStyles from '@components/DeviceMonitors/components/Details/styles';

const Percentage = ({ successRate }) => {
  const statusColor = getRecordColor(Math.max(1 - successRate / 100, 0.1));
  const classes = useStyles({ statusColor });
  const text = `${successRate}%`;

  return (
    <div className={classes.statusBlock}>
      <span className={classes.colorBlock}>{text}</span>
    </div>
  );
};

Percentage.propTypes = {
  successRate: PropTypes.number,
};

export default Percentage;
