export const helpdeskLanguages = [
  {
    label: 'English',
    value: 0,
  },
  {
    label: 'French',
    value: 1,
  },
  {
    label: 'Spanish',
    value: 2,
  },
];

export default {};
