import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';

import resources from '@constants/resources';
import { sortOrders } from '@constants/filters';
import { defaultDateFormat } from '@constants/formats';
import useTranslatedText from '@common/hooks/useTranslatedText';
import DateTimeField from '@common/TableComponents/DateTimeField';
import useAsync from '@services/api/common/useAsync';
import { getHintUsers } from '@components/Audit/helpers';
import {
  addIncidentAssignee,
  deleteIncident,
} from '@components/Triggers/helpers';
import { actions } from '@store/actions';
import { listSelector } from '@store/selectors';
import StatusesAccessor from '@common/MuiVirtualizedTable/components/accessors/StatusesAccessor';
import {
  incidentStatuses,
  triggerPriorities,
} from '@components/Triggers/components/helpers';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import useTranslation from '@common/hooks/useTranslation';
import { TableTypes } from '@components/Table/constants';
import Table from '@components/Table';
import ActionField from './components/ActionField';
import BulkActions from './components/BulkActions';
import SourceTableItem from './components/SourceTableItem';

import useStyles from '../../styles';

const Incidents = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getFieldText } = useTranslatedText(resources.incidents);
  const [users, setUsers] = useState([]);
  const [groupBy, setGroupBy] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  useAsync(getHintUsers, setUsers);
  const list = useSelector(listSelector);
  const { getText } = useTranslation('incidents');

  const handleAssignDialogSelectValue = useCallback(
    (assigneeId, recordId) => {
      addIncidentAssignee(assigneeId, recordId)
        .then(res => {
          res.forEach(item => dispatch(actions.updateItemInList(item)));
          enqueueSnackbar('Assignment completed', { variant: 'success' });
        })
        .catch(e => enqueueSnackbar(e.message, { variant: 'error' }));
    },
    [list, users, dispatch],
  );

  const handleDelete = id => {
    setIdForDelete(id);
    setDeleteDialogOpen(true);
  };

  const onCancelDeleteHandler = () => {
    setIdForDelete(null);
    setDeleteDialogOpen(false);
  };

  const onDeleteHandler = useCallback(() => {
    deleteIncident(idForDelete)
      .then(() => {
        dispatch(actions.removeItemFromList({ id: idForDelete }));
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(onCancelDeleteHandler);
  }, [idForDelete, dispatch]);

  useEffect(() => {
    if (groupBy === 'priority') {
      document
        .querySelectorAll('.rdg-group-cell-content')
        .forEach(i => (i.textContent = triggerPriorities[i.textContent].label));
    }
    if (groupBy === 'dateTriggered') {
      document.querySelectorAll('.rdg-group-cell-content').forEach(
        i =>
          (i.textContent = moment
            .utc(i.textContent)
            .local()
            .format(defaultDateFormat)),
      );
    }
    if (groupBy === 'tags') {
      document
        .querySelectorAll('.rdg-group-cell-content')
        .forEach(i => (i.textContent = i.textContent.split(',').join(', ')));
    }
  }, [groupBy]);

  const columns = useMemo(
    () => [
      {
        name: 'Id',
        key: 'id',
        width: 70,
        minWidth: 70,
        sortable: true,
      },
      {
        name: getFieldText('incidentStatus'),
        key: 'incidentStatus',
        searchable: true,
        isCustomAccessor: true,
        type: TableTypes.dropdown,
        dropDownValues: incidentStatuses,
        filterByAutocomplete: 'incidentStatus',
        minWidth: 220,
        accessor: row => (
          <StatusesAccessor
            item={row.incidentStatus}
            array={incidentStatuses}
          />
        ),
      },
      {
        name: 'Priority',
        key: 'priority',
        isCustomAccessor: true,
        sortable: true,
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: triggerPriorities,
        filterByAutocomplete: 'priority',
        accessor: row => (
          <StatusesAccessor item={row.priority} array={triggerPriorities} />
        ),
        minWidth: 220,
      },
      {
        name: getFieldText('emailSubject'),
        key: 'emailSubject',
        sortable: true,
        searchable: true,
        minWidth: 200,
      },
      {
        name: getFieldText('assigneeName'),
        key: 'assigneeName',
        sortable: true,
        searchable: true,
        minWidth: 200,
      },
      {
        name: getFieldText('tags'),
        key: 'tags',
        isCustomAccessor: true,
        sortable: true,
        searchable: true,
        minWidth: 220,
        accessor: rowData => rowData.tags?.join(', '),
      },
      {
        name: getFieldText('dateTriggered'),
        key: 'dateTriggered',
        isCustomAccessor: true,
        sortable: true,
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="dateTriggered"
            source="dateTriggered"
            label={getFieldText('dateTriggered')}
            sortable
          />
        ),
        minWidth: 200,
      },
      {
        name: getFieldText('sourceName'),
        key: 'sourceName',
        sortable: true,
        searchable: true,
        isCustomAccessor: true,
        accessor: rowData => <SourceTableItem rowData={rowData} />,
        minWidth: 220,
      },
      {
        name: getFieldText('lastEscalationDate'),
        sortable: true,
        key: 'lastEscalationDate',
        isCustomAccessor: true,
        accessor: rowData => (
          <DateTimeField
            record={rowData}
            key="lastEscalationDate"
            source="lastEscalationDate"
            label={getFieldText('lastEscalationDate')}
            sortable
          />
        ),
        minWidth: 200,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        accessor: rowData => (
          <ActionField
            record={rowData}
            users={users}
            handleAssign={handleAssignDialogSelectValue}
            handleDelete={handleDelete}
          />
        ),
        width: 200,
        minWidth: 200,
      },
    ],
    [getFieldText, users, handleAssignDialogSelectValue],
  );

  return (
    <div className={classes.tableWrapper}>
      {/* <TableActions setGroupBy={setGroupBy} users={users} /> // will */}
      {/*  returned in another PR */}
      <Table
        columns={columns}
        resource={resources.incidents}
        defaultSort={{
          fieldName: 'dateTriggered',
          order: sortOrders.desc,
        }}
        classNameWrapper={classes.container}
        bulkActionsClassName={classes.bulkActionsClassName}
        bulkActions={<BulkActions users={users} />}
        groupByValue={groupBy}
      />
      {!!deleteDialogOpen && (
        <ConfirmDialog
          open
          setOpen={setDeleteDialogOpen}
          onAccept={onDeleteHandler}
          onCancel={onCancelDeleteHandler}
          title={getText('deleteCaption')}
          content={getText('deleteText')}
        />
      )}
    </div>
  );
};

export default Incidents;
