import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

import { TEXT_DELETE_SUCESS } from '@constants/texts/common';

import deleteIcon from '@assets/icons/deleteWhite.svg';
import ReusableButton from '@common/Button/Button';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';

import { ThunkDeleteSmsBlackListNumbers } from '@store/slices/smsBlackList/thunks';
import { actions } from '@store/actions';
import { useTableContext } from '../../../../../hooks/table';

import useStyles from '../styles';

const BulkActionButtons = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { selectedIds, deselectAllItems } = useTableContext();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleDelete = () => {
    setProcessing(true);
    dispatch(ThunkDeleteSmsBlackListNumbers(selectedIds))
      .unwrap()
      .then(() => {
        dispatch(actions.removeItemFromList({ selectedIds }));
        deselectAllItems();
        enqueueSnackbar(TEXT_DELETE_SUCESS, { variant: 'success' });
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => {
        setOpenDeleteDialog(false);
        setProcessing(false);
      });
  };

  return (
    <>
      <ReusableButton
        onClick={() => setOpenDeleteDialog(true)}
        viewType="red"
        classNameWrapper={classes.btnWrapper}
        size="xl"
      >
        <>
          <img src={deleteIcon} alt="deleteIcon" />
          Remove
        </>
      </ReusableButton>
      {openDeleteDialog && (
        <ConfirmDialog
          loading={processing}
          onAccept={() => {
            handleDelete();
          }}
          onCancel={() => {
            setOpenDeleteDialog(false);
          }}
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          title="Confirm Action"
          content="Are you sure you want to delete the selected items?"
        />
      )}
    </>
  );
};

export default BulkActionButtons;
