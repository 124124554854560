import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Table from '@components/Table/Table';
import { TableTypes } from '@components/Table/constants';
import ConfirmDialog from '@common/ConfirmDialog/ConfirmDialog';
import ActionButton from '@common/buttons/ActionButton/ActionButton';
import iconDelete from '@assets/icons/delete.svg';
import { actions } from '@store/actions';
import { ThunkDeletePhoneNumber } from '@store/slices/phoneNumbers/thunks/deletePhoneNumber';
import useStyles from './styles';

const MessagingNumbers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deleteDialogData, setDeleteDialogData] = useState({ isOpen: false });

  const handleDeleteUser = async () => {
    setDeleteDialogData({ isOpen: false });
    await dispatch(ThunkDeletePhoneNumber({ id: deleteDialogData.id }));
    dispatch(actions.removeItemFromList({ id: deleteDialogData.id }));
  };
  const columns = useMemo(
    () => [
      {
        name: 'Number',
        key: 'number',
        sortable: true,
        searchable: true,
        minWidth: 200,
        isPhoneNumber: true,
        type: TableTypes.phoneNumber,
      },
      {
        name: 'Country',
        key: 'country',
        width: 150,
        sortable: true,
        searchable: true,
      },
      {
        name: 'Type',
        key: 'type',
        width: 150,
        sortable: true,
        searchable: true,
        isUpperCase: true,
      },
      {
        name: 'Status',
        key: 'status',
        sortable: true,
        searchable: true,
      },
      {
        name: 'Tenant',
        key: 'tenantName',
        minWidth: 200,
        sortable: true,
        searchable: true,
      },
      {
        name: 'Provider',
        key: 'provider',
        width: 200,
        sortable: true,
        searchable: true,
      },
      {
        name: 'Description',
        key: 'description',
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 100,
        accessor: item => (
          <ActionButton
            icon={<img src={iconDelete} alt="iconDelete" />}
            handler={() => setDeleteDialogData({ isOpen: true, id: item.id })}
            disabled={!item.id}
          />
        ),
      },
    ],
    [],
  );
  return (
    <div className={classes.container}>
      <Table
        columns={columns}
        resource="MessagingPhoneNumbers/numbers/report/v2"
        defaultSort={{ fieldName: 'tenantName', order: 'ASC' }}
      />
      <ConfirmDialog
        open={deleteDialogData.isOpen}
        onAccept={handleDeleteUser}
        onCancel={() => setDeleteDialogData({ isOpen: false })}
        title="Are you sure to delete this phone number?"
        content="This operation can not be undone."
      />
    </div>
  );
};

export default MessagingNumbers;
