import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import ReusableButton from '@common/Button/Button';

import useStyles from '../styles';

const GenericSourcesStackTraceDetails = ({ open, setOpen, stackTrace }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <div className={classes.logDetailHeader}>
          <span>Stack trace</span>
          <ReusableButton
            label="Close"
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent>{stackTrace}</DialogContent>
    </Dialog>
  );
};

GenericSourcesStackTraceDetails.propTypes = {
  stackTrace: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default GenericSourcesStackTraceDetails;
