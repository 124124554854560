import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import isHtml from 'is-html';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Menu, MenuItem } from '@material-ui/core';

import {
  ThunkToggleConnectWiseTicketIssue,
  ThunkToggleConnectWiseTicketResolution,
} from '@store/slices/connectWiseTicket/thunks';
import getFormattedDate from '@utils/getFormattedDate';

import { ReactComponent as MenuIcon } from '@assets/icons/menu.svg';
import EditIcon from '@assets/icons/edit.svg';
import DeleteIcon from '@assets/icons/delete.svg';
import ActionFieldItem from '@common/ActionButtons/ActionFieldItem';
import EditNoteDialog from '@components/CrmTicket/Notes/dialogs/EditNoteDialog';

import DeleteNoteDialog from '@components/CrmTicket/Notes/dialogs/DeleteNoteDialog';
import { useParams } from 'react-router-dom';
import useStyles from '../styles';

const TicketNote = React.memo(({ note, psaType }) => {
  const [openEditNoteDialog, setOpenEditNoteDialog] = useState(false);
  const [openDeleteNoteDialog, setOpenDeleteNoteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  const classes = useStyles();
  const dispatch = useDispatch();
  const { ticketId, crmDefaultId } = useParams();
  const handleResolutionChange = (noteId, resolutionState) => {
    dispatch(
      ThunkToggleConnectWiseTicketResolution({
        crmDefaultId,
        ticketId,
        noteId,
        resolutionState,
      }),
    );
    setAnchorEl(null);
  };

  const handleIssueChange = (noteId, issueState) => {
    dispatch(
      ThunkToggleConnectWiseTicketIssue({
        crmDefaultId,
        ticketId,
        noteId,
        issueState,
      }),
    );
    setAnchorEl(null);
  };

  const availableActions = [
    {
      type: 'edit',
      component: () => (
        <ActionFieldItem
          handler={() => {
            setOpenEditNoteDialog(true);
          }}
          alt="Edit"
          toolTip="Edit"
          icon={EditIcon}
        />
      ),
    },
    {
      type: 'delete',
      component: () => (
        <ActionFieldItem
          handler={() => {
            setOpenDeleteNoteDialog(true);
          }}
          alt="Delete"
          toolTip="Delete"
          icon={DeleteIcon}
        />
      ),
    },
    {
      type: 'context',
      component: () => (
        <div>
          <MenuIcon
            onClick={e => {
              setAnchorEl(e.currentTarget);
            }}
            style={{ cursor: 'pointer' }}
          />

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                handleResolutionChange(note.id, !note.resolutionFlag);
              }}
            >
              Toggle Resolution
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleIssueChange(note.id, !note.issueFlag);
              }}
            >
              Toggle Issue
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  const haveHtmlContent = note?.description && isHtml(note?.description);

  const noteActions = availableActions
    .filter(action => note.actions.includes(action.type))
    .filter(action => action.type !== 'edit' || !haveHtmlContent);

  return (
    <div className={classes.note}>
      <div className={classes.noteLabelRow}>
        <div style={{ display: 'flex', gap: '5px' }}>
          {!!note?.labels.length &&
            note.labels.map(label => (
              <span
                key={label}
                style={{ color: label.color }}
                className={classes.noteLabels}
              >
                {label.text}
              </span>
            ))}
        </div>
        <div style={{ display: 'flex' }}>
          {noteActions.map(action => action.component())}
        </div>
      </div>
      <div className={classes.noteTitle}>{note?.title && note.title}</div>
      <div style={{ overflow: 'auto' }}>
        {haveHtmlContent ? (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                note?.description
                  .replaceAll('&lt;', '<')
                  .replaceAll('&gt;', '><br />'),
              ),
            }}
          />
        ) : (
          <>{note?.description}</>
        )}
      </div>
      <div className={classes.noteAuthorDate}>
        <div>{note?.createdBy}</div>
        {note?.dateCreated && <div>{getFormattedDate(note?.dateCreated)}</div>}
      </div>

      {openEditNoteDialog && (
        <EditNoteDialog
          open={openEditNoteDialog}
          setOpen={setOpenEditNoteDialog}
          psaType={psaType}
          noteId={note.id}
        />
      )}
      {openDeleteNoteDialog && (
        <DeleteNoteDialog
          open={openDeleteNoteDialog}
          setOpen={setOpenDeleteNoteDialog}
          psaType={psaType}
          noteId={note.id}
        />
      )}
    </div>
  );
});

TicketNote.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    createdBy: PropTypes.string,
    dateCreated: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.string),
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        component: PropTypes.element,
      }),
    ),
  }).isRequired,
  psaType: PropTypes.string,
};

export default TicketNote;
