import React, { useState } from 'react';

import { FormControlLabel } from '@material-ui/core';

import Switch from '@common/FilterInputs/Switch';

import useStyles from './styles';
import { useTableContext } from '../../../../hooks/table';

const LiveChatActions = () => {
  const classes = useStyles();

  const [skipClosed, setSkipClosed] = useState({ value: true });
  const [onlyMine, setOnlyMine] = useState({ value: false });

  const { setFilteringObject, filtering } = useTableContext();

  const handleChangeSkipClosed = item => {
    setSkipClosed(item);
    setFilteringObject(filtering, 'isClosed', item?.value ? 'false' : '');
  };

  const handleChangeOnlyMine = item => {
    setOnlyMine(item);
    setFilteringObject(filtering, 'onlyMine', item?.value ? 'true' : '');
  };

  return (
    <div className={classes.tableActionsWrapper}>
      <FormControlLabel
        style={{ alignItems: 'flex-end' }}
        control={
          <Switch
            input={{
              onChange: () => {
                handleChangeSkipClosed({ value: !skipClosed.value });
              },
              value: skipClosed.value,
            }}
            checked={skipClosed.value}
          />
        }
        label="Skip Closed"
        labelPlacement="start"
      />
      <FormControlLabel
        style={{ alignItems: 'flex-end' }}
        control={
          <Switch
            input={{
              onChange: () => {
                handleChangeOnlyMine({ value: !onlyMine.value });
              },
              value: onlyMine.value,
            }}
            checked={onlyMine.value}
          />
        }
        label="Only Mine"
        labelPlacement="start"
      />
    </div>
  );
};

export default LiveChatActions;
