import React from 'react';
import PropTypes from 'prop-types';
import { Pause, PlayArrow } from '@material-ui/icons';
import useStyles from '@components/DeviceMonitors/components/styles';
import toggleEnabled from '@components/DeviceMonitors/helpers';
import { actions } from '@store/actions';
import { useDispatch, useSelector } from 'react-redux';

const RunningState = ({ row, handleClick }) => {
  const Icon = row.enabled ? PlayArrow : Pause;
  const classes = useStyles();
  const dispatch = useDispatch();
  const list = useSelector(state => state.list.list);

  const handleMakeDefault = async (id, isActive) => {
    try {
      await toggleEnabled(id, !isActive);
      const el = list.find(item => item.id === id);
      el.enabled = !el.enabled;
      dispatch(actions.saveList(list));
    } catch (e) {
      console.error(e.message);
    }
  };

  return (
    <div className={classes.runningStateContainer}>
      <Icon
        className={classes.runningStateIcon}
        onClick={() => handleMakeDefault(row.id, row.enabled)}
      />
    </div>
  );
};
RunningState.propTypes = {
  row: PropTypes.objectOf(PropTypes.any),
  handleClick: PropTypes.func,
};

export default RunningState;
