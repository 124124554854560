import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useAuthenticated,
  useAuthProvider,
  useGetOne,
  useRedirect,
} from 'react-admin';
import _ from 'lodash';

import getData from '@services/api/common/getData';
import { defaultPalette, setPalette } from '@services/themes/mainTheme';
import { actions } from '@store/actions';
import appConfig from '@configs/appConfig';
import { getTenantPalettes } from '@components/Settings/CustomPalette/helpers';

import SimpleDialog from '@ui/components/common/Dialog/SimpleDialog';

import { pricePlanMyPlan } from '@constants/appRoutes';
import { BILLING } from '@constants/routes';
import resources from '@constants/resources';

import { Button } from '@material-ui/core';
import { TypeUserData } from '@common/propTypes/common';

import useStyles from './styles';

const getPricePlanInfo = () =>
  getData(`${appConfig.baseUrl}${pricePlanMyPlan}`);

const UserName = ({ userData }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const redirect = useRedirect();

  const authProvider = useAuthProvider();

  const id = authProvider.getUserId();

  useAuthenticated();

  const { data, loading, refetch } = useGetOne(resources.user, id);

  useEffect(() => {
    refetch();
  }, [refetch, id]);

  useEffect(() => {
    getPricePlanInfo()
      .then(d => dispatch(actions.setPricePlan(d)))
      .catch(e => console.error(e.message));
    getTenantPalettes()
      .then(d =>
        setPalette(
          _.isEmpty(JSON.parse(d.palette))
            ? defaultPalette
            : JSON.parse(d.palette),
        ),
      )
      .catch(e => console.error(e.message));
  }, [dispatch]);

  useEffect(() => {
    if (data && !data.isSubscriptionEnabled) {
      setOpen(true);
    } else {
      localStorage.setItem('tenantId', data?.tenantId);
      localStorage.setItem('roleId', data?.roleId);
      localStorage.setItem('profileImage', data?.imageUri);
      dispatch(actions.setCurrentUser(data));
    }
  }, [data, dispatch]);

  if (loading) return null;

  const userName = userData?.username || data?.username;

  return (
    <div className={classes.container}>
      <span>{userName}</span>

      <SimpleDialog
        open={open}
        setOpen={setOpen}
        title="Your subscription is inactive"
      >
        <div>
          Your subscription is inactive. One of the possible reasons: Invalid
          credit card data.
          <br />
          Try to update your credit card data in the
          <Button
            className={classes.link}
            type="text"
            onClick={() => {
              redirect(BILLING.concat('?page=creditCard'));
              setOpen(false);
            }}
          >
            Credit Card page.
          </Button>
        </div>
      </SimpleDialog>
    </div>
  );
};

UserName.propTypes = {
  userData: TypeUserData,
};

export default UserName;
