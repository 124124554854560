import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { useSnackbar } from 'notistack';

import {
  ThunkResetUserPassword,
  ThunkVerifyResetPasswordCode,
} from '@store/slices/auth/thunks';
import { LOGIN_ROUTE } from '@constants/routes';
import { requiredValidator } from '@utils/validators';

import UnauthorizedProvider from '@ui/common/UnauthorizedProvider/UnauthorizedProvider';
import LoginPageBanner from '@ui/common/LoginPageBanner';
import AppLogo from '@ui/common/AppLogo';
import FieldWrapper from '@common/form/FieldWrapper';
import Input from '@components/Auth/Common/Input';
import ReusableButton from '@common/Button/Button';
import Loading from '@common/Loading/Loading';

import useSharedStyles from '../Login/styles';
import useStyles from '../ForgotPassword/styles';

const ResetPassword = ({ location: { search } }) => {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(undefined);

  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const code = useMemo(() => urlParams.get('code'), []);
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const validate = values => ({
    password: requiredValidator(values.password),
    confirmPassword:
      values.confirmPassword !== values.password
        ? 'Password not matching'
        : undefined,
  });

  const submit = values => {
    setProcessing(true);
    const payload = {
      password: values.password,
      token: code,
    };

    dispatch(ThunkResetUserPassword({ payload }))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Password Reset Successfully', { variant: 'success' });
        push(LOGIN_ROUTE);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkVerifyResetPasswordCode({ code }))
      .unwrap()
      .catch(err => {
        setError(err.message.split('RequestError: ')[1]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [code, dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;
  return (
    <UnauthorizedProvider>
      <div className={sharedClasses.container}>
        <LoginPageBanner />
        <div className={classes.activeContainer}>
          <AppLogo />
          <Form
            onSubmit={submit}
            validate={validate}
            render={({ handleSubmit }) => (
              <div>
                <form onSubmit={handleSubmit}>
                  <div>{error ?? 'Please enter new password.'}</div>
                  {!error && (
                    <div>
                      <FieldWrapper
                        label="Password"
                        labelSize={12}
                        contentSize={12}
                        fullWidth
                        content={
                          <Field
                            placeholder="Enter your password here"
                            name="password"
                            id="password"
                            type="password"
                            render={Input}
                            disabled={processing}
                          />
                        }
                      />
                      <FieldWrapper
                        label="Confirm Password"
                        labelSize={12}
                        contentSize={12}
                        fullWidth
                        content={
                          <Field
                            placeholder="Enter your pasword here"
                            name="confirmPassword"
                            id="confirmPassword"
                            type="password"
                            render={Input}
                            disabled={processing}
                          />
                        }
                      />
                    </div>
                  )}
                  <div className={classes.buttons}>
                    <ReusableButton
                      label="Back"
                      onClick={() => {
                        push(LOGIN_ROUTE);
                      }}
                      disabled={processing}
                    />
                    {!error && (
                      <ReusableButton
                        label="Send"
                        type="submit"
                        viewType="blue"
                        disabled={processing}
                        loading={processing}
                      />
                    )}
                  </div>
                </form>
              </div>
            )}
          />
        </div>
      </div>
    </UnauthorizedProvider>
  );
};

export default ResetPassword;
