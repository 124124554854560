import { createAsyncThunk } from '@reduxjs/toolkit';
import { LOG_RECORDS } from '@store/slices/resources';
import { getGetLogRecordTypes } from '@services/requests/logRecords';

export const ThunkGetLogRecordTypes = createAsyncThunk(
  `${LOG_RECORDS}/getTypes`,
  async () => {
    const result = await getGetLogRecordTypes();
    return result;
  },
);
