export const events = {
  0: { name: 'Success', color: '#67AD5B' },
  1: { name: 'Failure', color: '#EC6A4E' },
  2: { name: 'Quota exceeded', color: '#F0BD3A' },
};

export const eventsOptions = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Success',
    value: 0,
  },
  {
    label: 'Failure',
    value: 1,
  },
  {
    label: 'Quota exceeded',
    value: 2,
  },
];

export default {};
