import React, { useMemo, useState } from 'react';
import { useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import ReusableButton from '@common/Button/Button';
import ClientPortalSupportInfo from '@components/ClientPortal/dialogs/ClientPortalSupportInfo';
import TabPanel from '@common/TabPanel/TabPanel';
import Chats from '@components/ClientPortal/components/Chats';
import General from '@components/ClientPortal/components/General';
import maxLengthValidator from '@utils/validators/maxLengthValidator';
import { getTenantImageFromStorage } from '@services/api';
import {
  emailValidator,
  urlValidator,
  phoneWithCharactersValidator,
  requiredValidator,
} from '@utils/validators';
import {
  ThunkAddClientPortalConfigurations,
  ThunkEditClientPortalConfigurations,
} from '@store/slices/clientPortalAdmin/thunks';
import { validateRequired } from '@common/functions/validators';

import useSharedStyles from '@components/CrmTicket/sharedStyles';

const defaultFormValues = Object.freeze({
  domain: '',
  crmId: undefined,
  portalName: '',
  portalColorScheme: '',
  defaultId: undefined,
  notifyAdmin: false,
  notifyAdminInMin: 0,
  clientPortalAdmins: [],
  putEachMessageToTicketNote: false,
  messageAsInternalNote: false,
  allowToReOpen: false,
  closeTicketWhenCloseChat: false,
  isActive: false,
});

const ClientPortalConfigDialog = ({ open, setOpen, item = undefined }) => {
  const classes = useSharedStyles();

  const dispatch = useDispatch();
  const notify = useNotify();

  const [processing, setProcessing] = useState(false);
  const [clientPortalId, setClientPortalId] = useState();

  const isEditMode = !!(clientPortalId || item?.id);

  const validate = values => {
    const result = {
      portalName: requiredValidator(values.portalName),
      // domain: urlValidator(
      //   values.domain ? `https://${values.domain}.mspprocess.net` : '',
      // ),
      crmId: validateRequired(values.crmId),
      defaultId: validateRequired(values.defaultId),
      defaultIssueTypeName: maxLengthValidator(values.defaultIssueTypeName, 50),
      techCompanyName: maxLengthValidator(values.techCompanyName, 50, false),
      techName: maxLengthValidator(values.techName, 50, false),
      techPhone: phoneWithCharactersValidator(values.techPhone || ''),
      techEmail: values.techEmail
        ? emailValidator(values.techEmail)
        : undefined,
      afterHoursTechPhone: phoneWithCharactersValidator(
        values.afterHoursTechPhone || '',
      ),
      afterHoursTechEmail: phoneWithCharactersValidator(
        values.afterHoursTechEmail || '',
      ),
    };

    if (values.isActive) {
      result.clientPortalAdmins = values.clientPortalAdmins?.length
        ? undefined
        : 'Required';
    }

    if (values.logToEmailOnClose) {
      result.emailForLogs = emailValidator(values.emailForLogs);
    }

    return result;
  };

  const submitThunks = useMemo(
    () =>
      item || clientPortalId
        ? ThunkEditClientPortalConfigurations
        : ThunkAddClientPortalConfigurations,
    [clientPortalId, item],
  );

  const submit = async values => {
    const { isActive, createLogoUrl, ...formValues } = values;
    setProcessing(true);
    const payload = {
      ...formValues,
      notifyAdminInMin: +values.notifyAdminInMin,
      // domain: `https://${values.domain}.mspprocess.net`,
      domain: values.domain,
      verificationTechType: 2, // set default value - Confirm code on Technician side
    };

    await dispatch(
      submitThunks(
        isEditMode
          ? { ...payload, id: clientPortalId || item?.id }
          : { payload },
      ),
    )
      .unwrap()
      .then(res => {
        if (!clientPortalId) {
          setClientPortalId(res?.id);
        }
        notify(
          `Client Portal Configuration was ${
            isEditMode ? 'updated' : 'created'
          }`,
          'success',
        );
      })
      .catch(error => {
        notify(error.message, 'error');
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const extractSubdomain = url => {
    if (!url) {
      return '';
    }
    const regex = /https?:\/\/([^.]+)\./;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>
        {`${isEditMode ? 'Edit' : 'Create'} Client Portal Configuration`}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Form
          onSubmit={submit}
          validate={validate}
          initialValues={
            (item
              ? {
                  ...item,
                  isActive: !!item.clientPortalAdmins?.length,
                  // domain: extractSubdomain(item.domain),
                }
              : undefined) || {
              ...defaultFormValues,
              portalLogo: getTenantImageFromStorage(),
            }
          }
          render={({ handleSubmit, valid }) => (
            <form onSubmit={handleSubmit}>
              <TabPanel
                classNameWrapperTabs={cx(classes.tabs, {
                  [classes.disabled]: !valid,
                })}
                disabled={!valid}
                classNameContentWrapper={classes.content}
                tabs={[
                  {
                    label: 'General',
                    value: (
                      <General item={item} clientPortalId={clientPortalId} />
                    ),
                  },
                  {
                    label: 'Chats',
                    value: <Chats />,
                  },
                  {
                    label: 'Contact Info',
                    value: <ClientPortalSupportInfo />,
                  },
                ]}
                viewTypeLink="flooded"
                isTransparent
              />
              <div className={classes.dialogActionsContainer}>
                <ReusableButton
                  label="Close"
                  onClick={() => {
                    setOpen(false);
                  }}
                  disabled={processing}
                />

                <ReusableButton
                  label="Submit"
                  type="submit"
                  loading={processing}
                  disabled={processing}
                />
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

ClientPortalConfigDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  item: PropTypes.shape({
    domain: PropTypes.string,
    psaId: PropTypes.number,
  }),
};

export default ClientPortalConfigDialog;
