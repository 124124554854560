import getData from '@services/api/common/getData';
import appConfig from '@configs/appConfig';
import {
  auditViewTemplateVariables,
  dataViews,
  dmVoiceSettings,
  emailSettings,
  ftpConnections,
  ftpSettings,
  notificationGroups,
  notificationSettings,
  policy,
  slackSettings,
  smsSettings,
  teamsSettings,
  ticketSettings,
  triggers,
  typeValues,
  voiceSettings,
  webHookSettings,
} from '@constants/appRoutes';
import postData from '@services/api/common/postData';
import putData from '@services/api/common/putData';
import deleteData from '@services/api/common/deleteData';
import postWithResponse from '@services/api/common/postWithResponse';
import deleteWithResponse from '@services/api/common/deleteWithResponse';

const postItem = (name, id, data) =>
  postData(`${appConfig.baseUrl}${name}${id}`, data);

const testItem = (name, data) =>
  postWithResponse(`${appConfig.baseUrl}${name}testUrl`, data);

export const sanitizeProps = ({
  hideFilter,
  displayedFilters,
  defaultTitle,
  toolTip,
  currentSort,
  ...sanitizedProps
}) => sanitizedProps;

export const getSettingById = id =>
  getData(`${appConfig.baseUrl.concat(notificationSettings)}${id}`);

export const getAuditViewsAutocomplete = () =>
  getData(`${appConfig.baseUrl}${notificationSettings}auditViews`);

export const getTriggersAutocomplete = () =>
  getData(`${appConfig.baseUrl}${triggers}get-autocomplete`);

export const createNotificationSetting = data =>
  postWithResponse(appConfig.baseUrl.concat(notificationSettings), data);

export const updateNotificationSetting = (id, data) =>
  putData(`${appConfig.baseUrl.concat(notificationSettings)}${id}`, data);

export const toggleEnabled = (id, data) =>
  putData(
    `${appConfig.baseUrl.concat(notificationSettings)}${id}/makeEnabled`,
    data,
  );

export const deleteSetting = id =>
  deleteData(`${appConfig.baseUrl.concat(notificationSettings)}${id}`);

export const getAuditViewById = id =>
  getData(`${appConfig.baseUrl}${dataViews}${id}`);

export const getAllTypeValues = () =>
  getData(`${appConfig.baseUrl}${typeValues}all`);

export const getAuditIdentityHints = id =>
  getData(`${appConfig.baseUrl}${policy}${id}/uniqueIdentityData`);

export const getTemplateVariables = (id, useDataViewId, isTrigger = false) =>
  getData(
    `${appConfig.baseUrl}${auditViewTemplateVariables}${id}?useDataViewId=${useDataViewId}&triggerVariables=${isTrigger}`,
  );

const getSettings = (name, id) => getData(`${appConfig.baseUrl}${name}${id}`);

export const getTicketSettings = id => getSettings(ticketSettings, id);

export const getEmailSettings = id => getSettings(emailSettings, id);

export const getSmsSettings = id => getSettings(smsSettings, id);

export const getWebHookSettings = id => getSettings(webHookSettings, id);

export const getSlackSettings = id => getSettings(slackSettings, id);

export const getTeamsSettings = id => getSettings(teamsSettings, id);

export const getFtpSettings = id => getSettings(ftpSettings, id);

export const getVoiceSettings = id => getSettings(voiceSettings, id);

export const getDmVoiceSettings = id => getSettings(dmVoiceSettings, id);

export const postTicketSettings = (id, data) =>
  postItem(ticketSettings, id, data);

export const postEmailSettings = (id, data) =>
  postItem(emailSettings, id, data);

export const postSmsSettings = (id, data) => postItem(smsSettings, id, data);

export const postWebHooksSettings = (id, data) =>
  postItem(webHookSettings, id, data);

export const postSlackSettings = (id, data) =>
  postItem(slackSettings, id, data);

export const postTeamsSettings = (id, data) =>
  postItem(teamsSettings, id, data);

export const postFtpSettings = (id, data) => postItem(ftpSettings, id, data);

export const postVoiceSettings = (id, data) =>
  postItem(voiceSettings, id, data);

export const postDmVoiceSettings = (id, data) =>
  postItem(dmVoiceSettings, id, data);

export const testWebHook = data => testItem(webHookSettings, data);

export const testSlack = data => testItem(slackSettings, data);

export const testTeams = data => testItem(teamsSettings, data);

const checkItem = item =>
  Array.isArray(item) ? item.length : item.from || item.to;

export const filtersNotEmpty = model =>
  model && Object.values(model).some(p => checkItem(p));

export const getFtpConnections = () =>
  getData(`${appConfig.baseUrl}${ftpConnections}connections`);

export const сloneNotification = data =>
  postWithResponse(`${appConfig.baseUrl}${notificationSettings}clone`, data);

export const getUsersForEmail = () =>
  getData(`${appConfig.baseUrl}${notificationSettings}users-for-email`);

export const getUsersForPhone = () =>
  getData(`${appConfig.baseUrl}${notificationSettings}users-for-phone`);

export const getEmailGroupVariables = () =>
  getData(`${appConfig.baseUrl}${notificationGroups}template-variables`);

export const removeNotificationSettings = params =>
  deleteWithResponse(
    `${appConfig.baseUrl}${notificationSettings}bulkDelete?${params}`,
  );
