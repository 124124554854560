import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    padding: '16px 16px 0',
    '& tbody': {
      maxHeight: '74vh',
    },
  },
  actionContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    margin: '5px 0px',
    gap: '5px',
  },
  font: {
    fontFamily: 'Montserrat',
  },
}));

export default useStyles;
