import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

import ReusableButton from '@common/Button/Button';
import Input from '@components/Auth/Common/Input';
import { Field, Form } from 'react-final-form';
import DatePicker from 'react-datepicker';
import DropDown from '@components/Auth/Common/DropDown';

import { isRequiredValidation } from '@ui/components/Helpers/valudationHelpers';

import { NEVER_EXPIRED } from '@constants/commonConstants';
import expirationPeriodOptions from '@constants/expirationPeriodOptions';
import useStyles from '../../Admin/styles';

const initialDate = new Date(moment().add(1, 'days'));

const CreateDialog = ({ open, onClose, onCreate }) => {
  const classes = useStyles();

  const [periodValue, setPeriodValue] = useState(0);
  const [dateValue, setDateValue] = useState(initialDate);
  const [datePickerVisible, setDataPickerVisible] = useState(true);

  const onPeriodChange = e => {
    const { value } = e.target;
    setDataPickerVisible(true);
    setPeriodValue(value);
    switch (value) {
      case expirationPeriodOptions.day.value:
        setDateValue(initialDate);
        break;
      case expirationPeriodOptions.tenDays.value:
        setDateValue(new Date(moment().add(10, 'd')));
        break;
      case expirationPeriodOptions.month.value:
        setDateValue(new Date(moment().add(1, 'M')));
        break;
      case expirationPeriodOptions.year.value:
        setDateValue(new Date(moment().add(1, 'y')));
        break;
      case expirationPeriodOptions.custom.value:
        setDateValue(initialDate);
        break;
      case expirationPeriodOptions.never.value:
        setDataPickerVisible(false);
        setDateValue(
          new Date(moment().set({ year: NEVER_EXPIRED, month: 1, day: 1 })),
        );
        break;
      default:
        setDateValue(0);
        setDateValue(initialDate);
        break;
    }
  };

  const onDateChange = value => {
    setDateValue(value);
    setPeriodValue(4);
  };

  const validate = values => ({
    name: isRequiredValidation(values.name),
    crmDefaultId: values.useForAloAi
      ? isRequiredValidation(values.crmDefaultId)
      : undefined,
  });

  const onCancel = () => {
    onClose();
  };

  const handleConfirm = values => {
    onCreate({
      name: values.name,
      expirationDateUtc: moment.utc(dateValue).toISOString(),
      crmDefaultId: values.crmDefaultId,
    });

    onCancel();
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Generate new API key</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={handleConfirm}
          initialValues={{ useForAloAi: false, expirationDate: null }}
          validate={validate}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.form}>
                <div>
                  <Typography className={classes.inputLabel}>Name:</Typography>
                  <Field
                    id="name"
                    name="name"
                    styleType="main"
                    inputView="text"
                    component={Input}
                    placeholder="Name"
                    fullWidth
                    dateProps={{ maxLength: 30 }}
                    helperText={`${values.name ? values.name.length : 0}/30`}
                  />
                </div>

                <div className={classes.marginTop}>
                  <Typography className={classes.inputLabel}>
                    Expiration period:
                  </Typography>
                  <Field
                    name="expirationDate"
                    size="small"
                    component={({ input, ...rest }) => (
                      <DropDown
                        {...rest}
                        input={{
                          id: 'expirationDate',
                          name: 'expirationDate',
                          value: periodValue,
                          onChange: onPeriodChange,
                        }}
                      />
                    )}
                    options={Object.values(expirationPeriodOptions)}
                  />
                </div>

                {datePickerVisible && (
                  <div className={classes.marginTop}>
                    <DatePicker
                      selected={dateValue}
                      onChange={onDateChange}
                      inline
                    />
                  </div>
                )}

                <div className={classes.buttonsContainer}>
                  <ReusableButton
                    label="resources.buttons.cancel"
                    onClick={onCancel}
                  />
                  <ReusableButton
                    viewType="black"
                    label="resources.buttons.confirm"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          )}
        />
        {/* <div className={classes.formContainer}>
          <Input
            label="Name"
            input={{
              value: nameControl.name,
              onChange: e => onNameChange(e.target.value),
            }}
            meta={{
              touched: nameControl.touched,
              error: nameControl.error,
            }}
          />
          <span className={classes.formText}>Expiration period</span>
          <DropDown
            options={Object.values(expirationPeriodOptions)}
            classNameWrapper={classes.marginTop}
            input={{
              value: periodValue,
              onChange: e => onPeriodChange(+e.target.value),
            }}
          />

          {datePickerVisible && (
            <DatePicker selected={dateValue} onChange={onDateChange} inline />
          )}

          <Checkbox
            checked={useForAloAi}
            onChange={e => setUseForAloAi(e.target.checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            label="Use for Alo.ai"
          />

          {useForAloAi && (
            <>
              <span className={classes.formText}>PSA Ticket Default</span>
              <DropDown
                options={crmOptions}
                classNameWrapper={classes.marginTop}
                labelName="defaultName"
                valueName="defaultId"
                input={{
                  value: crmDefaultId,
                  onChange: e => setCrmDefaultId(+e.target.value),
                }}
              />
            </>
          )}
        </div> */}
      </DialogContent>
    </Dialog>
  );
};

CreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default CreateDialog;
