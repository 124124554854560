import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { ThunkGetCrmOptions } from '@store/slices/crmTicket/thunks';
import ThunkGetCountries from '@store/slices/countries/thunks';
import { ThunkGetTenantCountry } from '@store/slices/tenant/thunks';
import { ThunkCreateNewHelpdeskConfiguration } from '@store/slices/helpdeskAI/thunks';
import { actions } from '@store/actions';

import { countryCodesListSelector } from '@store/slices/countries';
import {
  phoneWithCharactersValidator,
  requiredValidator,
} from '@utils/validators';

import { helpdeskLanguages } from '@ui/pages/portalSettings/HelpdeskAI/constants';

import Input from '@components/Auth/Common/Input';
import FieldWrapper from '@common/form/FieldWrapper';
import ReusableButton from '@common/Button/Button';
import DropDown from '@components/Auth/Common/DropDown';
import FormSwitch from '@components/Auth/Common/FormSwitch';
import PhoneField from '@common/PhoneField';
import Loading from '@common/Loading/Loading';

import useSharedStyles from '@components/CrmTicket/sharedStyles';
import useStyles from '../styles';

const CreateHelpdeskIntegration = ({ open = true, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [crmOptions, setCrmOptions] = useState([]);
  const [countryCode, setCountryCode] = useState('US');
  const [processing, setProcessing] = useState(false);
  const [initialValues, setInitialValues] = useState({
    language: helpdeskLanguages[0]?.value,
    isCallRecordingEnabled: true,
    incomingPhoneNumber: '',
    callTransferPhoneNumber: '',
    addCallRecordingAsExternalNote: false,
    addCallRecordingAsInternalNote: false,
  });

  const sharedStyles = useSharedStyles();
  const classes = useStyles({ loading });
  const dispatch = useDispatch();
  const countries = useSelector(countryCodesListSelector);

  const countryCodesOptions = useMemo(() => {
    return countries.map(list => ({
      value: `${list.code} ${list.dialCode}`,
      label: `${list.code} ${list.dialCode}`,
      icon: (
        <img
          alt={list.code}
          src={`https://flagcdn.com/w20/${list.code.toLowerCase()}.png`}
        />
      ),
    }));
  }, [countries]);

  const initialIcon = useMemo(
    () => (
      <img
        src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
        alt={countryCode}
      />
    ),
    [countryCode],
  );

  const validate = values => ({
    name: requiredValidator(values.name),
    incomingPhoneNumber: phoneWithCharactersValidator(
      values.incomingPhoneNumber,
      9,
      true,
    ),
    crmTicketDefaultId: requiredValidator(values.crmTicketDefaultId),
    language: requiredValidator(values.language, true),
    incomingPhoneNumberCountryCode: requiredValidator(
      values.incomingPhoneNumberCountryCode,
    ),
    callTransferPhoneNumberCountryCode: values.callTransferPhoneNumber
      ? requiredValidator(values.callTransferPhoneNumberCountryCode)
      : undefined,
    callTransferPhoneNumber: values.callTransferPhoneNumber
      ? phoneWithCharactersValidator(values.callTransferPhoneNumber, 9, false)
      : undefined,
  });

  const submit = values => {
    const payload = {
      isActive: true,
      callTransferPhoneNumber: values?.callTransferPhoneNumber
        ? values.callTransferPhoneNumberCountryCode.split(' ')[1] +
          values.callTransferPhoneNumber
        : null,
      incomingPhoneNumber:
        values.incomingPhoneNumberCountryCode.split(' ')[1] +
        values.incomingPhoneNumber,
      ...values,
    };

    setProcessing(true);
    dispatch(ThunkCreateNewHelpdeskConfiguration(payload))
      .unwrap()
      .then(res => {
        dispatch(actions.updateItemInList(res));
        onClose();
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const onMount = useCallback(async () => {
    setLoading(true);
    Promise.all([
      dispatch(ThunkGetCrmOptions()).unwrap(),
      dispatch(ThunkGetCountries()).unwrap(),
      dispatch(ThunkGetTenantCountry()).unwrap(),
    ])
      .then(([defaultOptions, countriesOptions, tenantCountry]) => {
        setCrmOptions(defaultOptions);
        setCountryCode(tenantCountry.data);

        const tenantRegion = countriesOptions.find(
          item => item.code === tenantCountry.data,
        );

        setInitialValues(state => ({
          crmTicketDefaultId: defaultOptions[0]?.defaultId,
          incomingPhoneNumberCountryCode: tenantRegion
            ? `${tenantRegion?.code} ${tenantRegion?.dialCode}`
            : 'US +1',
          callTransferPhoneNumberCountryCode: tenantRegion
            ? `${tenantRegion?.code} ${tenantRegion?.dialCode}`
            : 'US +1',
          ...state,
        }));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Helpdesk AI Assistant Configuration</DialogTitle>
      <DialogContent className={classes.dialog}>
        <Form
          onSubmit={submit}
          initialValues={initialValues}
          validate={validate}
          render={({ handleSubmit, values }) =>
            loading ? (
              <Loading />
            ) : (
              <form onSubmit={handleSubmit}>
                <FieldWrapper
                  label="Name"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <Field
                      id="name"
                      name="name"
                      component={Input}
                      disabled={processing}
                    />
                  }
                />

                <PhoneField
                  label="Incoming Phone Number"
                  phoneName="incomingPhoneNumber"
                  phoneCodeName="incomingPhoneNumberCountryCode"
                  countryCodesOptions={countryCodesOptions}
                  initialIcon={initialIcon}
                  isRequired
                  disabled={processing}
                />

                <PhoneField
                  label="Call Transfer Phone Number"
                  phoneName="callTransferPhoneNumber"
                  phoneCodeName="callTransferPhoneNumberCountryCode"
                  countryCodesOptions={countryCodesOptions}
                  initialIcon={initialIcon}
                />

                <FieldWrapper
                  label="PSA Ticket Default"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <Field
                      id="crmTicketDefaultId"
                      name="crmTicketDefaultId"
                      component={DropDown}
                      options={crmOptions}
                      disabled={processing}
                      labelName="defaultName"
                      valueName="defaultId"
                    />
                  }
                />

                <FieldWrapper
                  label="Language"
                  labelSize={12}
                  contentSize={12}
                  isRequired
                  content={
                    <Field
                      id="language"
                      name="language"
                      component={DropDown}
                      options={helpdeskLanguages}
                      disabled={processing}
                      required
                    />
                  }
                />

                <FieldWrapper
                  label="Record Calls"
                  labelSize={11}
                  contentSize={1}
                  content={
                    <Field
                      name="isCallRecordingEnabled"
                      id="isCallRecordingEnabled"
                      render={FormSwitch}
                      disabled={processing}
                      wrapperStyles={{ border: 'none' }}
                      checked={values.isCallRecordingEnabled}
                    />
                  }
                />

                <FieldWrapper
                  label="Add Call Recording as Internal Note"
                  labelSize={11}
                  contentSize={1}
                  content={
                    <Field
                      name="addCallRecordingAsInternalNote"
                      id="addCallRecordingAsInternalNote"
                      render={FormSwitch}
                      disabled={processing || !values.isCallRecordingEnabled}
                      wrapperStyles={{ border: 'none' }}
                      checked={values.addCallRecordingAsInternalNote}
                    />
                  }
                />

                <FieldWrapper
                  label="Add Call Recording as External Note"
                  labelSize={11}
                  contentSize={1}
                  content={
                    <Field
                      name="addCallRecordingAsExternalNote"
                      id="addCallRecordingAsExternalNote"
                      render={FormSwitch}
                      disabled={processing || !values.isCallRecordingEnabled}
                      wrapperStyles={{ border: 'none' }}
                      checked={values.addCallRecordingAsExternalNote}
                    />
                  }
                />

                <div className={sharedStyles.dialogActionsContainer}>
                  <ReusableButton
                    label="Close"
                    onClick={onClose}
                    disabled={processing}
                  />
                  <ReusableButton
                    label="Create"
                    type="submit"
                    viewType="blue"
                    loading={processing}
                    disabled={processing}
                  />
                </div>
              </form>
            )
          }
        />
      </DialogContent>
    </Dialog>
  );
};

CreateHelpdeskIntegration.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default CreateHelpdeskIntegration;
