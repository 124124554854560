import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import HtmlInput from '@common/HtmlInput';
import ReusableButton from '@common/Button/Button';

import useStyles from '../styles';

const EditKaseyaDetailsDialog = ({ open = true, form, onClose, onUpdate }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Details</DialogTitle>
      <DialogContent>
        <div>
          <HtmlInput name="details" form={form} />
        </div>
        <div className={classes.dialogButtons}>
          <ReusableButton label="Close" onClick={onClose} />
          <ReusableButton
            label="Update"
            onClick={() => {
              onUpdate();
              onClose();
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

EditKaseyaDetailsDialog.propTypes = {
  open: PropTypes.bool,
  form: PropTypes.shape({
    change: PropTypes.func,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default EditKaseyaDetailsDialog;
