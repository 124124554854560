import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import KaseyaTicketTableActions from '@components/PsaTickets/components/Kaseya/KaseyaTicketTableActions';
import Table from '@components/Table';
import { TableTypes } from '@components/Table/constants';
import {
  ThunkGetKaseyaIssueSubTypes,
  ThunkGetKaseyaIssueTypes,
  ThunkGetKaseyaQueues,
  ThunkGetKaseyaStatuses,
  ThunkGetKaseyaTypes,
} from '@store/slices/kaseyaTicket/thunks';
import { KaseyaTicketOptionsSelector } from '@store/slices/kaseyaTicket';
import getFormattedDate from '@utils/getFormattedDate';

const KaseyaTicketTable = ({ defaultId, crmId }) => {
  const dispatch = useDispatch();

  const { queues, statuses, types, issueTypes, issueSubTypes } = useSelector(
    KaseyaTicketOptionsSelector,
  );

  const {
    queuesOptions,
    statusesOptions,
    typesOptions,
    issueTypesOptions,
    issueSubTypesOptions,
  } = useMemo(() => {
    const generateOptions = options =>
      options.map(option => ({ value: option.label, label: option.label }));

    return {
      queuesOptions: generateOptions(queues),
      statusesOptions: generateOptions(statuses),
      typesOptions: generateOptions(types),
      issueTypesOptions: generateOptions(issueTypes),
      issueSubTypesOptions: generateOptions(issueSubTypes),
    };
  }, [issueSubTypes, issueTypes, queues, statuses, types]);

  const onMount = useCallback(async () => {
    await Promise.all([
      dispatch(ThunkGetKaseyaQueues({ crmDefaultId: defaultId })).unwrap(),
      dispatch(ThunkGetKaseyaStatuses({ crmDefaultId: defaultId })).unwrap(),
      dispatch(ThunkGetKaseyaTypes({ crmDefaultId: defaultId })).unwrap(),
      dispatch(ThunkGetKaseyaIssueTypes({ crmDefaultId: defaultId })).unwrap(),
    ]);
  }, [dispatch, defaultId]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const columns = useMemo(
    () => [
      {
        name: 'ID',
        key: 'ticketNumber',
        sortable: true,
        searchable: true,
        minWidth: 150,
      },
      {
        name: 'Title',
        key: 'title',
        sortable: true,
        searchable: true,
        minWidth: 150,
      },
      {
        name: 'Account',
        key: 'account',
        sortable: true,
        searchable: true,
        minWidth: 150,
      },
      {
        name: 'Status',
        key: 'status',
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: statusesOptions,
        filterByAutocomplete: 'status',
        minWidth: 150,
      },
      {
        name: 'Queue',
        key: 'queue',
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: queuesOptions,
        filterByAutocomplete: 'queue',
        minWidth: 200,
      },
      {
        name: 'Open Date',
        key: 'openDate',
        searchable: false,
        sortable: true,
        filterByAutocomplete: 'openDate',
        accessor: row => <div>{getFormattedDate(row.openDate)}</div>,
        minWidth: 200,
      },
      {
        name: 'Type',
        key: 'type',
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: typesOptions,
        filterByAutocomplete: 'type',
        minWidth: 150,
      },
      {
        name: 'Issue Type',
        key: 'issueType',
        searchable: true,
        type: TableTypes.dropdown,
        dropDownValues: issueTypesOptions,
        filterByAutocomplete: 'IssueType',
        minWidth: 200,
        filterCallBack: (value, filtering, setFilteringObject) => {
          if (value) {
            dispatch(
              ThunkGetKaseyaIssueSubTypes({
                crmDefaultId: defaultId,
                issueTypeId: issueTypes.find(i => i.label === value)?.value,
              }),
            );
          }
          const { issueSubType, ...filters } = filtering;
          setFilteringObject(filters, 'IssueType', value);
        },
      },
      {
        name: 'Sub Issue Type',
        key: 'issueSubType',
        searchable: true,
        minWidth: 200,
        type: TableTypes.dropdown,
        dropDownValues: issueSubTypesOptions,
        filterByAutocomplete: 'IssueSubType',
      },
      {
        name: 'Contact',
        key: 'contact',
        minWidth: 150,
        sortable: true,
        searchable: true,
      },
      {
        name: 'Assignee',
        key: 'assignee',
        minWidth: 150,
        sortable: true,
        searchable: true,
      },
      {
        name: 'Actions',
        key: TableTypes.actions,
        type: TableTypes.actions,
        width: 170,
        minWidth: 170,
        accessor: record => (
          <KaseyaTicketTableActions
            record={record}
            crmDefaultId={defaultId}
            crmId={crmId}
          />
        ),
      },
    ],
    [
      crmId,
      defaultId,
      dispatch,
      issueSubTypesOptions,
      issueTypesOptions,
      queuesOptions,
      statusesOptions,
      typesOptions,
    ],
  );

  return (
    <Table
      columns={columns}
      resource={`TicketUpdate/kaseya/${defaultId}/tickets`}
      defaultSort={{ fieldName: 'openDate', order: 'DESC' }}
    />
  );
};

KaseyaTicketTable.propTypes = {
  defaultId: PropTypes.number.isRequired,
  crmId: PropTypes.number.isRequired,
};

export default KaseyaTicketTable;
