import React from 'react';
import { SnackbarProvider } from 'notistack';
import SignalRProvider from './SignalRProvider';
import TelemetryProvider from './TelemetryProvider';

const AppProviders = ({ children }) => {
  return (
    <TelemetryProvider>
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        preventDuplicate
      >
        <SignalRProvider>{children}</SignalRProvider>
      </SnackbarProvider>
    </TelemetryProvider>
  );
};

export default AppProviders;
