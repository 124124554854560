import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';

import { TEXT_UPDATE_SUCESS } from '@constants/texts/common';
import ReusableButton from '@common/Button/Button';
import TemplateMenu from '@components/Ticketing/Notifications/components/TemplateMenu';
import {
  ThunkAddOptInSmsTemplate,
  ThunkGetOptInSmsTemplate,
} from '@store/slices/optIn/thunks';
import { requiredValidator } from '@utils/validators';

import FieldWrapper from '@ui/components/common/form/FieldWrapper';
import HtmlInput from '@ui/components/common/HtmlInput';
import Loading from '@common/Loading/Loading';
import FieldComponent from './FieldComponent';

import useStyles from './styles';

const templateVariables = [{ label: 'Url template', value: '$<url>' }];
const htmlTemplateVariables = [{ label: 'Url template', value: '►url◄' }];

const OptInForm = ({ onSubmit, onCancel }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [smsAnchorEl, setSmsAnchorEl] = useState(null);
  const [ticketAnchorEl, setTicketAnchorEl] = useState(null);
  const [emailSubjectAnchorEl, setEmailSubjectAnchorEl] = useState(null);
  const [emailHeaderAnchorEl, setEmailHeaderAnchorEl] = useState(null);
  const [data, setData] = useState(null);

  const inputs = [
    {
      label: 'SMS Template',
      action: setSmsAnchorEl,
      anchor: smsAnchorEl,
      fieldName: 'smsTemplate',
    },
    {
      label: 'PSA Ticket Note Template',
      action: setTicketAnchorEl,
      anchor: ticketAnchorEl,
      fieldName: 'ticketNoteTemplate',
    },
    {
      label: 'Email Subject Template',
      action: setEmailSubjectAnchorEl,
      anchor: emailSubjectAnchorEl,
      fieldName: 'emailSubjectTemplate',
    },
    {
      label: 'Email Header Template',
      action: setEmailHeaderAnchorEl,
      anchor: emailHeaderAnchorEl,
      fieldName: 'emailHeaderTemplate',
    },
  ];

  const submit = values => {
    setProcessing(true);
    dispatch(ThunkAddOptInSmsTemplate({ payload: values }))
      .unwrap()
      .then(() => enqueueSnackbar(TEXT_UPDATE_SUCESS, { variant: 'success' }))
      .finally(() => {
        setProcessing(false);
        onSubmit();
      });
  };

  const validate = values => {
    return {
      smsTemplate: requiredValidator(values.smsTemplate),
      ticketNoteTemplate: requiredValidator(values.ticketNoteTemplate),
    };
  };

  const onMount = useCallback(() => {
    setLoading(true);
    dispatch(ThunkGetOptInSmsTemplate())
      .unwrap()
      .then(setData)
      .finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loading />;

  return (
    <div className={classes.container}>
      <Form
        onSubmit={submit}
        validate={validate}
        initialValues={data}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            {inputs.map(i => (
              <FieldComponent
                key={i.fieldName}
                label={i.label}
                setAnchorFunc={i.action}
                fieldName={i.fieldName}
                processing={processing}
              />
            ))}
            <FieldWrapper
              label="Email Body Template *"
              labelSize={3}
              contentSize={9}
              content={
                <div className={classes.dialogTemplate}>
                  <HtmlInput
                    name="emailBodyTemplate"
                    form={form}
                    templateVariables={htmlTemplateVariables}
                  />
                </div>
              }
            />
            <div className={classes.dialogActions}>
              {onCancel && (
                <ReusableButton
                  label="Cancel"
                  onClick={onCancel}
                  style={{ marginRight: '0.5rem' }}
                />
              )}
              <ReusableButton
                label="Submit"
                type="submit"
                loading={processing}
                disabled={processing}
              />
            </div>
            {inputs.map(i => (
              <TemplateMenu
                key={i.fieldName}
                anchorEl={i.anchor}
                setAnchorEl={i.action}
                form={form}
                name={i.fieldName}
                templateVariables={templateVariables}
              />
            ))}
          </form>
        )}
      />
    </div>
  );
};

OptInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default OptInForm;
