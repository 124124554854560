import { OUTBOUND_MAIL_BOX } from '../requestResources';
import { makeRequest } from '../makeRequest';

export const getOutboundMailbox = () =>
  makeRequest('GET', `${OUTBOUND_MAIL_BOX}`);

export const getOutboundMailboxUsers = () =>
  makeRequest('GET', `${OUTBOUND_MAIL_BOX}/setup/users`);

export const setupOutboundMailbox = payload =>
  makeRequest('POST', `${OUTBOUND_MAIL_BOX}/setup`, payload);

export const updateOutboundMailboxResult = payload =>
  makeRequest('PUT', `${OUTBOUND_MAIL_BOX}`, payload);

export const deleteOutboundMailbox = () =>
  makeRequest('DELETE', `${OUTBOUND_MAIL_BOX}`, null, false, false);

export default {};
