import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '1rem',
    '& tbody': {
      maxHeight: '73vh',
    },
  },
  selectWrapper: {
    width: '18rem',
    marginBottom: '8px',
  },
}));

export default useStyles;
