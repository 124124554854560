import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import ReusableButton from '@common/Button/Button';

import useStyles from '../styles';

const logColors = {
  dateTimeColor: 'blue',
  errorColor: 'red',
  infoColor: 'green',
  highlightColor: '#e9e9e9',
};

const prepareLogString = logRecord => {
  const dateTimeRegex = RegExp(
    /^(?<dateTime>\[\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\])/,
  );
  const statusRegex = RegExp(
    /\]\s?(?<status>IsSuccess:\s?(?<result>[True|False]+))/,
  );
  const textRegex = RegExp(/IsSuccess:\s(True|False)\.\s(?<text>.*)$/);

  const dateTimeMatch = dateTimeRegex.exec(logRecord)?.groups?.dateTime;
  const statusMatch = statusRegex.exec(logRecord)?.groups?.status;
  const isSuccess = statusRegex.exec(logRecord)?.groups?.result === 'True';
  const textMatch = textRegex.exec(logRecord)?.groups?.text;

  return dateTimeMatch && statusMatch ? (
    <>
      <span style={{ color: logColors.dateTimeColor }}>{dateTimeMatch}</span>
      <span>&nbsp;</span>
      <span
        style={{
          color: isSuccess ? logColors.infoColor : logColors.errorColor,
        }}
      >
        {statusMatch}
      </span>
      <span>&nbsp;</span>
      <span>{textMatch}</span>
    </>
  ) : (
    <span>{logRecord}</span>
  );
};

const GenericSourcesLogsDetails = ({ open, setOpen, logDetails }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <div className={classes.logDetailHeader}>
          <span>Logs</span>
          <ReusableButton
            label="Close"
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        {logDetails.map((log, idx) => (
          <div
            key={idx}
            style={{
              backgroundColor:
                idx % 2 !== 0 ? logColors.highlightColor : 'none',
            }}
          >
            {prepareLogString(log)}
          </div>
        ))}
      </DialogContent>
    </Dialog>
  );
};

GenericSourcesLogsDetails.propTypes = {
  logDetails: PropTypes.arrayOf(PropTypes.string),
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default GenericSourcesLogsDetails;
