import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  grayScalse: {
    filter: 'grayscale(1)'
  },
  dialogActionsRoot: {
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  confirmBtnContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  spacingWrapper: {
    [theme.breakpoints.down('xs')]: {
      '& :not(:first-child)': {
        marginLeft: '0',
      },
    },
  },
  displayFlex: {
    display: 'flex',
  }
}));

export default useStyles;